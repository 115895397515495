import util from "./util2";
import config from "./config";
/**
 * 所有账单
 * @param createDate
 * @returns bills  所有账单
 */
//变更支付日期的账单创建-------------创建日期，合同开始日期，-结束日期，账单list，提前时间总类型对象-提前时间，---押金----------标题----付款类型------优惠方式-----------折扣----从第几期开始变---再提前几天付款
function creatEditCycleBills (createDate, beginDate, endDate, feeList, advanceDays,beforeDays,restDepositMoney,title,payMethodId,couponType,quarterlyDiscount, cycle, dayNum) {
    var me = this;
    var bills = [];
    feeList.forEach(function (item) {
        bills = bills.concat(me.createEditCycleBillDetail(createDate, beginDate, endDate, item, advanceDays,beforeDays,restDepositMoney,title,payMethodId,couponType,quarterlyDiscount, cycle, dayNum))  //处理后的账单
    });
    return bills;
}

function createEditCycleBillDetail(createDate, beginDate, endDate, params, advanceDays, beforeDays, restDepositMoney, title, payMethodId, couponType,quarterlyDiscount, cycle, dayNum){
    // console.log('当前类型账单为：',params)
    endDate=new Date(endDate.getFullYear(),endDate.getMonth(),endDate.getDate())
    if (!params) {
        return
    }
    var me = this
    var payType = params.payTypeId
    var otherPayType = params.otherPayTypeId
    var bills = []
    var unitType = params.payUnitType
    var direction = params.billCollectionPaymentType
    var typeFlag = params.typeFlag
    // 一次付清
    var state=false
    if (
        payType == config.PAY_TYPE_ONCE ||
        otherPayType == config.PAY_TYPE_ONCE ||
        otherPayType == config.PAY_TYPE_WITHRENT
    ) {
        let totalamount = params.totalamount
        // 其他费用
        if (params.identity == 'tenant' && typeFlag == '1') {
            // 元
            if (unitType == config.PAY_UNIT_All) {
                totalamount = params.totalamount
            } else if (unitType == config.PAY_UNIT_DAY) {
                // 元/天
                let feeDays = util.getDaysSum(
                    util.dateFormat(beginDate),
                    util.dateFormat(endDate)
                )
                totalamount = Number(params.totalamount) * Number(feeDays)
            } else if (unitType == config.PAY_UNIT_MONTH) {
                // 元/月
                // 开始时间加租期时长，比较得出的时间与结束时间，校验是否为正月
                let months = util.getDistanceMonth(beginDate, endDate)

                let wholeEndDate = util.dateAddMonth(beginDate, Number(params.count))
                let tempCount = Number(params.count)
                if (
                    endDate.getTime() >
                    wholeEndDate.getTime() - 24 * 60 * 60 * 1000
                ) {
                    tempCount = tempCount + 1
                }
                totalamount = tempCount * Number(params.totalamount) // 向上取
            }
        }
        // debugger
        // 一次性付清，计算日租金*天数
        if (params.identity == 'tenant' && params.costtype == '8') {
            // let days = me.getDaysSum(me.dateFormat(beginDate),me.dateFormat(endDate));
            // // 月租金
            // let monthMoney = Number(totalamount)/Number(params.count - 1)
            // let dayMoney = me.getDayRentMoney(monthMoney);

            // if(Number(days) > 0){
            //     totalamount = (Number(dayMoney)*Number(days))
            // }
            //计算两个日期相差的月数
            let months = util.getDistanceMonth2(beginDate, endDate)
            //计算除了相差月数之外的相差天数
            let middleDate = util.dateAddMonth(beginDate, months)
            let middleDays = util.getDaysSum(middleDate, endDate)
            // 月租金
            let monthMoney = Number(totalamount) / Number(params.count)
            if (Number(params.count) == 0) {
                monthMoney = Number(totalamount)
            }
            let dayMoney = util.getDayRentMoney(monthMoney)
            //应收月租 = (月数*月租)+(日数*日租)
            //totalamount =Number(monthMoney) * Number(months) +Number(middleDays) * Number(dayMoney)     //原
            let dayIndex=1
            let isWholeMonth=false
            while(true){
                let newDate=util.dateAddDay(util.dateAddMonth(beginDate, dayIndex), -1)//me.dateAddMonth(curBegin,dayIndex)
                if(newDate.getTime()==endDate.getTime()){
                    isWholeMonth=true
                    break
                }else if(newDate.getTime()<endDate.getTime()){
                    dayIndex++
                }else{
                    break
                }
            }
            var amount = params.totalamount;
            if(isWholeMonth){
                if(params.reduceLastRent=='是'&&params.count==12&&params.chooseBreakCard&&Number(params.count)>=12){
                    totalamount=(11*amount).toFixed(2)
                }else{
                    totalamount=(dayIndex*amount).toFixed(2)
                }
            }else{
                totalamount = util.getSumMoneyOther(params, beginDate, endDate);
            }
            //totalamount = me.getSumMoneyOther(params, beginDate, endDate); //赵
        }

        var total=Number(totalamount).toFixed(2)
        let dayDeduction=''
        if(!state&&(title=='修改'||title==1)){
            if(Number(restDepositMoney)<0&&Number(total)+Number(restDepositMoney)<0){
                restDepositMoney=Number(total)+Number(restDepositMoney)
            }
            else if(Number(restDepositMoney)<0&&Number(total)+Number(restDepositMoney)==0){
                restDepositMoney=Number(total)+Number(restDepositMoney)
            }
            else if(Number(restDepositMoney)<0&&Number(total)+Number(restDepositMoney)>0&&params.costtypeVal=='租金'){
                let monthMoney = Number(total)/Number(params.count)
                let dayMoney=me.getDayRentMoney(monthMoney)
                dayDeduction=Number(-restDepositMoney)/Number(dayMoney)

                if(dayDeduction<1){
                    dayDeduction=0
                }else{
                    dayDeduction=Math.floor(dayDeduction+0)
                }
                bills.push({
                    billCollectionPaymentType: 3,
                    costtype: params.costtype,
                    costtypeVal: params.costtypeVal,
                    payTypeId: params.payTypeId,
                    totalamount: (Number(-restDepositMoney)).toFixed(2),
                    count: params.count,
                    identity: params.identity,//owner业主,tenant租客
                    nowCount: params.nowCount,
                    billdescription: params.describe+(-Number(params.goodsPriceMoney)>-Number(restDepositMoney)?'(物品押金抵扣)':'(押金抵扣)'),
                    // 应付日期
                    //collectionDate: me.dateFormat(createDate),
                    collectionDate: params.flagType=='2'?util.dateFormat(beginDate):util.dateFormat(createDate),//me.dateFormat(beginDate),
                    costBeginDate: util.dateFormat(beginDate),
                    costEndDate: util.dateFormat2(beginDate,dayDeduction),
                    isDownpayment:'否'
                });
                state=true
            }/*else if(Number(restDepositMoney)>Number(total)){
            bills.push({
                billCollectionPaymentType: me.getPayDirectionValue(direction),
                costtypeVal: params.costtypeVal,
                payTypeId: params.payTypeId,
                totalamount: (Number(restDepositMoney)-Number(total)).toFixed(2),
                count: params.count,
                identity: params.identity,//owner业主,tenant租客
                nowCount: params.nowCount,
                billdescription: params.describe+'(押金欠款)',
                // 应付日期
                collectionDate: me.dateFormat(createDate),
                costBeginDate: me.dateFormat(beginDate),
                costEndDate: me.dateFormat(endDate),
                isDownpayment:'否'
            });
        } */
        }

        if(params.payTypeId==4&&params.costtypeVal=='房租'&&Number(restDepositMoney)<0){
            totalamount=(Number(totalamount)+(Number(restDepositMoney)?Number(restDepositMoney):0)).toFixed(2)
        }

        var startDate=''
        if(bills[bills.length-1]&&bills[bills.length-1].billCollectionPaymentType==3){
            startDate=util.dateFormat2(beginDate,dayDeduction+1)
        }

        bills.push({
            billCollectionPaymentType: util.getPayDirectionValue(direction),
            costtype: params.costtype,
            costtypeVal: params.costtypeVal,
            payTypeId: params.payTypeId,
            totalamount: Number(totalamount).toFixed(2),
            billdescription: params.typeFlag==1?(params.costtype==4?params.describe+'水电押金':params.describe+'一次性费用'):params.describe,
            count: params.count,
            nowCount: params.nowCount,
            identity: params.identity, //owner 业主,tenant 租客
            // 应付日1
            collectionDate:(params.flagType=='2')&&params.costtype=='8'?util.dateFormat(beginDate):util.dateFormat(createDate),
            costBeginDate: startDate?startDate:util.dateFormat(beginDate),
            costEndDate: util.dateFormat(endDate),
            isDownpayment: params.isDownpaymentVal=='否'||params.isDownpaymentVal==undefined? '否':totalPayTypeId =='6'&&beforeDays==30&&num<=2||num<=1?'是':'否'
        })
        return bills
    }
    var advanceDay = 0
    // 月付
    if (payType != '') {
        if (
            payType == config.PAY_TYPE_MONTH ||
            payType == config.PAY_TYPE_TWO_BY_ONE ||
            payType == config.PAY_TYPE_TWO_BY_THREE|| payType=='9'
        ) {
            advanceDay = advanceDays.MONTH
        }
        if (
            payType == config.PAY_TYPE_SEASON ||
            payType == config.PAY_TYPE_ONE_BY_ONE ||
            payType == config.PAY_TYPE_ONE_BY_THREE
        ) {
            advanceDay = advanceDays.SEASON
        }
        if (payType == config.PAY_TYPE_HALF_YEAR) {
            advanceDay = advanceDays.HALF_YEAR
        }
        if (payType == config.PAY_TYPE_YEAR) {
            advanceDay = advanceDays.YEAR
        }
        if (payType == config.PAY_TYPE_WITHRENT) {
            if (
                unitType == config.PAY_TYPE_MONTH ||
                unitType == config.PAY_TYPE_TWO_BY_ONE ||
                unitType == config.PAY_TYPE_TWO_BY_THREE|| unitType=='9'
            ) {
                advanceDay = advanceDays.MONTH
            }
            if (
                unitType == config.PAY_TYPE_SEASON ||
                unitType == config.PAY_TYPE_ONE_BY_ONE ||
                unitType == config.PAY_TYPE_ONE_BY_THREE
            ) {
                advanceDay = advanceDays.SEASON
            }
            if (unitType == config.PAY_TYPE_HALF_YEAR) {
                advanceDay = advanceDays.HALF_YEAR
            }
            if (unitType == config.PAY_TYPE_YEAR) {
                advanceDay = advanceDays.YEAR
            }
        }
    }
    if (otherPayType != '' || otherPayType != undefined) {
        otherPayType = parseInt(otherPayType)
        //半年
        if (otherPayType == config.PAY_TYPE_MONTH) {
            advanceDay = advanceDays.MONTH
        }
        // 季付
        if (otherPayType == config.PAY_TYPE_SEASON) {
            advanceDay = advanceDays.SEASON
        }
        //半年
        if (otherPayType == config.PAY_TYPE_HALF_YEAR) {
            advanceDay = advanceDays.HALF_YEAR
        }
        // 年付
        if (otherPayType == config.PAY_TYPE_YEAR) {
            advanceDay = advanceDays.YEAR
        }
    }

    bills = me.getEditCycleBills(createDate, beginDate, endDate, params, advanceDay,beforeDays,restDepositMoney,title,payMethodId,couponType,quarterlyDiscount, cycle, dayNum)

    if (
        params.costtype != '2' &&
        params.costtype != '1' &&
        params.costtype != '8' &&
        params.payTypeId != '4' &&
        params.identity == undefined
    ) {
        if (util.isWholeYear(beginDate, endDate)) {
            bills[bills.length - 1].totalamount = bills[0].totalamount
        }
    }

    return bills
}


function getEditCycleBills(createDate, beginDate, endDate, params, advanceDay,beforeDays,restDepositMoney,title,payMethodId,couponType,quarterlyDiscount, cycle, dayNum) {
    var me = this
    var payType = params.payTypeId
    var otherPayType = params.otherPayTypeId
    var unitType = params.payUnitType2
    if (params.payTypeId == '') {
        payType = otherPayType
    }
    if (params.renterType) {
        if (params.payTypeId == 5) {
            // params.payTypeId = params.renterType
            payType = params.renterType
        }
    }
    var curBegin = beginDate
    var curEnd = me.getEditCycleBillEndDate(curBegin, payType, unitType, endDate)
    var bills = []
    var num = 1
    var feenum = 1
    var direction = util.getPayDirectionValue(params.billCollectionPaymentType)
    let noFeeNum = parseInt(params.noFeeNum)
    let oldNoFeeNum = parseInt(params.oldNoFeeNum)
    let newEndDate = new Date(endDate)

    // newEndDate.setDate(newEndDate.getDate() + 8)
    newEndDate.setDate(newEndDate.getDate() + 0)
    let newNoFeeNum = noFeeNum
    var state=false
    var condition=false
    var status=false
    while (curEnd <= newEndDate) {
        var sum = me.getEditCycleSumMoney(params, curBegin, curEnd,endDate,num,couponType,quarterlyDiscount,cycle,dayNum,bills)
        if (params.identity == 'owner' && params.typeFlag != 1) {
        } else {
            if (num == 1) {
                //var collectionDate = me.dateFormat(createDate)
                var collectionDate = params.flagType=='2'&&params.payTypeId!='4'?util.dateFormat(beginDate):util.dateFormat(createDate)//me.dateFormat(beginDate)
            } else if(num>1 && num<cycle){
                if(params.payTypeId =='6' || params.renterType == '6'){  //押一付一
                    var collectionDate = util.getPreMonth2(util.dateFormat3(curBegin,beforeDays));
                }
                else if(params.payTypeId ==8){
                    var collectionDate = util.getPreMonth2(util.dateFormat3(curBegin,1))
                }else{   //随房租付
                    var collectionDate = util.getPreMonth2(util.dateFormat3(curBegin,beforeDays));         //赵
                }
            } else{
                if(params.payTypeId =='6' || params.renterType == '6'){                 //赵
                    var collectionDate = util.getPreMonth2(util.dateFormat3(curBegin,beforeDays));
                }
                else if(params.payTypeId ==8){
                    var collectionDate = util.getPreMonth2(util.dateFormat3(curBegin,1))
                }else{
                    var collectionDate = util.getPreMonth2(util.dateFormat3(curBegin,beforeDays));
                }
            }

            let billdescription = ''
            if (params.typeFlag && params.typeFlag == 1) {
                //billdescription = params.describe
                billdescription = '第' + num + '期'
                // 随房租付 付款周期（元、元/天、元/月6）
                let otherPayUnitType = params.otherPayUnitType
                // 元/ 天
                if (otherPayUnitType == config.PAY_UNIT_DAY) {
                    let feeDays = util.getDaysSum(
                        util.dateFormat(curBegin),
                        util.dateFormat(curEnd)
                    )
                    sum = Number(feeDays) * params.totalamount
                } else if (otherPayUnitType == config.PAY_UNIT_MONTH) {
                    let months = me.getOtherDistanceMonth(
                        util.dateFormat(curBegin),
                        util.dateFormat(curEnd),
                        payType,num,cycle
                    )
                    if (months <= 0 && curEnd.getTime() - curBegin.getTime() > 0) {
                        let daysNumber = (curEnd.getTime() - curBegin.getTime())/(3600*24*1000)
                        if(daysNumber==dayNum){
                            months = daysNumber/30;
                        }else{
                            months = 1;
                        }
                    }
                    sum = Number(months) * Number(params.totalamount) // 向上取
                    if(num==13){
                        sum = Number(params.totalamount) - Number(bills[2].totalamount)
                    }
                }
            } else {
                billdescription = '第' + num + '期'
                // billdescription = params.describe + num + '期';
            }
            //console.log(curEnd)
            let costEndDate = util.dateFormat(curEnd)
            if (curEnd > endDate) {
                costEndDate = util.dateFormat(endDate)
            }
            if(beginDate.getDate()>28&&beginDate.getDate()<=new Date(costEndDate).getDate()){
                costEndDate=new Date(costEndDate)
                costEndDate.setDate(beginDate.getDate()-1)
                costEndDate=util.dateFormat(costEndDate)
            }
            //console.log(costEndDate)
            curEnd=util.dateAddDay(costEndDate,0)
            //console.log(sum)
            //console.log(title)
            var dayDeduction=''
            if(!state&&(title=='修改'||title==1)){            //title==1为租约变更
                if(Number(restDepositMoney)<0&&Number(sum)+Number(restDepositMoney)<0){
                    restDepositMoney=Number(sum)+Number(restDepositMoney)
                }
                else if(Number(restDepositMoney)<0&&Number(sum)+Number(restDepositMoney)==0){
                    /*if(condition){
                     condition=false
                    }else{
                     condition=true
                    }*/
                    if(params.payTypeId==6){
                        condition=true
                    }
                    restDepositMoney=Number(sum)+Number(restDepositMoney)
                }
                else if(Number(restDepositMoney)<0&&Number(sum)+Number(restDepositMoney)>0){
                    let dayMoney=util.getDayRentMoney(sum)
                    dayDeduction=Number(-restDepositMoney)/Number(dayMoney)
                    if(dayDeduction<1){
                        dayDeduction=0
                    }else{
                        dayDeduction=Math.floor(dayDeduction+0)
                    }
                    bills.push({
                        billCollectionPaymentType: 3,
                        costtype: params.costtype,
                        costtypeVal: params.costtypeVal,
                        payTypeId: params.payTypeId,
                        otherPayTypeId: params.otherPayTypeId,
                        totalamount: (Number(-restDepositMoney)).toFixed(2),
                        count: params.count,
                        identity: params.identity,//owner业主,tenant租客
                        nowCount: params.nowCount,
                        billdescription: billdescription+'(押金抵扣)',
                        temporarybillType:'0',
                        // 应付日期
                        collectionDate: num == 1 ? collectionDate : collectionDate,
                        costBeginDate: util.dateFormat(curBegin),
                        //costEndDate: costEndDate
                        costEndDate: util.dateFormat2(curBegin,dayDeduction),
                        isDownpayment:'否'
                    });
                    state=true
                    status=true
                }
            }
            var startDate=''
            if(bills[bills.length-1]&&bills[bills.length-1].billCollectionPaymentType==3&&num==1){
                startDate=util.dateFormat2(curBegin,dayDeduction+0)
            }
            if(params.costtypeVal!='租金'){
                let tempEndDate = util.dateAddMonth(curBegin, params.count);
                if (tempEndDate.getTime() <= util.dateToDate(costEndDate).getTime()) {
                    sum = (Number(params.count) + 1)* Number(params.totalamount)
                }
            }
            bills.push({
                billCollectionPaymentType: !state&&restDepositMoney<0&&(title=='修改'||title==1)||condition?3:direction,
                costtype: params.costtype,
                costtypeVal: params.costtypeVal,
                payTypeId: params.payTypeId,
                otherPayTypeId: params.otherPayTypeId,
                totalamount: state&&status&&(title=='修改'||title==1)?(Number(sum)+Number(restDepositMoney)).toFixed(2):Number(sum).toFixed(2),
                count: params.count,
                identity: params.identity, //owner业主,tenant租客
                nowCount: params.nowCount,
                billdescription: !state&&restDepositMoney<0&&(title=='修改'||title==1)||condition?billdescription+'(押金抵扣)':billdescription,
                temporarybillType:!state&&restDepositMoney<0&&(title=='修改'||title==1)||condition?'0':'',
                // 应付日期
                collectionDate: num == 1 ? collectionDate : collectionDate,
                // collectionDate: collectionDate,
                //costBeginDate: me.dateFormat(curBegin),
                costBeginDate: startDate?startDate:util.dateFormat(curBegin),
                costEndDate: util.dateFormat(costEndDate),
                isDownpayment:params.isDownpaymentVal=='否'||params.isDownpaymentVal==undefined? '否':payMethodId =='6'&&beforeDays==30&&num<=2?'是':num<=1?'是':'否',
            })
            status=false
            condition=false
        }
        curBegin = util.dateAddDay(curEnd, 1)
        if (util.dateFormat(endDate) == util.dateFormat(curEnd)) {
            break
        }

        let isChangeCycle = false  //下一周期是否是修改的周期
        if((num+1)==cycle){
            isChangeCycle=true
        }
        //加入额外的逻辑处理（租金），
        if (
            (params.payTypeId == 6 || params.payTypeId == 8) &&
            (num + 1) % 13 === 0
        ) {
            // 押一付一  押二付一
            curEnd = util.newDateAddYear(beginDate, (num + 1) / 12)
        } else if (params.payTypeId == 1 && (num + 1) % 4 === 0) {
            // 季付
            curEnd = util.newDateAddYear(beginDate, (num + 1) / 4)
        } else if (params.payTypeId == 2 && (num + 1) % 2 === 0) {
            // 半年付
            curEnd = util.newDateAddYear(beginDate, (num + 1) / 2)
        } else {
            curEnd = me.getEditCycleBillEndDate(
                curBegin,
                payType,
                unitType,
                util.dateFormat(endDate),
                isChangeCycle
            )
        }

        //加入额外的逻辑处理（其他费用-随房租付），
        if (params.payTypeId == 5) {
            if ((payType == 6 || payType == 8) && (num + 1) % 13 === 0) {
                // 押一付一  押二付一
                curEnd = util.newDateAddYear(beginDate, (num + 1) / 12)
            } else if (payType == 1 && (num + 1) % 4 === 0) {
                // 季付
                curEnd = util.newDateAddYear(beginDate, (num + 1) / 4)
            } else if (payType == 2 && (num + 1) % 2 === 0) {
                // 半年付
                curEnd = util.newDateAddYear(beginDate, (num + 1) / 2)
            } else {
                curEnd = me.getEditCycleBillEndDate(
                    curBegin,
                    payType,
                    unitType,
                    util.dateFormat(endDate),
                    isChangeCycle
                )
            }
        }
        num++
    }
    //如果计算出的账单结束日期大于实际合同截止日期，就说明剩下的时间不足一个月了，计算日租金
    if (
        curEnd > newEndDate &&
        params.identity == 'tenant' &&
        (params.costtype == '2' || params.costtype == '8')
    ) {

        let dayIndex=1
        let isWholeMonth=false
        while(true){
            let newDate=util.dateAddDay(util.dateAddMonth(beginDate, num), -1)//me.dateAddMonth(curBegin,dayIndex)
            if(newDate.getTime()==endDate.getTime()){
                isWholeMonth=true
                break
            }/*else if(newDate.getTime()<endDate.getTime()){
             dayIndex++
          }*/else{
                break
            }
        }
        var amount = params.totalamount
        var sum = ''
        if(isWholeMonth){
            sum=(amount).toFixed(2)
        }else{
            sum = util.getSumMoneyOther(params, curBegin, endDate);
        }
        if (num == 1) {
            var collectionDate = title=='续租'||params.flagType==2?me.dateFormat(curBegin):me.dateFormat(createDate)
        } else {
            if(params.payTypeId =='6' || params.renterType == '6'){                 //赵
                var collectionDate = util.getPreMonth2(util.dateFormat3(curBegin,beforeDays));
            }
            else if(params.payTypeId ==8){
                var collectionDate = util.getPreMonth2(util.dateFormat3(curBegin,1))
            }else{
                var collectionDate = util.getPreMonth2(util.dateFormat3(curBegin,beforeDays));
            }
        }
        let billdescription = ''
        if (params.typeFlag && params.typeFlag == 1) {
            billdescription = params.describe
        } else {
            billdescription = '第' + num + '期'
            // billdescription = params.describe + num + '期';
        }
        let costEndDate = util.dateFormat(curEnd)
        if (curEnd > endDate) {
            costEndDate = util.dateFormat(endDate)
        }
        //位置
        bills.push({
            billCollectionPaymentType: direction,
            costtype: params.costtype,
            costtypeVal: params.costtypeVal,
            payTypeId: params.payTypeId,
            otherPayTypeId: params.otherPayTypeId,
            totalamount: Number(sum).toFixed(2),
            count: params.count,
            identity: params.identity, //owner业主,tenant租客
            nowCount: params.nowCount,
            billdescription: billdescription,
            // 应付日期
            collectionDate: num == 1 ? collectionDate : collectionDate,
            // collectionDate: collectionDate,
            costBeginDate: util.dateFormat(curBegin),
            costEndDate: util.dateFormat(costEndDate),
            isDownpayment:'否'
        })
    }

    //只针对其他费用的账单计算
    if (
        params.costtype != '2' &&
        params.costtype != '1' &&
        params.costtype != '8' &&
        params.payTypeId != '4' &&
        params.identity == 'tenant'
    ) {
        var amount = params.totalamount
        let sum = 0
        if (util.dateFormat(endDate) == util.dateFormat(curEnd)) {
            return bills
        }
        if (num == 1) {
            var collectionDate = title=='续租'?me.dateFormat(curBegin):me.dateFormat(createDate)
        } else {
            /*if (params.payTypeId == '6' || params.renterType == '6') {
              var collectionDate = me.getPreMonth(me.dateFormat(curBegin))
            } else {
              var collectionDate = me.dateFormat(
                me.dateAddDay(curBegin, -1 * advanceDay)
              )
            }*/
            if(params.payTypeId =='6' || params.renterType == '6'){                 //赵
                var collectionDate = util.getPreMonth2(util.dateFormat3(curBegin,beforeDays));
            }
            else if(params.payTypeId ==8){
                var collectionDate = util.getPreMonth2(util.dateFormat3(curBegin,1))
            }else{
                var collectionDate = util.getPreMonth2(util.dateFormat3(curBegin,beforeDays));
            }
        }
        let tempCount = params.count //租期（月份数），
        let costEndDate = util.dateFormat(curEnd)
        if (curEnd > endDate) {
            costEndDate = util.dateFormat(endDate)
            if (num != 1) {
                tempCount = tempCount + 1 //如果租期除了整月外还有多余且不足一个月的天数， + 1
            }
        }
        if (params.payTypeId == '1') {
            if (unitType == config.PAY_UNIT_YEAR) {
                //每年
                sum = Number(amount / 12, 2) * Math.floor(tempCount % 3)
            } else if (unitType == config.PAY_UNIT_DAY) {
                //每天
                var days = util.dayMinus(
                    new Date(util.dateFormat(curBegin)),
                    new Date(costEndDate)
                )
                sum = amount * days
            } else {
                sum = amount * Math.floor(tempCount % 3)
            }
        } else if (params.payTypeId == '2') {
            if (unitType == config.PAY_UNIT_YEAR) {
                sum = Number(amount / 12, 2) * Math.floor(tempCount % 6)
            } else if (unitType == config.PAY_UNIT_DAY) {
                //每天
                var days = util.dayMinus(
                    new Date(util.dateFormat(curBegin)),
                    new Date(costEndDate)
                )
                sum = amount * days
            } else {
                sum = amount * Math.floor(tempCount % 6)
            }
        } else if (params.payTypeId == '3') {
            if (unitType == config.PAY_UNIT_YEAR) {
                sum = Number(amount / 12, 2) * Math.floor(tempCount % 12)
            } else if (unitType == config.PAY_UNIT_DAY) {
                //每天
                var days = util.dayMinus(
                    new Date(util.dateFormat(curBegin)),
                    new Date(costEndDate)
                )
                sum = amount * days
            } else {
                sum = amount * Math.floor(tempCount % 12)
            }
        } else if (params.payTypeId == '5') {
            if (unitType == config.PAY_UNIT_YEAR) {
                sum = Number(amount / 12, 2) * Math.floor(tempCount % 12)
            } else if (unitType == config.PAY_UNIT_DAY) {
                //每天
                var days = util.dayMinus(
                    new Date(util.dateFormat(curBegin)),
                    new Date(costEndDate)
                )
                sum = amount * days
            } else if (unitType == config.PAY_UNIT_MONTH) {
                //每月

                let months = util.getDistanceMonth(curBegin, endDate)
                sum = amount * (Number(months) + 1)
            } else {
                sum = amount
            }

            // 其他费用付款周期 元/天
            if (params.otherPayUnitType == config.PAY_UNIT_DAY) {
                sum =
                    Number(
                        util.getDaysSum(
                            util.dateFormat(curBegin),
                            util.dateFormat(newEndDate)
                        )
                    ) * Number(amount)
            } else if (params.otherPayUnitType == config.PAY_UNIT_MONTH) {
                sum =
                    Number(
                        util.getOtherDistanceMonth(
                            util.dateFormat(curBegin),
                            util.dateFormat(curEnd)
                        )
                    ) * Number(amount)
            }
        }
        if (params.payTypeId == '0') {
            if (unitType == 0) {
                //如果其他费用付款周期是每天，那么金额要按照每日计算，否则计算整月或是整年
                let curBeginString = util.dateFormat(curBegin)
                let daysSum = util.getDaysSum(curBeginString, costEndDate)
                sum = amount * Math.round(daysSum)
            } else if (num == 1 && (unitType == 2 || unitType == 1)) {
                //租期不满一个费用周期时，费用周日选择的是年或者月，直接加一个月的其他费用的金额

                if (unitType == 1) {
                    sum = sum + amount
                } else {
                    sum = sum + Number(amount / 12, 2)
                }
            } else if (num >= 2) {
                if (unitType == 1) {
                    sum = amount
                } else {
                    sum = sum + Number(amount / 12, 2)
                }
            }
            // else if(){
            //     //先计算租期不满一个费用周期时，整月的日期结束日是哪天
            //     fullCostEndTime = this.dateAddMonth(curBegin,params.count);
            //     //再计算这个日期到合同结束日期的
            // }

            bills.push({
                billCollectionPaymentType: direction,
                costtype: params.costtype,
                costtypeVal: params.costtypeVal,
                payTypeId: params.payTypeId,
                otherPayTypeId: params.otherPayTypeId,
                totalamount: Number(sum).toFixed(2),
                count: params.count,
                identity: params.identity, //owner业主,tenant租客
                nowCount: params.nowCount,
                //billdescription: params.describe,
                billdescription: '第'+num+'期',
                // 应付日期
                collectionDate: num == 1 ? collectionDate : collectionDate,
                costBeginDate: util.dateFormat(curBegin),
                costEndDate: util.dateFormat(costEndDate),
                isDownpayment:'否'
            })
        } else if (params.payTypeId == '1') {
            if (unitType == 0) {
                //如果其他费用付款周期是每天，那么金额要按照每日计算，否则计算整月或是整年
                let curBeginString = util.dateFormat(curBegin)
                let daysSum = util.getDaysSum(curBeginString, costEndDate)
                sum = amount * Math.round(daysSum)
            } else if (num == 1 && (unitType == 2 || unitType == 1)) {
                //租期不满一个费用周期时，费用周日选择的是年或者月，直接加一个月的其他费用的金额

                if (unitType == 1) {
                    sum = sum + amount
                } else {
                    sum = sum + Number(amount / 12, 2)
                }
            }
            // else if(){
            //     //先计算租期不满一个费用周期时，整月的日期结束日是哪天
            //     fullCostEndTime = this.dateAddMonth(curBegin,params.count);
            //     //再计算这个日期到合同结束日期的
            // }

            bills.push({
                billCollectionPaymentType: direction,
                costtype: params.costtype,
                costtypeVal: params.costtypeVal,
                payTypeId: params.payTypeId,
                otherPayTypeId: params.otherPayTypeId,
                totalamount: Number(sum).toFixed(2),
                count: params.count,
                identity: params.identity, //owner业主,tenant租客
                nowCount: params.nowCount,
                //billdescription: params.describe,
                billdescription: '第'+num+'期',
                // 应付日期
                collectionDate: num == 1 ? collectionDate : collectionDate,
                costBeginDate: util.dateFormat(curBegin),
                costEndDate: util.dateFormat(costEndDate),
                isDownpayment:'否'
            })
        } else if (params.payTypeId == '2') {
            if (unitType == 0) {
                //如果其他费用付款周期是每天，那么金额要按照每日计算，否则计算整月或是整年
                let curBeginString = util.dateFormat(curBegin)
                let daysSum = util.getDaysSum(curBeginString, costEndDate)
                sum = amount * Math.round(daysSum)
            } else if (num == 1 && (unitType == 2 || unitType == 1)) {
                //租期不满一个费用周期时，费用周日选择的是年或者月，直接加一个月的其他费用的金额

                if (unitType == 1) {
                    sum = sum + amount
                } else {
                    sum = sum + Number(amount / 12, 2)
                }
            }
            bills.push({
                billCollectionPaymentType: direction,
                costtype: params.costtype,
                costtypeVal: params.costtypeVal,
                payTypeId: params.payTypeId,
                otherPayTypeId: params.otherPayTypeId,
                totalamount: Number(sum).toFixed(2),
                count: params.count,
                identity: params.identity, //owner业主,tenant租客
                nowCount: params.nowCount,
                //billdescription: params.describe,
                billdescription: '第'+num+'期',
                // 应付日期
                collectionDate: num == 1 ? collectionDate : collectionDate,
                costBeginDate: util.dateFormat(curBegin),
                costEndDate: util.dateFormat(costEndDate),
                isDownpayment:'否'
            })
        } else if (params.payTypeId == '3') {
            if (unitType == 0) {
                //如果其他费用付款周期是每天，那么金额要按照每日计算，否则计算整月或是整年
                let curBeginString = util.dateFormat(curBegin)
                let daysSum = util.getDaysSum(curBeginString, costEndDate)
                sum = amount * Math.round(daysSum)
            } else if (num == 1 && (unitType == 2 || unitType == 1)) {
                //租期不满一个费用周期时，费用周日选择的是年或者月，直接加一个月的其他费用的金额

                if (unitType == 1) {
                    sum = sum + amount
                } else {
                    sum = sum + Number(amount / 12, 2)
                }
            }
            bills.push({
                billCollectionPaymentType: direction,
                costtype: params.costtype,
                costtypeVal: params.costtypeVal,
                payTypeId: params.payTypeId,
                otherPayTypeId: params.otherPayTypeId,
                totalamount: Number(sum).toFixed(2),
                count: params.count,
                identity: params.identity, //owner业主,tenant租客
                nowCount: params.nowCount,
                //billdescription: params.describe,
                billdescription: '第'+num+'期',
                // 应付日期
                collectionDate: num == 1 ? collectionDate : collectionDate,
                costBeginDate: util.dateFormat(curBegin),
                costEndDate: util.dateFormat(costEndDate),
                isDownpayment:'否'
            })
        } else if (params.payTypeId == '5') {
            if (sum != 0) {
                sum = params.totalamount
                // 其他费用付款周期 元/天
                if (params.otherPayUnitType == config.PAY_UNIT_DAY) {
                    sum =
                        Number(
                            util.getDaysSum(
                                util.dateFormat(curBegin),
                                util.dateFormat(newEndDate)
                            )
                        ) * Number(amount)
                } else if (params.otherPayUnitType == config.PAY_UNIT_MONTH) {
                    let months = util.getOtherDistanceMonth(
                        util.dateFormat(curBegin),
                        util.dateFormat(newEndDate),
                        payType
                    )
                    sum = Number(months) * Number(amount)
                    // sum = Number(this.getDistanceMonth(this.dateFormat(curBegin),this.dateFormat(newEndDate))) * Number(amount)
                    // 判断开始时间、结束时间天数差是否为整月，不为整月的话，向上取整
                    let tempEndDate = util.dateAddMonth(curBegin, months)
                    if (tempEndDate.getTime() <= newEndDate.getTime()) {
                        sum = (Number(months) + 1) * Number(amount)
                    } else {
                        sum = Number(months) * Number(amount)
                    }
                }
                bills.push({
                    billCollectionPaymentType: direction,
                    costtype: params.costtype,
                    costtypeVal: params.costtypeVal,
                    payTypeId: params.payTypeId,
                    otherPayTypeId: params.otherPayTypeId,
                    totalamount: Number(sum).toFixed(2),
                    count: params.count,
                    identity: params.identity, //owner业主,tenant租客
                    nowCount: params.nowCount,
                    //billdescription: params.describe,
                    billdescription: '第'+num+'期',
                    // 应付日期
                    collectionDate: num == 1 ? collectionDate : collectionDate,
                    costBeginDate: util.dateFormat(curBegin),
                    costEndDate: util.dateFormat(costEndDate),
                    isDownpayment:'否'
                })
            }
        }
    }
    return bills
}

function getEditCycleBillEndDate(date, payType, unitType, endDate, isChangeCycle) {
    var me = this
    date = new Date(date)
    let size = 0
    //月付
    if (
        payType == config.PAY_TYPE_MONTH ||
        payType == config.PAY_TYPE_ONE_BY_ONE ||
        payType == config.PAY_TYPE_TWO_BY_ONE|| payType=='9'
    ) {
        if(isChangeCycle){  //如果当前周期是开始修改的周期，则账单周期为15天
            return util.dateAddDay(date, 15);
        }else{
            return util.dateAddDay(util.dateAddMonth(date, 1), -1);
        }
    }
    //季付
    if (
        payType == config.PAY_TYPE_SEASON ||
        payType == config.PAY_TYPE_ONE_BY_THREE ||
        payType == config.PAY_TYPE_TWO_BY_THREE
    ) {
        //date.setMonth(date.getMonth()+3);
        // size = 3;
        return util.dateAddDay(util.dateAddMonth(date, 3), -1)
    }
    //半年付
    if (payType == config.PAY_TYPE_HALF_YEAR) {
        //date.setMonth(date.getMonth()+6);
        // size = 6;
        return util.dateAddDay(util.dateAddMonth(date, 6), -1)
    }
    //年付
    if (payType == config.PAY_TYPE_YEAR) {
        //date.setMonth(date.getMonth()+12);

        // size = 12;
        return util.dateAddDay(util.dateAddMonth(date, 12), -1)
    }

    //随房租付，判断类型
    if (payType == config.PAY_TYPE_ONCE) {
        if (
            unitType == config.PAY_TYPE_MONTH ||
            unitType == config.PAY_TYPE_ONE_BY_ONE ||
            unitType == config.PAY_TYPE_TWO_BY_ONE|| unitType =='9'
        ) {
            //date.setMonth(date.getMonth()+1);
            // size = 1;
            if(isChangeCycle){  //如果当前周期是开始修改的周期，则账单周期为15天
                return util.dateAddDay(date, 15);
            }else{
                return util.dateAddDay(util.dateAddMonth(date, 1), -1);
            }
        }
        //季付
        if (
            unitType == config.PAY_TYPE_SEASON ||
            unitType == config.PAY_TYPE_ONE_BY_THREE ||
            unitType == config.PAY_TYPE_TWO_BY_THREE
        ) {
            //date.setMonth(date.getMonth()+3);
            // size = 3;
            return util.dateAddDay(util.dateAddMonth(date, 3), -1)
        }
        //半年付
        if (unitType == config.PAY_TYPE_HALF_YEAR) {
            //date.setMonth(date.getMonth()+6);
            // size = 6;
            return util.dateAddDay(util.dateAddMonth(date, 6), -1)
        }
        //年付
        if (unitType == config.PAY_TYPE_YEAR) {
            //date.setMonth(date.getMonth()+12);

            // size = 12;
            return util.dateAddDay(util.dateAddMonth(date, 12), -1)
        }
        if (unitType == config.PAY_TYPE_ONCE) {
            return endDate
        }
    }

    return date
}

function getEditCycleSumMoney(params, curBegin, curEnd,endDate,num,couponType,quarterlyDiscount,cycle,dayNum,bills){
    var me = this
    // 输入的金额
    var amount = params.totalamount
    var unitType = params.payUnitType  //6月
    var payType = params.payTypeId  //5随房租付
    if (params.payTypeId == '') {
        payType = params.otherPayTypeId
    }
    var sum = 0
    //月付
    //debugger
    if (
        payType == config.PAY_TYPE_MONTH ||
        payType == config.PAY_TYPE_ONE_BY_ONE ||
        payType == config.PAY_TYPE_TWO_BY_ONE|| payType=='9'
    ) {
        // 按天计算的费用
        if (unitType == config.PAY_UNIT_DAY) {
            sum = util.dayDifference(curBegin, curEnd) * amount
        }
        if (unitType == config.PAY_UNIT_MONTH) {
            if(curEnd.getTime()==endDate.getTime()&&params.reduceLastRent=='是'&&params.chooseBreakCard&&Number(params.count)>=12){
                sum=0
            }else{
                if(quarterlyDiscount&&num==1&&couponType=='3'){
                    sum=quarterlyDiscount*amount
                }else if((curEnd.getTime() - curBegin.getTime())/(3600*24*1000)==dayNum){
                    sum = amount / 30 * (curEnd.getTime() - curBegin.getTime())/(3600*24*1000);
                }else{
                    if(num==13){
                        sum = amount - Number(bills[2].totalamount);
                    } else {
                        sum = amount;
                    }
                }
            }
        }
        if (unitType == config.PAY_UNIT_YEAR) {
            sum = (amount / 12).toFixed(2)
        }
    }
    //季付
    if (
        payType == config.PAY_TYPE_SEASON ||
        payType == config.PAY_TYPE_ONE_BY_THREE ||
        payType == config.PAY_TYPE_TWO_BY_THREE
    ) {
        // 按天计算的费用
        if (unitType == config.PAY_UNIT_DAY) {
            sum = me.dayDifference(curBegin, curEnd) * amount
        }
        if (unitType == config.PAY_UNIT_MONTH) {
            if(quarterlyDiscount&&num==1&&couponType=='3'){
                sum=(2+quarterlyDiscount)*amount
            }else if(quarterlyDiscount&&curEnd.getTime()==endDate.getTime()&&couponType=='4'&&!params.chooseBreakCard){
                sum=(2+quarterlyDiscount)*amount
            }else{
                if(curEnd.getTime()==endDate.getTime()&&params.reduceLastRent=='是'&&params.chooseBreakCard&&Number(params.count)>=12){
                    sum = 2 * amount;
                }else{
                    sum = 3 * amount;
                }
            }
        }
        if (unitType == config.PAY_UNIT_YEAR) {
            sum = amount / 4
        }
    }
    //半年付
    if (payType == config.PAY_TYPE_HALF_YEAR) {
        // 按天计算的费用
        if (unitType == config.PAY_UNIT_DAY) {
            sum = me.dayDifference(curBegin, curEnd) * amount
        }
        if (unitType == config.PAY_UNIT_MONTH) {
            if(curEnd.getTime()==endDate.getTime()&&params.reduceLastRent=='是'&&params.chooseBreakCard&&Number(params.count)>=12){
                sum= 5 * amount
            }else{
                sum = 6 * amount
            }
        }
        if (unitType == config.PAY_UNIT_YEAR) {
            sum = amount / 2
        }
    }
    //年付
    if (payType == config.PAY_TYPE_YEAR) {
        // 按天计算的费用
        if (unitType == config.PAY_UNIT_DAY) {
            sum = 365 * amount
        }
        if (unitType == config.PAY_UNIT_MONTH) {
            if(curEnd.getTime()==endDate.getTime()&&params.reduceLastRent=='是'&&params.chooseBreakCard&&Number(params.count)>=12){
                sum= 11 * amount;
            }else{
                sum = 12 * amount
            }
        }
        if (unitType == config.PAY_UNIT_YEAR) {
            sum = amount
        }
    }
    //随房租付
    if (payType == config.PAY_TYPE_WITHRENT) {
        if((curEnd.getTime() - curBegin.getTime())/(3600*24*1000)==dayNum){
            sum = amount / 30 * (curEnd.getTime() - curBegin.getTime())/(3600*24*1000);
        }else{
            if(num==13){
                sum = amount - Number(bills[2].totalamount);
            } else {
                sum = amount;
            }
        }
        // sum = amount
    }

    return sum
}
// 计算两个日期相差几个月（租客合同随房租付其他费用使用）
function getOtherDistanceMonth(startTime, endTime, payType,num,cycle) {
    startTime = new Date(startTime)
    endTime = new Date(endTime)
    let StartDay = startTime.getDate()
    let StartMonth = startTime.getMonth()
    let StartYear = startTime.getFullYear()
    let endDay = endTime.getDate()
    let endMonth = endTime.getMonth()
    let endYear = endTime.getFullYear()
    let day = 0
    if(endMonth==1 && endDay==29 && StartDay==30){   //如果是2.29，走下面else的方法会==0使day=0，在走下面会算成两个月
        day =  -1;
    }else{
        day = endDay + 1 - StartDay >= 0 ? 0 : -1;
    }
    if(endMonth==1&&endDay==28&&(StartDay==30||StartDay==31)){
        day+=1
    }else if(endDay-StartDay>0){
        if(num!=cycle){
            day++
        }
    }
    // eslint-disable-next-line no-self-compare
    var diffecence = 0
    if (payType === '6' || payType === '8') {
        diffecence = 0
    } else if (payType === '1') {
        diffecence = 2
    } else if (payType === '2') {
        diffecence = 5
    }
    var diffecenceMonth = 0
    if (endMonth > StartMonth) {
        diffecenceMonth =
            util.getMonths(util.dateFormat(startTime), util.dateFormat(endTime)) - 1
        // diffecenceMonth = endMonth - StartMonth;
    } else {
        diffecenceMonth =
            util.getMonths(util.dateFormat(startTime), util.dateFormat(endTime)) - 1
        // diffecenceMonth = StartMonth - endMonth;
    }
    if (
        diffecenceMonth === diffecence &&
        (endDay + 1 - StartDay === 31 || endDay + 1 - StartDay === 30)
    ) {
        day = 1
    } else if (
        endMonth + 1 === 2 &&
        (endDay + 1 - StartDay === 28 || endDay + 1 - StartDay === 29)
    ) {
        day = 1
    }

    return (endYear - StartYear) * 12 + (endMonth - StartMonth) + day
}

export default {
    creatEditCycleBills:creatEditCycleBills,
    createEditCycleBillDetail:createEditCycleBillDetail,
    getEditCycleBills:getEditCycleBills,
    getEditCycleSumMoney:getEditCycleSumMoney,
    getEditCycleBillEndDate:getEditCycleBillEndDate,
    getOtherDistanceMonth:getOtherDistanceMonth,
}