<template>
  <div>
    <div class="potential">
      <rxNavBar
        v-if="fromType == '1'"
        :title="title"
        androidOrIOSFlag="true"
      ></rxNavBar>
      <rxNavBar v-else :title="title"></rxNavBar>
    </div>
    <!--上传图片loading-->
    <loading v-if="loadingFlag"></loading>

    <!-- 是否可以更改地址 -->

    <div class="part-inputpart part-inputpart-houseAddress" v-if="!ownerInfo.roomState">    <!-- 可以更改地址 -->
      <div class="part-inputpart-row" @click="goToSelectHouse">
        <span :class="!houseAddress ? 'content-none' : 'content-have'"></span>
        <span class="part-inputpart-row-header">房源地址</span>
        <span class="content-divide">|</span>
        <div>
          <input
            type="textarea"
            v-model="houseAddress"
            placeholder="点击进房源搜索页面"
            class="roomHouse"
            readonly="true"
          />
        </div>
        <img class="imageTo" src="../../../assets/images/youjiantou.png" />
      </div>
    </div>
    <div class="part-inputpart part-inputpart-houseAddress" v-else>    <!-- 不可以更改地址 -->
      <div class="part-inputpart-row" style="height: 50px">
        <span :class="!houseAddress ? 'content-none' : 'content-have'"></span>
        <span class="part-inputpart-row-header">房源地址</span>
        <span class="content-divide">|</span>
        <div class="nopadding" style="height: 50px">
              <van-field
                type="textarea"
                v-model="houseAddress"
                oninput="value"
                :disabled="ownerInfo.roomState"
                placeholder="点击进房源搜索页面"
                class="nopadding"
              />
            </div>
        <img class="imageTo" src="../../../assets/images/youjiantou.png" />
      </div>
    </div>
    <!-- 房源特色（判断有无） -->
    <div class="infos" v-if="roomFeatureList.length != 0">
      <div class="infosInfo3">房源特色</div>
      <div class="infos-info-2" v-for="item,index in roomFeatureList" :key="index">
        <div class="smallText-10">
          {{ item.roomCharacteristicName }}
        </div>
      </div>
    </div>

    <!-- 间房智能设备 -->
    <div class="infos" style="float: left; " v-if="roomEqumentMap.length != 0">
      <div class="infosInfo3">智能设备</div>
      <div class="infos-info-2" v-if="roomEqumentMap[0].isEquimentLock == 1">
        <div class="smallText-10">
          <span>智能门锁 </span>
        </div>
      </div>

      <div class="infos-info-2" v-if="roomEqumentMap[0].isEquimentMeter == 1">
        <div class="smallText-10">
          <span>智能电表 </span>
        </div>
      </div>
    </div>

    <div class="isWhetherKitchen" v-if="isWhetherKitchen==1&&contractInfo.houseType!='2'">
      <div class="smallText-10">
        <span>火房</span>
      </div>
    </div>

    <!--房源地址下拉选-->
    <!--        <van-popup v-model="isRoomHouseListShow" position="bottom">-->
    <!--            <div class="searchDiv">-->
    <!--                <van-search  placeholder="请输入" v-model="roomSearch" size="large" @input="getRenterRoomHouseList()"/>-->
    <!--            </div>-->
    <!--            <van-picker show-toolbar value-key="roomDetailedAddress" :columns="roomHouseList"-->
    <!--                        @cancel="isRoomHouseListShow = false" @confirm="selectRoomHouseOption" />-->
    <!--        </van-popup>-->

    <div class="headbar" :class="isFixed ? 'headbar-fixed' : ''">
      <div @click="isTenantInfo">
        <span>租客信息</span>
        <div
          :class="canRenterActive() ? 'headbar-greentick' : 'headbar-graytick'"
        ></div>
        <div v-if="0 == whichInfo" class="headbar-redline"></div>
      </div>
      <div class="content-bigdivide"></div>
      <div @click="isContractInfo">
        <span>合同信息</span>
        <div
          :class="
            canContractActive() ? 'headbar-greentick' : 'headbar-graytick'
          "
        ></div>
        <div v-if="1 == whichInfo" class="headbar-redline"></div>
      </div>
    </div>
    <!--        租客信息-->
    <div v-if="0 == whichInfo" :class="isFixed ? 'contentpart' : ''">
      <div class="part">
        <span class="part-subtitle">承租人</span>
        <div class="part-inputpart">
          <div class="part-inputpart-row">
            <span
              :class="!ownerInfo.phone ? 'content-none' : 'content-have'"
            ></span>
            <span class="part-inputpart-row-header">联系电话</span>
            <span class="content-divide">|</span>
            <!--                        <input type="tel" v-model="ownerInfo.phone" placeholder="填写方便联系的电话">-->
            <div class="nopadding" @click="skipChooseRenter">
              <van-field
                :formatter="formatter"
                type="tel"
                v-model="ownerInfo.phone"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="ownerInfo.mobileState"
                placeholder="填写方便联系的电话"
                maxlength="11"
                @blur="animatePhone"
                class="nopadding"
              />
            </div>
            <img class="imageTo" src="../../../assets/images/youjiantou.png" />
          </div>
          <div>
            <div class="part-inputpart-row part-inputpart-row-short">
              <div
                :class="!ownerInfo.name ? 'content-none' : 'content-have'"
              ></div>
              <span class="part-inputpart-row-header">姓名</span>
              <span class="content-divide">|</span>
              <!--                            <input v-model="ownerInfo.name" placeholder="租客姓名">-->
              <div class="nopadding">
                <van-field
                  class="nopadding"
                  :formatter="formatter"
                  type="text"
                  v-model="ownerInfo.name"
                  :disabled="ownerInfo.nameState"
                  placeholder="租客姓名"
                  @blur="checkOwnerName"
                />
              </div>
            </div>
            <div class="part-inputpart-row-sex">
              <span
                class="part-inputpart-man part-inputpart-row-header"
                :class="
                  getWhatSex(1)
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-short-graytext'
                "
                @click="mansex"
                >男</span
              >
              <span
                class="content-divide content-divide-position part-inputpart-row-short-graytext"
                >|</span
              >
              <span
                class="part-inputpart-woman part-inputpart-row-header"
                :class="
                  getWhatSex(0)
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-short-graytext'
                "
                @click="womansex"
                >女</span
              >
            </div>
          </div>
          <div id="ownerCardPanel" class="part-inputpart-row">
            <span
              :class="ownerInfo.cardNumber ? 'content-have' : 'content-none'"
            ></span>
            <span class="part-inputpart-row-header" @click="clickCardShow">{{
              ownerInfo.card.label
            }}</span>
            <img
              :class="
                isCardShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
            <span class="content-divide">|</span>
            <!--                        <input type="tel" v-model="ownerInfo.cardNumber" placeholder="填写证件号码">-->
            <div class="nopadding">
              <van-field
                class="nopadding"
                :formatter="formatter"
                v-model="ownerInfo.cardNumber"
                type="text"
                placeholder="填写证件号码"
                :disabled="ownerInfo.certificateState"
                @blur="animateOwnerIdCard"
              />
            </div>
          </div>
          <!--租客证件下拉选-->
          <van-popup v-model="isCardShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="label"
              :columns="cardArr"
              @cancel="isCardShow = false"
              @confirm="selectCardOption"
            />
          </van-popup>
          <div class="part-inputpart-row">
            <div
              :class="
                !ownerInfo.emergencyContactName
                  ? 'content-none'
                  : 'content-have'
              "
            ></div>
            <span class="part-inputpart-row-header">紧急联系人</span>
            <span class="content-divide">|</span>
            <!--                        <input v-model="ownerInfo.emergencyContactName" placeholder="紧急联系人姓名">-->
            <div class="nopadding">
              <van-field
                class="nopadding"
                :formatter="formatter"
                type="text"
                v-model="ownerInfo.emergencyContactName"
                placeholder="紧急联系人姓名"
              />
            </div>
          </div>
          <div class="part-inputpart-row">
            <span
              :class="
                !ownerInfo.emergencyContactPhone
                  ? 'content-none'
                  : 'content-have'
              "
            ></span>
            <span class="part-inputpart-row-header">紧急联系电话</span>
            <span class="content-divide">|</span>
            <!--                        <input type="tel" v-model="ownerInfo.emergencyContactPhone" placeholder="填写方便联系的电话">-->
            <div class="nopadding">
              <van-field
                class="nopadding"
                :formatter="formatter"
                type="text"
                v-model="ownerInfo.emergencyContactPhone"
                maxlength="11"
                placeholder="填写方便联系的电话"
                @blur="animateEmPhone"
              />
            </div>
          </div>

          <!--邮箱信息-->
          <div class="part-inputpart-row">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header"
              >邮箱</span
            >
            <span class="content-divide">|</span>
            <div class="nopadding">
              <van-field
                class="nopadding"
                :formatter="formatter"
                type="text"
                v-model="ownerInfo.emergencyContactEmail"
                placeholder="填写联系邮箱"
                @blur="animateEmail"
              />
            </div>
          </div>
          <!--紧急联系地址信息-->
          <div class="part-inputpart-row">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header"
              >紧急联系地址</span
            >
            <span class="content-divide">|</span>
            <div class="nopadding">
              <van-field
                class="nopadding"
                :formatter="formatter"
                type="text"
                v-model="ownerInfo.emergencyContactAddress"
                placeholder="填写紧急联系地址"
              />
            </div>
          </div>

          <div class="part-inputpart-cardpic">
            <div class="part-inputpart-row part-inputpart-cardpic-row">
              <span
                class="part-inputpart-row-enablenon part-inputpart-row-header"
                >证件凭证</span
              >
            </div>
            <div class="part-inputpart-cardpic-module">
              <div class="part-cardpic-module">
                <div class="part-inputpart-cardpic-pic">
                  <img
                    v-show="ownerInfo.CertificateFront"
                    class="part-inputpart-cardpic-pic-img"
                    :src="ownerInfo.CertificateFront"
                    @click="changUpload('CertificateFront')"
                  />
                </div>
                <van-uploader
                  v-show="!ownerInfo.CertificateFront"
                  ref="CertificateFront"
                  :preview-image="false"
                  :after-read="afterReadCertificateFront"
                  upload-icon="plus"
                  :max-count="1"
                >
                  <div class="part-inputpart-cardpic-button"></div>
                </van-uploader>
                <div class="part-inputpart-cardpic-text">
                  {{ ownerInfo.card.label }}正面
                </div>
              </div>
              <div class="part-cardpic-module">
                <div class="part-inputpart-cardpic-pic">
                  <img
                    v-show="ownerInfo.CertificateBack"
                    class="part-inputpart-cardpic-pic-img"
                    :src="ownerInfo.CertificateBack"
                    @click="changUpload('CertificateBack')"
                  />
                </div>
                <van-uploader
                  v-show="!ownerInfo.CertificateBack"
                  ref="CertificateBack"
                  :preview-image="false"
                  :after-read="afterReadCertificateBack"
                  upload-icon="plus"
                >
                  <div class="part-inputpart-cardpic-button"></div>
                </van-uploader>
                <div class="part-inputpart-cardpic-text">
                  {{ ownerInfo.card.label }}反面
                </div>
              </div>
              <div class="part-cardpic-module">
                <div class="part-inputpart-cardpic-pic">
                  <img
                    v-show="ownerInfo.CertificateHandHeld"
                    class="part-inputpart-cardpic-pic-img"
                    :src="ownerInfo.CertificateHandHeld"
                    @click="changUpload('CertificateHandHeld')"
                  />
                </div>
                <van-uploader
                  v-show="!ownerInfo.CertificateHandHeld"
                  ref="CertificateHandHeld"
                  :preview-image="false"
                  :after-read="afterReadCertificateHandHeld"
                  upload-icon="plus"
                  :max-count="1"
                >
                  <div class="part-inputpart-cardpic-button"></div>
                </van-uploader>
                <div class="part-inputpart-cardpic-text">
                  手持{{ ownerInfo.card.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part">
        <span class="part-subtitle">同住人</span>
        <div class="part-inputpart">
          <div class="part-inputpart-row">
            <!--                        <div :class=" !ownerInfo.liveTogether ? 'content-none' : 'content-have'"></div>-->
            <div class="content-have"></div>
            <span class="part-inputpart-row-header">是否有同住</span>
            <div>
              <span
                class="part-inputpart-yes part-inputpart-row-header"
                :class="
                  ownerInfo.liveTogether
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-short-graytext'
                "
                @click="isliveTogether"
                >是</span
              >
              <span
                class="content-divide content-divide-position part-inputpart-row-short-graytext"
                >|</span
              >
              <span
                class="part-inputpart-woman part-inputpart-row-header"
                :class="
                  !ownerInfo.liveTogether
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-short-graytext'
                "
                @click="noliveTogether"
                >否</span
              >
            </div>
          </div>
          <div class="part-inputpart-row" v-if="ownerInfo.liveTogether">
            <span
              :class="
                !cohabitingPersonInfo.phone ? 'content-none' : 'content-have'
              "
            ></span>
            <span class="part-inputpart-row-header">联系电话</span>
            <span class="content-divide">|</span>
            <!--                        <input type="tel" v-model="cohabitingPersonInfo.phone" placeholder="填写方便联系的电话">-->
            <div class="nopadding">
              <van-field
                class="nopadding"
                :formatter="formatter"
                type="text"
                v-model="cohabitingPersonInfo.phone"
                maxlength="11"
                placeholder="填写方便联系的电话"
                @blur="animateCoPhone"
              />
            </div>
          </div>
          <div v-if="ownerInfo.liveTogether">
            <div class="part-inputpart-row part-inputpart-row-short">
              <div
                :class="
                  !cohabitingPersonInfo.name ? 'content-none' : 'content-have'
                "
              ></div>
              <span class="part-inputpart-row-header">姓名</span>
              <span class="content-divide">|</span>
              <!--                            <input v-model="cohabitingPersonInfo.name" placeholder="租客姓名">-->
              <div class="nopadding">
                <van-field
                  class="nopadding"
                  :formatter="formatter"
                  type="text"
                  v-model="cohabitingPersonInfo.name"
                  :disabled="cohabitingPersonInfo.nameState"
                  placeholder="姓名"
                />
              </div>
            </div>
            <div class="part-inputpart-row-sex">
              <span
                class="part-inputpart-man part-inputpart-row-header"
                :class="
                  getCoSex(1)
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-short-graytext'
                "
                @click="cohabitingPersonmansex"
                >男</span
              >
              <span
                class="content-divide content-divide-position part-inputpart-row-short-graytext"
                >|</span
              >
              <span
                class="part-inputpart-woman part-inputpart-row-header"
                :class="
                  getCoSex(0)
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-short-graytext'
                "
                @click="cohabitingPersonwomansex"
                >女</span
              >
            </div>
          </div>
          <div
            id="cohabitingPersonCardPanel"
            class="part-inputpart-row"
            v-if="ownerInfo.liveTogether"
          >
            <span
              :class="
                cohabitingPersonInfo.cardNumber
                  ? 'content-have'
                  : 'content-none'
              "
            ></span>
            <span
              class="part-inputpart-row-header"
              @click="clickCohabitingPersonCardShow"
              >{{ cohabitingPersonInfo.card.label }}</span
            >
            <pre></pre>
            <img
              :class="
                isCohabitingPersonCardShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
            <span class="content-divide">|</span>
            <!--                        <input type="tel" v-model="cohabitingPersonInfo.cardNumber" placeholder="填写证件号码">-->
            <div class="nopadding">
              <van-field
                class="nopadding"
                :formatter="formatter"
                v-model="cohabitingPersonInfo.cardNumber"
                type="text"
                placeholder="填写证件号码"
                :disabled="cohabitingPersonInfo.certificateState"
                @blur="animateCoIdCard"
              />
            </div>
          </div>
          <!--租客同住人下拉选-->
          <van-popup v-model="isCohabitingPersonCardShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="label"
              :columns="cardArr"
              @cancel="isCohabitingPersonCardShow = false"
              @confirm="selectCohabitingPersonCardOption"
            />
          </van-popup>
          <div class="part-inputpart-cardpic" v-if="ownerInfo.liveTogether">
            <div class="part-inputpart-row part-inputpart-cardpic-row">
              <span
                class="part-inputpart-row-enablenon part-inputpart-row-header"
                >证件凭证</span
              >
            </div>
            <div class="part-inputpart-cardpic-module">
              <div class="part-cardpic-module">
                <div class="part-inputpart-cardpic-pic">
                  <img
                    v-show="cohabitingPersonInfo.CertificateFront"
                    class="part-inputpart-cardpic-pic-img"
                    :src="cohabitingPersonInfo.CertificateFront"
                    @click="changUpload('coCertificateFront')"
                  />
                </div>
                <van-uploader
                  v-show="!cohabitingPersonInfo.CertificateFront"
                  ref="coCertificateFront"
                  :preview-image="false"
                  :after-read="cohabitingPersonCertificateFront"
                  upload-icon="plus"
                  :max-count="1"
                >
                  <div class="part-inputpart-cardpic-button"></div>
                </van-uploader>
                <div class="part-inputpart-cardpic-text">
                  {{ cohabitingPersonInfo.card.label }}正面
                </div>
              </div>
              <div class="part-cardpic-module">
                <div class="part-inputpart-cardpic-pic">
                  <img
                    v-show="cohabitingPersonInfo.CertificateBack"
                    class="part-inputpart-cardpic-pic-img"
                    :src="cohabitingPersonInfo.CertificateBack"
                    @click="changUpload('coCertificateBack')"
                  />
                </div>
                <van-uploader
                  v-show="!cohabitingPersonInfo.CertificateBack"
                  ref="coCertificateBack"
                  :preview-image="false"
                  :after-read="cohabitingPersonCertificateBack"
                  upload-icon="plus"
                >
                  <div class="part-inputpart-cardpic-button"></div>
                </van-uploader>
                <div class="part-inputpart-cardpic-text">
                  {{ cohabitingPersonInfo.card.label }}反面
                </div>
              </div>
              <!--                            <div class="part-cardpic-module">-->
              <!--                                <img v-show="cohabitingPersonInfo.CertificateHandHeld" class="part-inputpart-cardpic-pic" :src="cohabitingPersonInfo.CertificateHandHeld"-->
              <!--                                     @click="changUpload('coCertificateHandHeld')">-->
              <!--                                <van-uploader v-show="!cohabitingPersonInfo.CertificateHandHeld" ref="coCertificateHandHeld"-->
              <!--                                              :preview-image="false"-->
              <!--                                              :after-read="cohabitingPersonCertificateHandHeld"-->
              <!--                                              upload-icon="plus"-->
              <!--                                              :max-count="1"-->
              <!--                                >-->
              <!--                                    <div class="part-inputpart-cardpic-button"></div>-->
              <!--                                </van-uploader>-->
              <!--                                <div class="part-inputpart-cardpic-text">手持{{!isCohabitingPersonCardSelected? cardArr[0].label :cohabitingPersonInfo.card.label}}</div>-->
              <!--                            </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--        合同信息-->
    <div v-if="1 == whichInfo" :class="isFixed ? 'contentpart' : ''">
      <div class="part">
        <span class="part-subtitle">合同信息</span>
        <div class="part-inputpart">
          <div class="part-inputpart-row">
            <span
              class="part-inputpart-row-enablenon part-inputpart-row-redtext part-inputpart-row-header"
              >编号： {{ contractInfo.number }}</span
            >
            <div class="part-inputpart-row-right redsmallLabel">
              {{ contractInfo.type }}
            </div>
          </div>
          <div>
            <span class="part-hyperlinks"
              >房源截止日期{{ contractInfo.cutOffDate }}</span
            >
          </div>
          <div
            id="paymentMethodPanel"
            class="part-inputpart-row"
            @click="clickPaymentMethodShow"
          >
            <span
              :class="
                contractInfo.paymentMethod ? 'content-have' : 'content-none'
              "
            ></span>
            <span class="part-inputpart-row-header">付款方式</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                contractInfo.paymentMethod
                  ? 'part-inputpart-row-normaltext'
                  : ''
              "
              >{{ contractInfo.paymentMethod.dictionaryTitle }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                isPaymentMethodShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
          </div>
          <!--付款方式下拉选-->
          <van-popup v-model="isPaymentMethodShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictionaryTitle"
              :columns="paymentMethodArr"
              @cancel="isPaymentMethodShow = false"
              @confirm="selectPaymentMethodOption"
            />
          </van-popup>
          <!-- 提前付款天数 -->
          <div id="paymentMethodPanel" class="part-inputpart-row" @click="clickAdvancedPaymentDays" v-if="showAdvancedPaymentDaysSelect">
                        <span :class=" advancedPaymentDays ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">提前付款天数</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext" :class="advancedPaymentDays ? 'part-inputpart-row-normaltext':'' ">{{advancedPaymentDays}}</span>
                        <img class="part-inputpart-row-right" :class="advancedPaymentDays ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
          </div>
          <van-popup v-model="isAdvancedPaymentDays" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictionaryValue"
              :columns="advancedPaymentDaysList"
              @cancel="isAdvancedPaymentDays = false"
              @confirm="selectAdvancedPaymentDays"
            />
          </van-popup>
<!--          是否在提前15天-->
          <div class="part-inputpart-row" v-if="advancedPaymentDays=='15'&&contractInfo.paymentMethod.dictionaryValue=='6'&&isInAdvance15Show">
            <div class="content-have"></div>
            <span class="part-inputpart-row-header">是否再提前15天</span>
            <div>
              <span
                  class="part-inputpart-isSupport part-inputpart-row-header"
                  :class="
                  furtherAdvancedDays=='15'
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-graytext'
                "
                  @click="isFurtherAdvancedDays"
              >是</span>
              <span class="content-divide content-divide-position part-inputpart-row-short-graytext">|</span>
              <span
                  class="part-inputpart-woman part-inputpart-row-header"
                  :class="
                  furtherAdvancedDays!='15'
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-graytext'
                "
                  @click="noFurtherAdvancedDays"
              >否</span>
            </div>
          </div>

          <!--租金和押金-->
          <div>
            <div class="part-inputpart-row">
              <div
                :class="contractInfo.rent ? 'content-have' : 'content-none'"
              ></div>
              <span class="part-inputpart-row-header">租金</span>
              <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field
                  class="nopadding"
                  :formatter="formatter"
                  type="digit"
                  v-model="contractInfo.rent"
                  placeholder="租金"
                  readonly
                />
              </div>
              <span
                class="part-inputpart-row-right part-inputpart-row-short-graytext"
                >元</span
              >
            </div>

          <div style="position:relative;clear:both;text-align:right;margin-top:10px">
            <span class="part-hyperfirst"
              >首次出房价：{{firstHousePrice}}元</span
            >
          </div>
            <div class="part-inputpart-row">
              <div
                :class="contractInfo.deposit ? 'content-have' : 'content-none'"
              ></div>
              <span class="part-inputpart-row-header">押金</span>
              <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field
                  class="nopadding"
                  :formatter="formatter"
                  type="text"
                  v-model="contractInfo.deposit"
                  placeholder="押金"
                  readonly
                />
              </div>
              <span
                class="part-inputpart-row-right part-inputpart-row-short-graytext"
                >元</span
              >
            </div>
            <div class="part-inputpart-row" v-if="contractInfo.paymentMethod.dictionaryValue=='8'||contractInfo.paymentMethod.dictionaryValue=='9'">
              <div
                :class="contractInfo.goodsDeposit ? 'content-have' : 'content-none'"
              ></div>
              <span class="part-inputpart-row-header">物品押金</span>
              <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field
                  class="nopadding"
                  :formatter="formatter"
                  type="text"
                  v-model="contractInfo.goodsDeposit"
                  placeholder="物品押金"
                  readonly
                />
              </div>
              <span
                class="part-inputpart-row-right part-inputpart-row-short-graytext"
                >元</span
              >
            </div>
            <div class="part-inputpart-row" v-else>
              <div
                class='content-have'
              ></div>
              <span class="part-inputpart-row-header">物品押金</span>
              <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field
                  class="nopadding"
                  :formatter="formatter"
                  type="text"
                  placeholder="0"
                  readonly
                />
              </div>
              <span
                class="part-inputpart-row-right part-inputpart-row-short-graytext"
                >元</span
              >
            </div>
          </div>

          <div class="part-inputpart-row">
            <span
              :class="
                !contractInfo.startPeriod || !contractInfo.endPeriod
                  ? 'content-none'
                  : 'content-have'
              "
            ></span>
            <span class="part-inputpart-row-header">期限</span>
            <span class="content-divide">|</span>
            <div
              class="part-inputpart-row-graytext"
              :class="
                0 == contractInfo.startPeriod
                  ? ''
                  : 'part-inputpart-row-redtext'
              "
              id="startPeriodPanel"
              @click="clickStartPeriodShow"
            >
              <div class="part-inputpart-row-startPeriod">
                {{ contractInfo.startPeriod }}
              </div>
              <img
                class="part-inputpart-row-left part-inputpart-row-right-timepick"
                style="margin-left: 25px"
                src="../../../assets/images/timepick.png"
              />
            </div>
            <span class="part-inputpart-row-header-other">至</span>
            <div
              class="part-inputpart-row-graytext"
              :class="
                0 == contractInfo.endPeriod ? '' : 'part-inputpart-row-redtext'
              "
              id="endPeriodPanel"

            >
              <div class="part-inputpart-row-endPeriod">
                {{ contractInfo.endPeriod }}
              </div>
              <img
                class="part-inputpart-row-left"
                style=""
                src="../../../assets/images/timepick.png"
              />
            </div>
          </div>
          <!--租期下拉-->
          <div
            id="paymentMethodPanel"
            class="part-inputpart-row"
            @click="clickRenterMethodShow" v-if="isOwnerExpiration==0 || (isChangeLeaseTerm=='true' && isOwnerExpiration==1)"
          >
            <span
              :class="
                contractInfo.leaseTerm ? 'content-have' : 'content-none'
              "
            ></span>
            <span class="part-inputpart-row-header">租期</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                contractInfo.leaseTerm
                  ? 'part-inputpart-row-normaltext'
                  : ''
              "
              >{{ contractInfo.leaseTerm }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                isRenterMethodShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
          </div>

          <!--@click="clickEndPeriodShow"-->
          <div class="part-inputpart-row" v-else>
            <span
              :class="!contractInfo.leaseTerm ? 'content-none' : 'content-have'"
            ></span>
            <span class="part-inputpart-row-header">租期</span>
            <span class="content-divide">|</span>
            <div class="nopadding">
              <van-field
                class="nopadding"
                :formatter="formatter"
                type="digit"
                v-model="contractInfo.leaseTerm"
                placeholder="租期"
                @input="computeEndTime"
                :disabled="editTenancyTerm||roomChangeDisable"
              />
            </div>
            <span
              class="part-inputpart-row-right part-inputpart-row-short-graytext"
              >月</span
            >
          </div>
          <!--租期下拉选项-->
          <van-popup v-model="isRenterMethodShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictionaryValue"
              :columns="renterList"
              @cancel="isRenterMethodShow = false"
              @confirm="selectRenterMethodOption"
            />
          </van-popup>
          <!--结束日期组件-->
          <van-popup
            v-model="isEndPeriodShow"
            position="bottom"
            class="selectTime"
          >
            <div class="date-dropDownList">
              <van-datetime-picker
                type="date"
                v-model="currentDate"
                :min-date="minDate"
                title="选择年月日"
                item-height="60px"
                @confirm="endContractTimeConfirm"
                @cancel="cancel"
              />
            </div>
          </van-popup>
          <!--起始日期组件-->
          <van-popup
            v-model="isStartPeriodShow"
            position="bottom"
            class="selectTime"
          >
            <div class="date-dropDownList">
              <van-datetime-picker
                type="date"
                :min-date="minDate"
                title="选择年月日"
                v-model="currentDate"
                item-height="60px"
                @confirm="startContractTimeConfirm"
                @cancel="cancel"
              />
            </div>
          </van-popup>

          <!--<div
            id="checkInDatePanel"
            class="part-inputpart-row"
            @click="clickCheckInDateShow"
          >
            <span
              :class="
                !contractInfo.checkInDate ? 'content-none' : 'content-have'
              "
            ></span>
            <span class="part-inputpart-row-header">入住日期</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                0 == contractInfo.checkInDate
                  ? ''
                  : 'part-inputpart-row-redtext'
              "
              >{{ contractInfo.checkInDate }}</span
            >
            <img
              class="part-inputpart-row-right part-inputpart-row-right-timepick"
              src="../../../assets/images/timepick.png"
            />
          </div>-->
          <!--入住日期组件-->
          <van-popup
            v-model="isCheckInDateShow"
            position="bottom"
            class="selectTime"
          >
            <div class="date-dropDownList">
              <van-datetime-picker
                type="date"
                :min-date="minDate"
                v-model="currentDate"
                title="选择年月日"
                item-height="60px"
                @confirm="checkInDateConfirm"
                @cancel="cancel"
              />
            </div>
          </van-popup>
          <!--                    <van-calendar v-model="isCheckInDateShow" @confirm="checkInDateConfirm" :min-date="minDate" :max-date="maxDate" :show-confirm="false"/>-->

          <div class="part-inputpart-row">
            <div class="content-have"></div>
            <span class="part-inputpart-row-header">是否有配套服务</span>
            <div>
              <span
                class="part-inputpart-isSupport part-inputpart-row-header"
                :class="
                  contractInfo.supportingService
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-graytext'
                "
                @click="isSupportingService"
                >是</span
              >
              <span
                class="content-divide content-divide-position part-inputpart-row-short-graytext"
                >|</span
              >
              <span
                class="part-inputpart-woman part-inputpart-row-header"
                :class="
                  !contractInfo.supportingService
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-graytext'
                "
                @click="noSupportingService"
                >否</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="other-part">
        <span class="part-subtitle">其他费用</span>
        <div
          v-for="(other, index) in contractInfo.otherExpensesList"
          :key="index"
        >
          <div v-if="decision=='false'" @click="deleteOtherExpenses(index)" class="deleteButton"></div>
          <div class="otherExpenses-inputpart">
            <div class="otherExpenses-inputpart-row">
              <span
                @click="changeIncome(other)"
                class="otherExpenses-row-enablenon part-inputpart-row-header"
                :class="
                  other.billCollectionPaymentType == '收'
                    ? 'part-inputpart-row-bluetext'
                    : 'part-inputpart-other-redtext'
                "
                >{{ other.billCollectionPaymentType }}</span
              >
              <span class="content-divide">|</span>
              <div
                :id="'type' + index + 'Panel'"
                @click="clickTypeShow(other, index)"
                class="part-inputpart-row-graytext"
                :class="other.costtype ? 'part-inputpart-row-normaltext' : ''"
              >
                <span>{{
                  !other.costtype
                    ? '请选择费用类型'
                    : getItemTitle(otherExpensesTypeList, other.costtype)
                }}</span>
                <img
                  class="part-inputpart-row-right"
                  :class="
                    other.isTypeShow
                      ? 'part-inputpart-row-right-upArrow'
                      : 'part-inputpart-row-right-downArrow'
                  "
                  src="../../../assets/images/triangle.png"
                />
              </div>
            </div>
            <div
              :id="'paymentMethod' + index + 'Panel'"
              class="otherExpenses-inputpart-row"
              @click="clickOtherPaymentMethodShow(other, index)"
            >
              <span
                class="otherExpenses-row-enablenon part-inputpart-row-graytext"
                :class="other.payTypeId ? 'part-inputpart-row-normaltext' : ''"
                >{{
                  !other.payTypeId
                    ? '请选择支付方式'
                    : getItemTitle(otherExpensesPayTypeList, other.payTypeId)
                }}</span
              >
              <img
                class="part-inputpart-row-right"
                :class="
                  other.isPaymentMethodShow
                    ? 'part-inputpart-row-right-upArrow'
                    : 'part-inputpart-row-right-downArrow'
                "
                src="../../../assets/images/triangle.png"
              />
            </div>
            <div class="otherExpenses-inputpart-row">
              <span class="otherExpenses-row-enablenon otherExpenses-price"
                >￥</span
              >
              <input
                type="number"
                v-model="other.totalamount"
                placeholder="请填写"
                min="0"
                @input="formateMoney(other.totalamount, index)"
                :disabled="editOtherFees"
              />
              <div
                class="content-margedivide"
                v-if="
                  other.payTypeId == 0 ||
                    other.payTypeId == 1 ||
                    other.payTypeId == 2 ||
                    other.payTypeId == 3
                "
              ></div>
              <span
              :disabled="editOtherFees"
                v-if="
                  other.payTypeId == 0 ||
                    other.payTypeId == 1 ||
                    other.payTypeId == 2 ||
                    other.payTypeId == 3
                "
                :id="'paymentInterval' + index + 'Panel'"
                class="part-inputpart-row-header"
                @click="clickPaymentIntervalShow(other, index)"
                >元{{ getItemTitle(payUnitTypeList, other.payUnitType) }}</span
              >
              <img
                v-if="
                  other.payTypeId == 0 ||
                    other.payTypeId == 1 ||
                    other.payTypeId == 2 ||
                    other.payTypeId == 3
                "
                class="part-inputpart-row-right"
                :class="
                  other.isPaymentIntervalShow
                    ? 'part-inputpart-row-right-upArrow'
                    : 'part-inputpart-row-right-downArrow'
                "
                src="../../../assets/images/triangle.png"
              />
              <div
                class="content-margedivide"
                v-if="other.payTypeId == 4 || other.payTypeId == 5"
              ></div>
              <span
              :disabled="editOtherFees"
                v-if="other.payTypeId == 4 || other.payTypeId == 5"
                :id="'paymentInterval' + index + 'Panel'"
                class="part-inputpart-row-header"
                @click="clickOtherPaymentIntervalShow(other, index)"
                >元{{ getItemTitle(payOtherTypeList, other.payUnitType) }}</span
              >
              <img
                v-if="other.payTypeId == 4 || other.payTypeId == 5"
                class="part-inputpart-row-right"
                :class="
                  other.isOtherPaymentIntervalShow
                    ? 'part-inputpart-row-right-upArrow'
                    : 'part-inputpart-row-right-downArrow'
                "
                src="../../../assets/images/triangle.png"
              />
            </div>
            <div class="otherExpenses-inputpart-row">
              <span
                class="otherExpenses-row-enablenon part-inputpart-row-header"
                >描述</span
              >
              <span class="content-divide">|</span>
              <input v-model="other.describe" placeholder="请输入描述" :disabled="editOtherFees"/>
            </div>
          </div>
        </div>
        <a
          @click="newOtherExpenses"
          v-if='decision=="false"'
          class="otherExpenses-hyperlinks"
          :class="!contractInfo.otherExpensesList ? 'other-part-add' : ''"
          >+ 新增条目</a
        >
      </div>

      <!--其他费用里的费用类型下拉选-->
      <van-popup v-model="isTypeShow" position="bottom">
        <van-picker
          show-toolbar
          value-key="label"
          :columns="otherExpensesTypeList"
          @cancel="isTypeShow = false"
          @confirm="selectTypeOption"
        />
      </van-popup>
      <!--其他费用里的费用付款方式下拉选-->
      <van-popup v-model="isPaymentOtherMethodShow" position="bottom">
        <van-picker
          show-toolbar
          value-key="label"
          :columns="otherExpensesPayTypeList"
          @cancel="isPaymentOtherMethodShow = false"
          @confirm="selectOtherPaymentMethodOption"
        />
      </van-popup>
      <!--其他费用里的费用金额收取阶段下拉选-->
      <van-popup v-model="isPaymentIntervalShow" position="bottom">
        <van-picker
          show-toolbar
          :columns="payUnitTypeList"
          value-key="label"
          @cancel="isPaymentIntervalShow = false"
          @confirm="selectPaymentIntervalOption"
        />
      </van-popup>
      <!--其他费用里的费用金额收取阶段下拉选(拥有随房租付和一次性付清)-->
      <van-popup v-model="isOtherPaymentIntervalShow" position="bottom">
        <van-picker
          show-toolbar
          :columns="payOtherTypeList"
          value-key="label"
          @cancel="isOtherPaymentIntervalShow = false"
          @confirm="selectOtherPaymentIntervalOption"
        />
      </van-popup>

      <div class="part">
        <span class="part-subtitle">优惠活动</span>
        <span class="part-subtitle-1">{{
          discountsList.length > 0 ? '' : ' ：暂无优惠活动'
        }}</span>
        <div class="part-inputpart" v-if="discountsList.length > 0">
          <div
            @click="chooseDiscounts(item)"
            class="part-inputpart-row"
            v-for="item in discountsList"
            :key="item.value"
          >
            <div
              :class="
                contractInfo.discounts.indexOf(item.value) != '-1'
                  ? 'selectTag'
                  : 'noSelectTag'
              "
            ></div>
            <span class="part-smalltext">{{ item.label }}</span>
            <span
              v-if="contractInfo.discounts.indexOf(item.value) != '-1'"
              class="content-have selectCirclePosition"
            ></span>
            <span class="selectCircle part-inputpart-row-right"></span>
          </div>
        </div>
        <!--                <van-checkbox-group v-model="contractInfo.discounts" ref="checkboxGroup">-->
        <!--                    <div class="billInfo" v-for=" (item,i) in discountsList " :key="i">-->
        <!--                        <van-checkbox ref="checks" :name="item.bill_id" checked-color="#ffa65c" shape="square" icon-size="15px" @click="billAction(item.money)">-->
        <!--                            <div class="left">-->
        <!--                                &lt;!&ndash; 费用详情 &ndash;&gt;-->
        <!--                                <div class="detail">-->
        <!--                                    <span style="font-weight: 800;">{{item.feeType}}</span>-->
        <!--                                    - -->
        <!--                                    <span style="">{{item.roomAddress}}</span>-->
        <!--                                </div>-->
        <!--                                &lt;!&ndash; 应缴时间 &ndash;&gt;-->
        <!--                                <div class="time">应缴时间：{{item.feeTime}}</div>-->
        <!--                            </div>-->
        <!--                            <div class="right">-->
        <!--                                <div class="money">{{item.money}}元</div>-->
        <!--                                <div class="overdue">{{item.statusLabel}}</div>-->
        <!--                            </div>-->
        <!--                        </van-checkbox>-->
        <!--                    </div>-->

        <!--                </van-checkbox-group>-->

        <!--标签-->
        <div class="part-inputpart-row-line" v-if="contractLabel.length != 0">
          <span class="text-heating">标签</span>
          <span class="content-divide">|</span>
          <div
            class="divid-heating"
            @click="choosePaperContent(item)"
            v-for="item in contractLabel"
          >
            <!--                        <div class="text-icon" :class="{'text-iconActive':item.isChoose}"></div>-->
            <div
              :class="item.isChoose ? 'text-contractActive' : 'text-contract'"
            >
              {{ item.dictionaryTitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="part">
        <span class="part-subtitle">补充说明</span>
        <div class="part-inputpart">
          <div class="part-inputpart-textarea">
            <textarea
              v-model="contractInfo.explanationDescribe"
              placeholder="填写补充说明"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="part">
        <span class="part-subtitle">合同附件</span>
        <div class="part-inputpart">
          <div class="part-inputpart-textarea part-uploadpic-content">
            <!--                        <div class="part-uploadpic-module" v-for="(item,index) in contractInfo.attachmentList" :key="index" >-->
            <!--                            <img class="part-uploadpic-module-pic" :src="item.content">-->
            <!--                            <div class="part-uploadpic-text">附件{{index+1}}</div>-->
            <!--                        </div>-->
            <!--上传图片按钮-->
            <van-uploader
              class="part-uploadpic-van-uploader"
              v-model="contractInfo.attachmentList"
              upload-icon="plus"
              :max-count="10"
              multiple
              :after-read="afterReadTest"
            ></van-uploader>
          </div>
        </div>
      </div>
      <div class="part">
        <span class="part-subtitle">房屋签约人</span>
        <div class="part-inputpart">
          <div
            id="signatoryPanel"
            class="part-inputpart-row"
            @click="clickSignatoryShow"
          >
            <span
              :class="contractInfo.signatory ? 'content-have' : 'content-none'"
            ></span>
            <span class="part-inputpart-row-header">签约人</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                contractInfo.signatory ? 'part-inputpart-row-normaltext' : ''
              "
              >{{ contractInfo.signatory.title }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                isSignatoryShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/youjiantou.png"
            />
          </div>
          <div>
            <!--                        <div class="part-inputpart part-inputpart-dropDownList" v-if="isSignatoryShow">-->
            <!--                            <div class="part-inputpart-dropDownList-option" :class="item==contractInfo.signatory ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="item in signatoryArr" @click="selectSignatoryOption(item)">{{item}}</div>-->
            <!--                        </div>-->
            <!--签约人下拉列表-->
            <!--                        <div class="part-inputpart part-inputpart-dropDownList" style="height:40%"-->
            <!--                             v-if="isSignatoryShow">-->
            <!--                            <treeSelect class="part-inputpart-dropDownList-option" :itemlist="signatoryArr"-->
            <!--                                        placeholder="请选择" nodatatext="没有找到" :show="isSignatoryShow"-->
            <!--                                        @item-click="selectSignatoryOption"></treeSelect>-->
            <!--                        </div>-->
          </div>
          <!--签约人部门-->
          <div class="part-inputpart-row">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header"
              >签约人部门</span
            >
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-normaltext">{{
              contractInfo.signDepartment.label
            }}</span>
          </div>
          <div
            id="partnerPanel"
            class="part-inputpart-row"
            @click="clickPartnerShow"
          >
            <!--                        <span :class=" isPartnerSelected ? 'content-have' : 'content-none' "></span>-->
            <span class="part-inputpart-row-enablenon part-inputpart-row-header"
              >合作人</span
            >
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                contractInfo.partner ? 'part-inputpart-row-normaltext' : ''
              "
              >{{ contractInfo.partner.title }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                isPartnerShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/youjiantou.png"
            />
          </div>
          <div>
            <!--                        <div class="part-inputpart part-inputpart-dropDownList" v-if="isPartnerShow">-->
            <!--                            <div class="part-inputpart-dropDownList-option" :class="item==contractInfo.partner ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="item in partnerArr" @click="selectPartnerOption(item)">{{item}}</div>-->
            <!--                        </div>-->
            <div
              class="part-inputpart part-inputpart-dropDownList"
              style="height:40%"
              v-if="isPartnerShow"
            >
              <treeSelect
                class="part-inputpart-dropDownList-option"
                :itemlist="partnerArr"
                placeholder="请选择"
                nodatatext="没有找到"
                :show="isPartnerShow"
                @item-click="selectPartnerOption"
              ></treeSelect>
            </div>
          </div>
          <div class="part-inputpart-row">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header"
              >合作人部门</span
            >
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-normaltext">{{
              contractInfo.partnerDepartment.label
            }}</span>
          </div>
          <div
            id="partnershipProportionPanel"
            class="part-inputpart-row"
            @click="clickPartnershipProportionShow"
            v-if="contractInfo.partner"
          >
            <span
              :class="
                contractInfo.partnershipProportion
                  ? 'content-have'
                  : 'content-none'
              "
            ></span>
            <span class="part-inputpart-row-header">合作占比</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                contractInfo.partnershipProportion
                  ? 'part-inputpart-row-normaltext'
                  : ''
              "
              >{{ contractInfo.partnershipProportion.label }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                isPartnershipProportionShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
          </div>
          <!--合作占比下拉框-->
          <van-popup v-model="isPartnershipProportionShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="partnershipProportionArr"
              value-key="label"
              @cancel="isPartnershipProportionShow = false"
              @confirm="selectPartnershipProportionOption"
            />
          </van-popup>

          <!--推荐人-->
          <div class="part-inputpart-row" @click="clickRecommendShow" v-if="isBD!='1'">
            <span class="part-inputpart-row-enablenon part-inputpart-row-header"
              >推荐人</span
            >
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                contractInfo.recommend ? 'part-inputpart-row-normaltext' : ''
              "
              >{{ contractInfo.recommend.label }}</span
            >
            <!--                        <span @click="clearRecommend"><van-icon name="clear" class="RecommendUser-clear" /></span>-->
            <img
              class="part-inputpart-row-right"
              :class="
                isRecommendShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
          </div>
          <div>
            <van-popup v-model="isRecommendShow" position="bottom">
              <!--搜索栏-->
              <div class="searchDiv">
                <van-search
                  placeholder="请输入"
                  v-model="referrerText"
                  size="large"
                  @input="quaryRecommend()"
                />
              </div>
              <van-picker
                show-toolbar
                value-key="label"
                :columns="recommendArr"
                @cancel="isRecommendShow = false"
                @confirm="selectRecommendOption"
              />
            </van-popup>
          </div>
          <div class="part-inputpart-row" v-if="isBD=='1'">
            <span
              class="part-inputpart-row-enablenon part-inputpart-row-heade"
            ></span>
            <span class="part-inputpart-row-header">BD人</span>
            <span class="content-divide">|</span>
            <div class="nopadding">
              <van-field
                class="nopadding"
                :formatter="formatter"
                v-model="bdName"
                placeholder="BD人"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part-horizontalLine"></div>
    <div
      class="part part-button"
      :class="
        canRenterActive() && canContractActive() ? 'part-button-enabled' : ''
      "
      @click="save"
    >
      {{ saveFlag ? '保存' : '保存中' }}
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  DatetimePicker,
  Uploader,
  Tag,
  Picker,
  Icon,
  Popup,
  Checkbox,
  CheckboxGroup,
  Field,
  Toast,
  Calendar,
  search,
  Notify
} from 'vant'
import rxNavBar from '../../../components/rongxun/rx-navBar/navBar'
import treeSelect from '../../../components/treeSelect/treeSelect'
import eventBus from '../../../components/rongxun/rx-navBar/envbus'
import util from '../util2'
import doubleFifteenUtil from  '../doubleFifteenUtil'
import config from '../config'
import {
  queryBaseData,
  base64Upload,
  queryStaffList,
  createContractCode,
  findUserInfoByMobile,
  findDepartmentByStaffId,
  saveOrUpdateRenterContract,
  seeWithUserList,
  getRenterRoomHouseList,
  findRentByRoomId,
  querygOwnerContractEndDate,
  queryRenterFeePolicyDecision,
  renterContractInfo,
  getCouponList,
  queryPotentialUserRequireRoomHouseId,
  findPerContractByRoomId,
  queryRestDepositMoneyBill,
  findAdvanceDay,
  queryRestDepositMoneyBillNew,
  queryRentTenant,
  policyDecisionData,
  queryPolicyDecision,
  queryHouseResourcesOtherExpenses,
  rentHouseNewTenant,
  queryMonth,
  getDepositMoneyType,
  queryContractfalsifydiscount,
  checkRenterContractStatus,
  getCouponNew,
} from '../../../getData/getData'
import {
  responseUtil,
  getStaffId,
  dealImageSingle,
  dealImage,
} from '../../../libs/rongxunUtil'
import {logo} from "@/libs/rxIcon";
import lookForHouse from "../../workbench/lookForHouse";
export default {
  name: 'NewContract',

  components: {
    [NavBar.name]: NavBar,
    [DatetimePicker.name]: DatetimePicker,
    [Uploader.name]: Uploader,
    [Tag.name]: Tag,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Calendar.name]: Calendar,
    [search.name]: search,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    rxNavBar,
    treeSelect,
  },

  data() {
    return {
      perUserFlag: false, // 房源详情进入，如果有预定合同的话，直接显示预订人的信息，切不可再编辑
      TimeProcessing: true,
      buttonInsertOne: true,
      paramType: '', // 用于不同入口进入，房源地址列表的入参不同
      perContractId: '',
      paid: '0', //是否已付定金
      returnSum: '', //换房应退金额
      restDepositMoney: '', //剩余押金，续租时押金自动转入，应收-应支，保存时加上租金
      earnestMoney: '', //如果所签房源时此租客之前的预定，这个即预定合同时的交的定金
      otherFeeType: true, //其他费用中元/日、月、天显示标识
      fromType: 0, //房源详情跳转为1   预订合同跳转为2   潜客详情跳转为3
      resourceStatus: '', //潜客状态
      title: '新增租客合同', //顶部固定兰标题
      roomSearch: '', //房间搜索词
      mobile: '', //前台传来的潜客电话号码
      id: '', //潜在资源id(潜客id)
      loadingFlag: false, //上传图片loading
      saveFlag: true,
      referrerText: '', //推荐人搜索
      flagType: 0, //0代表新增，1代表修改，2,3,4代表租约变更
      entranceType: '',
      contractId: '', //租客合同id
      roomHouseList: [], //房源列表
      roomId: '', //房间源id
      potentialResource_id: '', //潜在资源id
      contractHistory_Id: '', //合同历史id租约变更时用到
      terminateInfoParam: [], //续租、转租、换房时，在退租页面保存的数据
      changeInfoParam: '',
      lastContractCode: '',
      isFixed: false,
      clickSignState: false, //签约人点击状态
      isRoomHouseListShow: false,
      minDate: new Date(),
      //显示 哪个信息
      whichInfo: '0',
      //证件
      isCardShow: false,
      isCardSelected: false,
      isCohabitingPersonCardShow: false,
      isCohabitingPersonCardSelected: false,
      //付款方式
      isPaymentMethodShow: false,
      isPaymentMethodSelected: false,
      //起始日期
      isStartPeriodShow: false,
      //截止日期
      isEndPeriodShow: false,
      //入住日期
      isCheckInDateShow: false,
      currentDate: new Date(),
      //签约人
      isSignatoryShow: false,
      isSignatorySelected: false,
      //合作人
      isPartnerShow: false,
      isPartnerSelected: false,
      //合伙占比
      isPartnershipProportionShow: false,
      isPartnershipProportionSelected: false,
      //其他费用系列
      isTypeShow: false,
      isPaymentOtherMethodShow: false,
      isPaymentIntervalShow: false,
      isOtherPaymentIntervalShow: false,
      isRecommendShow: false, //是否显示推荐人
      isOwnerExpiration:0,  //是否业主截止
      isWhetherKitchen:0,  //是否火房
      chooseIndex: 0, //记录选择的下标
      payMethodId: '', //租客支付方式id
      costtypeList: [], //费用类型
      //房源地址
      houseAddress: '',
      showParam: '',
      newRenterMobile: '',
      newRoomId: '',
      newStartDate: '',
      ownerInfo: {
        //实名认证信息
        ownerNameAuthentication: '1',
        mobileState: false,
        sexState: false,
        certificateState: false,
        nameState: false,
        roomState: false,
        phone: '',
        name: '',
        sex: '',
        card: { value: '0', label: '身份证' },
        cardNumber: '',
        emergencyContactName: '',
        emergencyContactPhone: '',
        emergencyContactAddress: '', //紧急联系人地址
        emergencyContactEmail: '', //紧急联系邮箱
        CertificateFront: require('../../../assets/images/default_img_4.png'),
        CertificateBack: require('../../../assets/images/default_img_5.png'),
        CertificateHandHeld: require('../../../assets/images/default_img_3.png'),
        //是否有同住
        liveTogether: false,
        supplementaryRemark: '', //补充说明
        defaultOtherDays: 0,
        otherDays: 0, //额外时间
      },
      cohabitingPersonInfo: {
        phone: '',
        name: '',
        sex: '',
        card: { value: '', label: '身份证' },
        cardNumber: '',
        CertificateFront: require('../../../assets/images/default_img_4.png'),
        CertificateBack: require('../../../assets/images/default_img_5.png'),
        CertificateHandHeld: '',
      },
      contractInfo: {
        //合同编号
        number: '',
        //合同类型
        type: '新签',
        cutOffDate: '', //业主合同截止日期
        //付款方式
        paymentMethod: '',
        //租金
        rent: '',
        //押金
        deposit: '',
        depositMoneyNum: 1, //押金月数  默认压一个月 如果支付方式有变化，随之改变
        contractHistoryHistoryType: '新签', //签约类型
        houseType: '', //房屋出租类型
        // 租期
        leaseTerm: '',
        // 开始期限
        startPeriod: this.formatDays(new Date()),
        // 结束期限
        endPeriod: '',
        // 入住日期
        checkInDate: this.formatDays(new Date()),
        //标签列表
        labelList: [],
        //是否有配套服务
        supportingService: false,
        //其他费用
        otherExpensesList: [],
        //优惠活动
        discounts: [],
        //临时优惠活动数组，用于存放优惠券类别
        tempDiscounts: [],
        //补充说明
        explanationDescribe: '',
        //附件
        attachmentList: [],
        appendicesContract: '', //合同附件
        //签约人
        signatory: {},
        //合作人
        partner: '',
        // 签约人部门
        signDepartment: {
          label: '',
        },
        // 合作人部门
        partnerDepartment: {
          label: '',
        },
        //合伙占比
        partnershipProportion: '',
        recommend: {
          departmentName: '',
          department_id: '',
          id: '',
          mobile: '',
          path: '',
          userName: '',
          label: '',
        }, //推荐人信息
        onsale:true,
        oldLeadYoung:true,
        quarterlyDiscount:'',   //季付优惠额度
        couponType:''
      },
      cardArr: [],
      paymentMethodArr: [],
      signatoryArr: [],
      partnerArr: [],
      partnershipProportionArr: [],
      recommendArr: [], //推荐人
      labelList: [
        { name: 'a', isChoose: false },
        { name: 'b', isChoose: false },
        { name: 'c', isChoose: false },
        { name: 'd', isChoose: false },
        { name: 'e', isChoose: false },
        { name: 'f', isChoose: false },
        { name: 'g', isChoose: false },
        { name: 'g', isChoose: false },
        { name: 'i', isChoose: false },
        { name: 'j', isChoose: false },
        { name: 'k', isChoose: false },
        { name: 'l', isChoose: false },
        { name: 'm', isChoose: false },
        { name: 'n', isChoose: false },
      ],
      //优惠券列表
      discountsList: [],
      otherExpensesTypeList: [],
      otherExpensesPayTypeList: [], //其他费用里的支付类型
      otherExpensesPaymentIntervalList: [],
      payUnitTypeList: [
        //收费单位类型
        {
          label: '/天',
          value: config.PAY_UNIT_DAY,
        },
        {
          label: '/月',
          value: config.PAY_UNIT_MONTH,
        },
        {
          label: '/年',
          value: config.PAY_UNIT_YEAR,
        },
      ],
      payOtherTypeList: [
        {
          label: '（一次性）',
          value: config.PAY_UNIT_All,
        },
        {
          label: '/天',
          value: config.PAY_UNIT_DAY,
        },
        {
          label: '/月',
          value: config.PAY_UNIT_MONTH,
        },
      ],
      contractLabel: [], //合同类型  分为纸质合同和英文合同
      billCollectionPaymentList: [
        //其它费用收支状态
        {
          label: '收',
          value: '0',
        },
        {
          label: '支',
          value: '1',
        },
      ],
      otherFeesDefaultMap: {
        fund_id: '',
        fundType_id: '',
        fund: '',
        moneyType_id: '',
      },

      roomFeatureList: [], //间房源特色
      roomEqumentMap: [], //间智能设备
      advancedPaymentDaysList:[],  //提前付款天数集合
      isAdvancedPaymentDays:false,        //是否显示付款天数集合弹框
      showAdvancedPaymentDaysSelect:false,  //是否显示提前付款天数
      advancedPaymentDays:'',                  //提前付款天数
      furtherAdvancedDays:'0',                //是否再提前15天
      isInAdvance15Show:false,
      isChangeLeaseTerm:'false',  //业主截止的房子能否改租期
      alertType:'',                          //变更类型，1为续租
      terminateType:'',
      decision:'false',
      isInAdvance15:'false',
      editOtherFees:false,
      editIsInAdvance15:false,
      refund:'',
      editSignPerson:false,                 //签约人是否可编辑
      editTenancyTerm:false,                //编辑租期
      maxMonth:0,
      fromChange:false,               //是否从修改页面进入,为了不调用监听器
      alertPage:{mobile:false,tenancy:false,referrer:false},          //true为修改页面,false不是
      isBD:'',
      bdName:'',
      renterList:[],
      isRenterMethodShow: false,  //租期
      goodsPriceMoney:0,    //已交的物品押金
      roomChangeDisable:false,     //换房是否可编辑
      leaveTime:'',
      changeLeaseInfo:[],
      detailInfo:{},
      firstHousePrice:0,    //首次出房价
      giveHouseTime:'',
      reduceLastRent:'',     //是否减免最后一个月租金
      chooseBreakCard:false,   //是否选择了最后一期租金减免
      changeFlag:false,    //修改进来的，不掉手机号监听器
      priceIncreaseSchemeList:[],     //租期时长决定调价
      newRenewalPlanList:[],  //新续签决策
      offerPrice:0,   //房子价格
      cleanMoney:0,   //保洁费
      // kitchenId:'',
      percentage:'',  //短租提价百分比
      resultOwner:'', //房间截止日期
      oldpercentage:'',
      standardWaterElectricDeposit:{totalamount:0},  //标准是电费押金
      city_id:'',  //当前房源所属城市
      halfPriceRental:0,   //是否使用半价优惠
      sumAll:0,
      detailList:[],
      rejectEdit:false,   //驳回修改，true不可修改
      halfPriceRentalId:'',
      halfPriceButPlus50Id:'',
      eightyPercentPriceId:'',
      halfPriceButPlus50Choose:false,
      isOpenRenewal:'false',
      oldrentMoney:'',
      oldDespositMoney:'',
      lastPreferentialList:[],
      discountsEnableReason:'',//优惠券不生效条件
    }
  },

  created() {
    console.log(this.$route.query)
    this.leaseType = this.$route.query.leaseType
    this.contractCodeOld=this.$route.query.contractCodeOld
    this.changeLeaseInfo=this.$route.query.terminateInfoParam
    //******判断是否是房源详情跳转过来的新签租客合同 begin***************************
    this.paramType = this.$route.query.type // 用于不同入口进入，房源地址列表的入参不同
    this.isBD=this.$route.query.isBD
    this.bdName=this.$route.query.staffName
    if (this.$route.query.type == '1') {
      this.fromType = this.$route.query.type //type 为1 时表示从原生房源详情跳转(首页两个都是)
      this.roomId = this.$route.query.roomId //房源id
      this.houseAddress = this.$route.query.roomDetailedAddress //房源详细地址
      this.flagType = 0 //flagType为0 是新增租客合同
      if (this.roomId != '') {
        let that = this
        let initData = {}
        initData.roomHouseId = that.roomId
        findPerContractByRoomId(initData).then(function(response) {
          responseUtil.dealResponse(that, response, () => {
            let data = response.data.data
            if (data != undefined && data && data != '') {
              if (data.payType == '1') {
                that.paid = '1'
                that.earnestMoney = data.earnestMoney
              }
              that.perContractId = data.id
              that.potentialResource_id = data.potentialResource_id  //所选房子的对应预定租客
              if (data.mobile != undefined && data.mobile != '') {
                that.ownerInfo.phone = data.mobile
                that.ownerInfo.mobileState = true
                that.ownerInfo.sexState = true
                that.ownerInfo.certificateState = true
                that.ownerInfo.nameState = true
                that.perUserFlag = true
              }
            }
          })
        })
      }
      this.show()
      //this.selectRoomChange(this.roomId)
      //******判断是否是房源详情跳转过来的新签租客合同 end*****************************
    } else if (this.$route.query.type == '2') {
      //预定合同入口跳转新签合同
      this.fromType = this.$route.query.type //type 为2 时表示从预定合同跳转
      this.roomId = this.$route.query.roomId //房源id
      this.mobile = this.$route.query.mobile
      this.houseAddress = this.$route.query.houseAddress
      this.id = this.$route.query.prId
      this.paid = this.$route.query.paid
      this.perContractId = this.$route.query.perContractId
      this.contractInfo.signatory.title=this.$route.query.sinName
      this.contractInfo.signatory.id=this.$route.query.signStaff_id
      this.contractInfo.signDepartment.label=this.$route.query.departmentName
      this.contractInfo.signDepartment.id=this.$route.query.signDepartment_id
      if(this.$route.query.partnerStaff_id){
          this.contractInfo.partner={}
          this.contractInfo.partner.id=this.$route.query.partnerStaff_id
          this.contractInfo.partner.title=this.$route.query.parUserName
      }
      this.flagType = 0 //flagType为0 是新增租客合同
      this.show()
      //this.selectRoomChange(this.roomId)
      //预定合同直接签约，根据预定的roomId查询定金，用于
      if (this.$route.query.roomId) {
        this.findPerContractByRoomId()
      }
    } else if (this.$route.query.type == '3') {
      //潜客详情跳转过来的新签合同
      this.fromType = this.$route.query.type //type 为3 时表示从潜客详情跳转
      this.mobile = this.$route.query.mobile
      this.id = this.$route.query.prId
      this.paid = this.$route.query.paid //已经付了定金
      this.perContractId = this.$route.query.perContractId //已经付了定金
      this.flagType = 0 //flagType为0 是新增租客合同
      this.resourceStatus = this.$route.query.resourceStatus //潜客状态
      this.roomId = this.$route.query.roomId //房源id
      //预定合同直接签约，根据预定的roomId查询定金，用于
      if (this.$route.query.roomId) {
        this.findPerContractByRoomId()
      }
      //this.getPolicyDecisionData()
    } else {
      this.id = this.$route.query.prId || '' //潜在资源id
      this.mobile = this.$route.query.mobile || '' //潜客电话
      this.flagType = this.$route.query.flagType || 0
      this.contractId = this.$route.query.contractId || ''
      this.houseAddress = this.$route.query.houseAddress || ''
      this.roomId = this.$route.query.roomId || '' //房间id号
      this.newRenterMobile = this.$route.query.newRenterMobile || '' //新租客电话号码
      this.newRoomId = this.$route.query.newRoomId || '' //换房id号
      this.newStartDate = this.$route.query.newStartDate || '' //转租,换房时合同默认开始时间
      this.changeInfoParam = this.$route.query.changeInfoParam || '' //租约变更页面传来的租约变更信息
      this.refund=this.$route.query.refund
      if (this.$route.query.roomId) {
        this.findPerContractByRoomId()
      }
      if (this.$route.query.contractId && this.flagType == '2') {
        this.queryRestDepositMoneyBill(this.$route.query.contractId)
      }
      this.rejectEdit = false
      if(this.$route.query.contractStatus=='6' && this.$route.query.approvalStatus=='2' && this.$route.query.alertType=='修改'){   //租期中驳回修改不能改这个
        this.rejectEdit = true
      }
      /*if(this.flagType!=6){
        this.editSignPerson=true
      }*/
    }
    //初始化员工信息
    this.initStaffData()

    //推荐人列表初始化
    this.quaryRecommend()

    //初始化房间列表信息
    this.getRenterRoomHouseList()
    if(this.$route.query.alertType){
      this.alertType=this.$route.query.alertType
    }
    if(this.$route.query.terminateType){
      this.terminateType=this.$route.query.terminateType
    }

    if(this.$route.query.roomId){
      this.getAdvancedPaymentDays(this.$route.query.roomId)
    }
    this.getRenterData()//从字典项拿到租期列表
    this.getDepositMoneyType();
  },

  mounted() {
    //事件监听滚动条
    window.addEventListener('scroll', this.watchScroll, true)
  },

  destroyed() {
    window.removeEventListener('scroll', this.watchScroll, true)
  },

  //监听数值变化
  watch: {
    roomId: function(newVal) {
      if(this.paramType=='2'){
        return
      }else{
        if (newVal != '' && newVal != undefined) {
          this.findPerContractByRoomId()
        }
      }

    },
    'contractInfo.recommend.id': function(newVal) {
      this.preferentialContractChange()
    },
    'ownerInfo.phone': function(newVal, oldVal) {
      if(this.changeFlag){
        this.changeFlag=false
      }else{
        this.findUserInfoByMobile(true, newVal)
      }
    },
    'contractInfo.rent': function(newVal, oldVal) {
      var reg = /^(\d{0,8})(\.(\d{0,2}))?$/g
      if (!reg.test(newVal)) {
        if (newVal == '') {
          this.contractInfo.rent = ''
          return
        }
        this.contractInfo.rent = oldVal
      } else {
        this.contractInfo.rent = newVal
      }
    },

    'contractInfo.deposit': function(newVal, oldVal) {
      var reg = /^(\d{0,8})(\.(\d{0,2}))?$/g
      if (!reg.test(newVal)) {
        if (newVal == '') {
          this.contractInfo.deposit = ''
          return
        }
        this.contractInfo.deposit = oldVal
      } else {
        this.contractInfo.deposit = newVal
      }
    },
    //监听租金的支付方式,动态的改变押金
    payMethodId: {
      handler: function(val) {
        var me = this
        var contractInfo = me.contractInfo
        // 月付默认两个月押金
        if (val == config.PAY_TYPE_TWO_BY_ONE) {
          contractInfo.depositMoneyNum = 2
          contractInfo.goodsDeposit=0
        } else if(val =='9'){
          contractInfo.depositMoneyNum = 1
          contractInfo.goodsDeposit=contractInfo.depositMoneyNum * contractInfo.rent
        }else {
          contractInfo.depositMoneyNum = 1
        }
        // 押金
        if (contractInfo.rent != '-') {
          contractInfo.deposit =
            contractInfo.depositMoneyNum * contractInfo.rent
        }
      },
    },

    'contractInfo.signatory.id': function(newVal) {
      if (newVal && newVal.value != -1 && newVal != '') {
        let that = this
        // that.findDepartmentByStaffId(newVal);
        let initData = {
          staffId: newVal,
        }
        findDepartmentByStaffId(initData).then(function(response) {
          responseUtil.dealResponse(that, response, () => {
            if (response.data.data != null && !that.rejectEdit) {  //有值且不是驳回修改
              // that.contractInfo.signDepartment.label = ''
              // response.data.data.forEach(item => {
              //     that.contractInfo.signDepartment.label +=
              //         item.departmentName + " ";
              //     that.contractInfo.signDepartment.id = item.id;
              // });
              that.contractInfo.signDepartment.label = ''
              let departmentList = response.data.data
              if (departmentList.length > 2) {
                for (let i = 2; i < departmentList.length; i++) {
                  that.contractInfo.signDepartment.label +=
                    departmentList[i].departmentName + ' '
                  that.contractInfo.signDepartment.id = departmentList[i].id
                }
              } else {
                response.data.data.forEach(item => {
                  that.contractInfo.signDepartment.label +=
                    item.departmentName + ' '
                  that.contractInfo.signDepartment.id = item.id
                })
              }
            }
          })
        })
      }
    },

    'contractInfo.partner': function(newVal) {
      if(this.rejectEdit){
        return;
      }
      let that = this
      if (newVal.id && newVal.value != -1 && newVal.id != '') {
        if (newVal.id == that.contractInfo.signatory.id) {
          responseUtil.alertMsg(that, '合作人和签约人不能是同一个人')
          that.contractInfo.partner = ''
          return
        }
        let initData = {
          staffId: newVal.id,
        }
        findDepartmentByStaffId(initData).then(function(response) {
          responseUtil.dealResponse(that, response, () => {
            if (response.data.data != null) {
              // response.data.data.forEach(item => {
              //     that.contractInfo.partnerDepartment.label +=
              //         item.departmentName + " ";
              //     that.contractInfo.partnerDepartment.id = item.id;
              // });
              that.contractInfo.partnerDepartment.label = ''
              let departmentList = response.data.data
              if (departmentList.length > 2) {
                for (let i = 2; i < departmentList.length; i++) {
                  that.contractInfo.partnerDepartment.label +=
                    departmentList[i].departmentName + ' '
                  that.contractInfo.partnerDepartment.id = departmentList[i].id
                }
              } else {
                response.data.data.forEach(item => {
                  that.contractInfo.partnerDepartment.label +=
                    item.departmentName + ' '
                  that.contractInfo.partnerDepartment.id = item.id
                })
              }
            }
          })
        })
        // that.findDepartmentByStaffId(newVal.id)
      } else {
        that.contractInfo.partnerDepartment.label = ''
        that.isPartnerSelected = false
      }
    },
  },
        beforeDestroy() {
          eventBus.$off('referrer')
        } ,
  activated() {
    eventBus.$on(
      'referrer',
      function(data) {
        if (data.signPersonInfo) {
          //签约人
          this.contractInfo.signatory = data.signPersonInfo
        }
        if (data.cooperationPersonInfo) {
          //合作人
          this.contractInfo.partner = data.cooperationPersonInfo
          if (data.cooperationPersonInfo.id == undefined) {
            this.contractInfo.partner = ''
            this.contractInfo.partnershipProportion = ''
          }
        }
        if (data.userListInfo&&data.userListInfo.length != 0) {
          //承租人电话
          this.ownerInfo.phone = data.userListInfo.mobile
          if(this.alertPage.mobile){
            this.alertPage.mobile=false
          }else{
            this.enjoyOldTakeNew()
          }
        }
        if(data.userListInfo){
          if(data.userListInfo.isBD){
             this.isBD='1'
             this.bdName=data.userListInfo.BDName
          }
        }
      }.bind(this)
    )
    eventBus.$on(
      'selectContractRoomList',
      function(data) {
        if (data.roomInfo) {
          //签约人
          this.roomId = data.roomInfo.id
          this.houseAddress = data.roomInfo.roomDetailedAddress
          this.findPerContractByRoomId()
          this.selectRoomChange(this.roomId)
          this.getAdvancedPaymentDays(this.roomId)
          // this.findPerContractByRoomId();
          //this.TimeProcessing=true
          //this.preferentialContractChange()
        }
      }.bind(this)
    )
  },

  //如果前往的页面不是推荐人，则摧毁缓存
  beforeRouteLeave: function(to, from, next) {
    if (from && to.name != 'referrer' && to.name != 'selectContractRoomList') {
      //此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
      if (this.$vnode && this.$vnode.data.keepAlive) {
        if (
          this.$vnode.parent &&
          this.$vnode.parent.componentInstance &&
          this.$vnode.parent.componentInstance.cache
        ) {
          if (this.$vnode.componentOptions) {
            var key =
              this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid +
                  (this.$vnode.componentOptions.tag
                    ? `::${this.$vnode.componentOptions.tag}`
                    : '')
                : this.$vnode.key
            var cache = this.$vnode.parent.componentInstance.cache
            var keys = this.$vnode.parent.componentInstance.keys
            if (cache[key]) {
              if (keys.length) {
                var index = keys.indexOf(key)
                if (index > -1) {
                  keys.splice(index, 1)
                }
              }
              delete cache[key]
            }
          }
        }
      }
      this.$destroy()
    }
    next()
  },

  methods: {

    getDepositMoneyType( ){
      var me = this
      let initData = {}
      getDepositMoneyType(initData).then(function (response) {
        responseUtil.dealResponse(me, response, () => {
          var data = response.data.data.data;
          me.paymentMethodArr = data
        })
      })
    },
    //计算合同结束时间
    computeEndTime(e){
      if(this.fromChange){
          this.fromChange=false
          return
      }
      if(e==''||e==0){
          this.contractInfo.endPeriod=''
          return
      }

      var resultToday=util.dateAddMonth(new Date(this.contractInfo.startPeriod),e)   // 根据租期和租期开始时间 算出来的租期结束时间没-1天的
      resultToday=util.dateAddDay(resultToday,-1)   //结束时间
      var resultOwner=util.dateAddDay(new Date(this.contractInfo.cutOffDate),0)   //业主截止时间
      if(Number(this.contractInfo.leaseTerm)<12){
        this.maxMonth=0
      }
      if(resultOwner.getTime()<=resultToday.getTime()){   //业主截止时间<=正常的结束时间
        this.editTenancyTerm=true   //输入的租期不可改
        this.contractInfo.endPeriod=this.contractInfo.cutOffDate    //实际合同结束时间=业主截止时间
        this.maxMonth=0
        this.computeTenancyTerm()   //计算租期
      }else{
        this.editTenancyTerm=false
        var result=util.dateAddMonth(new Date(this.contractInfo.startPeriod),e)
        this.contractInfo.endPeriod=util.dateFormat(util.dateAddDay(result,-1))   //不可改租期，房子是业主截止房子
        if(this.isChangeLeaseTerm=='false' && util.dateAddDay(util.dateAddMonth(new Date(this.contractInfo.startPeriod),12),-1)>resultOwner){
          this.contractInfo.endPeriod=this.contractInfo.cutOffDate
          this.editTenancyTerm=true
        }
      }
      if(this.maxMonth==e){
        this.contractInfo.endPeriod=this.contractInfo.cutOffDate
      }
      if(Number(this.contractInfo.leaseTerm)>12){
        this.editTenancyTerm=false
      }
      this.TimeProcessing=true
      this.preferentialContractChange()   //优惠活动改变
      if(this.alertPage.tenancy){
        this.alertPage.tenancy=false
      }else{
        this.enjoyOldTakeNew()
      }
      this.countNewRent('change')   //计算租金
    },
         countNewRent(type){
          if(type=='change' && this.halfPriceButPlus50Choose && this.contractInfo.leaseTerm=='12'){  //切回12个月
            return
          }
          if(this.isOpenRenewal==='true'&&'2'==this.flagType){  //续签新决策---续租
            this.newRenewalPlan()
            return
          }
            let status=false
            for(let i in this.priceIncreaseSchemeList){
                if(this.contractInfo.leaseTerm>=Number(this.priceIncreaseSchemeList[i].lease_termq)&&this.contractInfo.leaseTerm<=Number(this.priceIncreaseSchemeList[i].lease_termh)){
                    status=true
                    let realPrice = this.priceIncreaseSchemeList[i].priceBenchmark=='2'?this.firstHousePrice:this.offerPrice
                    // let realPrice=Number(this.priceIncreaseSchemeList[i].raise_price)>100?this.firstHousePrice:this.offerPrice
                    this.contractInfo.rent=Math.ceil(realPrice*(Number(this.priceIncreaseSchemeList[i].raise_price)/100))
                    this.percentage=this.priceIncreaseSchemeList[i].raise_price

                  if(this.resultOwner && this.resultOwner!=''){
                    if(util.dateAddDay(new Date(this.contractInfo.endPeriod),0).getTime()==util.dateAddDay(new Date(this.resultOwner),0).getTime()){   //业主到期
                      this.contractInfo.rent=(this.offerPrice).toFixed(2)//this.$route.query.rentMoney?this.$route.query.rentMoney:(this.offerPrice).toFixed(2)
                      this.percentage=100
                    }
                  }else{
                    if(util.dateAddDay(new Date(this.contractInfo.endPeriod),0).getTime()==util.dateAddDay(new Date(this.contractInfo.cutOffDate),0).getTime()){   //业主到期
                      this.contractInfo.rent=(this.offerPrice).toFixed(2)//this.$route.query.rentMoney?this.$route.query.rentMoney:(this.offerPrice).toFixed(2)
                      this.percentage=100
                    }
                  }

                    // // //是业主到期
                    // if(new Date(this.contractInfo.cutOffDate).getTime()==this.contractInfo.endPeriod.getTime()){
                        
                    // }else{          //不是业主到期，但是旧合同租期是12个月，不涨幅
                    //     if('2'==this.flagType && this.detailInfo.rentTimeLong==12){
                    //         this.percentage=100
                    //     }
                    // }
                    this.contractInfo.deposit=this.contractInfo.rent
                    if(this.contractInfo.goodsDeposit){
                        this.contractInfo.goodsDeposit=this.contractInfo.deposit
                    }
                    break
                }
            }
            if(!status){
                this.contractInfo.rent=(this.offerPrice)
                    // .toFixed(2)
                this.percentage=100
                this.contractInfo.deposit=this.contractInfo.rent
                if(this.contractInfo.goodsDeposit){
                    this.contractInfo.goodsDeposit=this.contractInfo.deposit
                }
            }
            if('3'==this.flagType){  //转租
                this.contractInfo.rent=Number(this.$route.query.rentMoney?this.$route.query.rentMoney:this.contractInfo.rent)
                this.percentage = Number(this.oldpercentage?this.oldpercentage:this.percentage)
                this.contractInfo.deposit=this.contractInfo.rent
                return
            }else if('4'==this.flagType&&'0'==this.changeLeaseInfo[0].hasResponsibility){    //换房
                /*this.contractInfo.rent=Number(this.offerPrice)
                if(util.dateAddDay(new Date(this.contractInfo.endPeriod),0).getTime()<=util.dateAddDay(new Date(this.resultOwner),0).getTime()){
                    this.contractInfo.rent=(this.firstHousePrice*Number(this.oldpercentage?this.oldpercentage:100)/100).toFixed(2)
                }*/
                if(this.oldpercentage=='100'){
                    this.contractInfo.rent=Number(this.offerPrice)
                    this.percentage=100
                }else{
                    this.contractInfo.rent=(this.firstHousePrice*Number(this.oldpercentage)/100).toFixed(2)
                    this.percentage = this.oldpercentage
                }
                this.contractInfo.deposit=this.contractInfo.rent
                this.contractInfo.endPeriod=this.$route.query.roomChangeEndTime
                if(util.dateAddDay(new Date(this.resultOwner),0).getTime()<=util.dateAddDay(new Date(this.$route.query.roomChangeEndTime),0).getTime()){
                    this.contractInfo.endPeriod=this.resultOwner
                }
                return
            }else if('2'==this.flagType&&12==this.detailInfo.rentTimeLong&&this.isOpenRenewal==='false'){     //续租
                this.contractInfo.rent=Number(this.$route.query.rentMoney)
                this.percentage = this.oldpercentage
                this.contractInfo.deposit=this.contractInfo.rent
                if (this.payMethodId == config.PAY_TYPE_TWO_BY_ONE) {
                  this.contractInfo.depositMoneyNum = 2
                  this.contractInfo.goodsDeposit=0
                } else if(this.payMethodId =='9'){
                  this.contractInfo.depositMoneyNum = 1
                  this.contractInfo.goodsDeposit=this.contractInfo.depositMoneyNum * this.contractInfo.rent
                }
                return
            }else if('2'==this.flagType&&this.detailInfo.rentTimeLong!=12&&this.isOpenRenewal==='false'){     //续租
                if(this.contractInfo.leaseTerm==12){
                    this.contractInfo.rent=Math.floor(this.offerPrice)//Math.floor(Number(this.detailInfo.rentMoney)/Number(this.detailInfo.percentage?this.detailInfo.percentage:100)*100)
                    this.contractInfo.deposit=this.contractInfo.rent
                    // this.percentage = 100
                    // if(!this.oldpercentage){
                    //     this.percentage=100
                    // }
                }else{
                    this.contractInfo.rent=Number(this.$route.query.rentMoney)?Number(this.$route.query.rentMoney):Math.floor(Number(this.firstHousePrice)*Number(this.percentage?this.percentage:100)/100)//Number(this.detailInfo.rentMoney)
                    this.percentage = Number(this.oldpercentage?this.oldpercentage:this.percentage?this.percentage:100)
                    this.contractInfo.deposit=this.contractInfo.rent
                }
                if (this.payMethodId == config.PAY_TYPE_TWO_BY_ONE) {
                  this.contractInfo.depositMoneyNum = 2
                  this.contractInfo.goodsDeposit=0
                } else if(this.payMethodId =='9'){
                  this.contractInfo.depositMoneyNum = 1
                  this.contractInfo.goodsDeposit=this.contractInfo.depositMoneyNum * this.contractInfo.rent
                }
                return
            }else if('1'==this.flagType&&'续签'==this.detailInfo.chhtype&&this.isOpenRenewal==='false'){      //续租修改
                if(12==this.detailInfo.oldrentTimeLong){
                    this.contractInfo.rent=this.detailInfo.rentMoney
                    this.percentage = this.oldpercentage
                    this.contractInfo.deposit=this.contractInfo.rent
                }else if(this.detailInfo.oldrentTimeLong!=12){
                    if(12==this.contractInfo.leaseTerm){
                        this.contractInfo.rent=Math.floor(this.offerPrice)//Math.floor(Number(this.detailInfo.rentMoney)/Number(this.detailInfo.oldpercentage?this.detailInfo.oldpercentage:100)*100)
                        this.percentage = 100
                        this.contractInfo.deposit=this.contractInfo.rent
                        if (this.payMethodId == config.PAY_TYPE_TWO_BY_ONE) {
                            this.contractInfo.depositMoneyNum = 2
                            this.contractInfo.goodsDeposit=0
                        } else if(this.payMethodId =='9'){
                            this.contractInfo.depositMoneyNum = 1
                            this.contractInfo.goodsDeposit=this.contractInfo.depositMoneyNum * this.contractInfo.rent
                        }
                    }else{
                        this.contractInfo.rent=this.detailInfo.rentMoney?this.detailInfo.rentMoney:Math.floor(Number(this.firstHousePrice)*Number(this.percentage?this.percentage:100)/100)//this.detailInfo.rentMoney
                        this.percentage = Number(this.oldpercentage?this.oldpercentage:this.percentage?this.percentage:100)
                        this.contractInfo.deposit=this.contractInfo.rent

                    }
                }
                if (this.payMethodId == config.PAY_TYPE_TWO_BY_ONE) {
                  this.contractInfo.depositMoneyNum = 2
                  this.contractInfo.goodsDeposit=0
                } else if(this.payMethodId =='9'){
                  this.contractInfo.depositMoneyNum = 1
                  this.contractInfo.goodsDeposit=this.contractInfo.depositMoneyNum * this.contractInfo.rent
                }
                return
            }else if('1'==this.flagType&&'转租'==this.detailInfo.chhtype){
                this.contractInfo.rent=Number(this.detailInfo.rentMoney)
                this.percentage = this.oldpercentage
                this.contractInfo.deposit=this.contractInfo.rent

              return
            }else if('1'==this.flagType&&this.detailInfo.hasResponsibility&&'换房'==this.detailInfo.chhtype){    //换房
                /*this.contractInfo.rent=Number(this.offerPrice)
                if(util.dateAddDay(new Date(this.contractInfo.endPeriod),0).getTime()<=util.dateAddDay(new Date(this.resultOwner),0).getTime()){
                    this.contractInfo.rent=(this.firstHousePrice*Number(this.oldpercentage?this.oldpercentage:100)/100).toFixed(2)
                }*/
                if(this.detailInfo.percentage=='100'){
                    this.contractInfo.rent=Number(this.offerPrice)
                    this.percentage = 100
                }else{
                    this.contractInfo.rent=(this.firstHousePrice*Number(this.oldpercentage)/100).toFixed(2)
                    this.percentage = this.oldpercentage
                }
                this.contractInfo.deposit=this.contractInfo.rent
                return
            }
        },
    newRenewalPlan(){
      console.log('newRenewalPlanList')
      let status=false
      let referenceRent = ''
      if(util.dateAddDay(new Date(this.contractInfo.endPeriod),0).getTime()==util.dateAddDay(new Date(this.resultOwner),0).getTime()){   //业主到期
        referenceRent = 12
      }else{
        referenceRent = this.contractInfo.leaseTerm
      }
      // console.log(this.detailInfo.rentTimeLong)
      for(let i in this.newRenewalPlanList) {
        if ((this.detailInfo.rentTimeLong >= Number(this.newRenewalPlanList[i].oldlease_termq)) &&
            (this.detailInfo.rentTimeLong <= Number(this.newRenewalPlanList[i].oldlease_termh)) &&
            (referenceRent>=Number(this.newRenewalPlanList[i].lease_termq)) &&
            (referenceRent<=Number(this.newRenewalPlanList[i].lease_termh))
        ) {
          // this.city_id == this.newRenewalPlanList[i].city_id &&
          // this.contractInfo.houseType == this.newRenewalPlanList[i].houseType &&
          // this.contractInfo.dempart_id == this.newRenewalPlanList[i].dempart_id
          status=true
          let realPrice=0
          if(this.newRenewalPlanList[i].pricebenchmark=='1'){  //报价
            realPrice = this.offerPrice
          }else if(this.newRenewalPlanList[i].pricebenchmark=='2'){  //首次出房价
            realPrice = this.firstHousePrice
          }else if(this.newRenewalPlanList[i].pricebenchmark=='3'){  //老合同价格
            realPrice = Number(this.$route.query.rentMoney)
          }
          this.contractInfo.rent=Math.ceil(realPrice*(Number(this.newRenewalPlanList[i].proportion)/100))
          this.percentage=this.newRenewalPlanList[i].proportion
          this.contractInfo.deposit=this.contractInfo.rent
          if(this.contractInfo.goodsDeposit){
            this.contractInfo.goodsDeposit=this.contractInfo.deposit
          }
          break
        }
      }
      if(!status){
        this.contractInfo.rent=(this.offerPrice)
        // .toFixed(2)
        this.percentage=100
        this.contractInfo.deposit=this.contractInfo.rent
        if(this.contractInfo.goodsDeposit){
          this.contractInfo.goodsDeposit=this.contractInfo.deposit
        }
      }
    },
    //提前付款天数
    getAdvancedPaymentDays(e){
      let that = this;
      let initData = {};
      initData.id= e
      findAdvanceDay(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.advancedPaymentDaysList=  response.data.data.list
        });
      });
    },
    //roomId ,通过roomId获取该房屋是否被预定，涉及到定金以及之后的账单的计算
    findPerContractByRoomId() {
      let that = this
      let initData = {}
      initData.roomHouseId = that.roomId
      findPerContractByRoomId(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          //预定合同租客交的定金，用于账单房租的抵扣
          if(response.data.data.earnestMoney){
            that.earnestMoney = response.data.data.earnestMoney
            that.perContractId = response.data.data.id
          }
          that.potentialResource_id = response.data.data.potentialResource_id
        })
      })
    },

    //返回上一层
    leftReturn() {
      this.$router.go(-1)
    },
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop > 80) {
        this.isFixed = true
      } else {
        this.isFixed = false
      }
    },
    refreshContactEndTime: function() {
      var me = this
      var contractInfo = me.contractInfo
      var month = contractInfo.leaseTerm
      if (contractInfo.startPeriod != '' && month != '') {
        let contractEndTime = new Date(
          me.commonDateFormate(contractInfo.startPeriod)
        )
        contractEndTime.setMonth(contractEndTime.getMonth() + parseInt(month))
        //转换 格式化成 YYYY-MM-DD 用于页面显示
        contractInfo.endPeriod = me.formatDays(contractEndTime)
      }
    },
    //格式化日期方法
    formatDays(timeStr) {
      let time = new Date(timeStr)
      let year = time.getFullYear()
      let months = time.getMonth() + 1
      if (months < 10) months = '0' + months
      let days = time.getDate()
      if (days < 10) days = '0' + days
      return year + '-' + months + '-' + days
    },
    //接口开始 start
    //显示房源列表的方法
    showRoomListToClick() {
      let that = this
      if (!that.ownerInfo.roomState) {
        that.isRoomHouseListShow = true
      }
    },

    goToSelectHouse() {
      if (this.ownerInfo.roomState) return
      let flagType
      if (this.paramType == 3 && this.flagType == 0) {
        //潜客详情进入
        flagType = 5
      } else if (this.paramType == '' && this.flagType == 2) {
        //租约变更续租
        flagType = 2
      } else if (this.paramType == '' && this.flagType == 3) {
        //租约变更转租
        flagType = 3
      } else if (this.paramType == '' && this.flagType == 4) {
        //租约变更换房
        flagType = 4
      } else if (this.paramType == 1 && this.flagType == 0) {
        //房源详情进入
        flagType = 6
      }
      if(this.flagType=='2'){
          return
      }
      this.$router.push({
        name: 'selectContractRoomList',
        query: {
          flagType: flagType,
        },
      })

    },

    //获取房源列表
    getRenterRoomHouseList() {
      let that = this
      let queryData = {}
      queryData.staff_id = getStaffId()
      queryData.searchInfo = that.roomSearch
      getRenterRoomHouseList(queryData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.roomHouseList = response.data.data.data
          that.initData()
          if(that.$route.query.type==1){
            that.houseAddress = that.getHouseAddressByRoomId(that.roomId)
          }
        })
      })
    },

    //根据房间id 获取当前房间地址
    getHouseAddressByRoomId(roomId) {
      let that = this
      let list = that.roomHouseList
      for (let i in list) {
        if (roomId == list[i].id) {
          return list[i].roomDetailedAddress
        }
      }
      return ''
    },

    //根据潜客资源id查询潜客对应的预定合同
    queryPotentialUserRequireRoomHouseId() {
      let that = this
      let queryData = {}
      queryData.potentialResource_id = that.id
      queryData.resourceStatus = that.resourceStatus
      queryPotentialUserRequireRoomHouseId(queryData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let perContract = response.data.data.percontract
          that.roomId = perContract.roomHouse_id
          that.selectRoomChange(that.roomId)
          that.houseAddress = that.getHouseAddressByRoomId(that.roomId)
          //租期
          let leaseTerm = perContract.rentTimeLong
          if (leaseTerm && Number(leaseTerm) > 0) {
            that.contractInfo.leaseTerm = leaseTerm
          }
          //签约人
          let signStaff_id = perContract.signStaff_id
          if (signStaff_id) {
            that.contractInfo.signatory = that.getArrValuesByIndex(
              that.signatoryArr,
              signStaff_id
            )
          }
          //合作人
          let partnerStaff_id = perContract.partnerStaff_id
          if (partnerStaff_id) {
            that.contractInfo.partner = that.getArrValuesByIndex(
              that.partnerArr,
              partnerStaff_id
            )
            //合作占比
            let partnerRatio_id = perContract.partnerRatio_id
            if (partnerRatio_id) {
              that.contractInfo.partnershipProportion = that.getArrValuesByIndex(
                that.partnershipProportionArr,
                partnerRatio_id
              )
            }
          }
        })
      })
    },

    //房间下拉选点击事件
    selectRoomHouseOption(item) {
      this.houseAddress = item.roomDetailedAddress
      this.isRoomHouseListShow = false
      let roomId = item.id
      this.roomId = roomId
      //this.potentialResource_id = item.potentialResource_id
      this.selectRoomChange(roomId)
    },
    //房间选择的方法 Start
    //选择具体房间的方法，其中要判断此房间的类型，是否已经生成业主合同，合同的截止日期是多少，合同的押金，租金是多少等等信息
    selectRoomChange: function(id) {
      var me = this
      if (id&&me.decision=='true') {
        me.getOtherExpenses(id)
      }else{
        me.queryRenterFeePolicyDecision(id)
      }
      if(this.fromChange){
        this.fromChange=false
          return
      }

      var contractInfo = me.contractInfo
      let queryData = {}
      queryData.roomId = id
      queryData.oldRoomId=me.$route.query.roomId
      findRentByRoomId(queryData).then(function(response) {
        responseUtil.dealResponse(me, response, () => {
          if(Number(response.data.data.vacantDays)>=30 && response.data.data.houseType!=2){
            me.isWhetherKitchen = 1
          }else{
            me.isWhetherKitchen = 0
          }
          me.offerPrice=response.data.data.offerPrice
          me.priceIncreaseSchemeList=response.data.data.priceIncreaseSchemeList
          me.newRenewalPlanList=response.data.data.priceAdjustmentPlanList

          me.firstHousePrice=response.data.data.firstHousePrice
          me.giveHouseTime=response.data.data.giveHouseTime
          // me.kitchenId = response.data.data.kitchenId
          me.oldpercentage=response.data.data.oldpercentage
          me.city_id = response.data.data.city_id
          //合同是否有再提前15天
          if(me.payMethodId=='6' && me.advancedPaymentDays=='15'){
            if(me.alertType=='修改'){
              if(me.editIsInAdvance15){   //修改时决策打开
                me.isInAdvance15Show = true   //能看
                if(JSON.stringify(me.detailInfo)!='{}' && me.detailInfo.furtherAdvancedDays){
                  me.furtherAdvancedDays = me.detailInfo.furtherAdvancedDays
                }else {
                  me.furtherAdvancedDays ='0'
                }
              }else {                     //修改时决策关闭
                me.furtherAdvancedDays ='0'
                me.isInAdvance15Show = false  //不能看且+15为0
              }
            }else{   //其他新签情况
              if(me.isInAdvance15=='false'){   //政策不开
                me.furtherAdvancedDays = '0'
                me.isInAdvance15Show = false  //不能看
              }else{
                //政策开了，能看，沈阳的默认是，大连的默认否
                me.isInAdvance15Show = true
                if(response.data.data.city_id=='15'){  //大连
                  me.furtherAdvancedDays = '0'
                }
                if(response.data.data.city_id=='16'){  //沈阳
                  me.furtherAdvancedDays = '15'
                }
              }
            }
          }else{
            me.furtherAdvancedDays = '0'
          }
          contractInfo.rent = response.data.data.offerPrice
          contractInfo.houseType = response.data.data.houseType
          contractInfo.cutOffDate =
            response.data.data.houseEndTime != undefined &&
            response.data.data.defaultAddContractDays != undefined
              ? util.dateFormat(
                  util.dateAddDay(
                    response.data.data.houseEndTime,
                    response.data.data.defaultAddContractDays
                  )
                )
              : ''
          var resultToday=util.dateAddMonth(new Date(contractInfo.startPeriod),12)
          resultToday=util.dateAddDay(resultToday,-1)
          var resultOwner=util.dateAddDay(new Date(contractInfo.cutOffDate),0)
          me.resultOwner=resultOwner
          if(resultOwner.getTime()<=resultToday.getTime() && !(me.isChangeLeaseTerm=='true' && me.flagType == 3)){   //如果截止日期小于开始日期后一年,且不是（为转租且业主截止短租打开）
            me.isOwnerExpiration = 1  //是业主截止的房子
            console.log('前端校验的',me.isOwnerExpiration)
            me.editTenancyTerm=true
            contractInfo.endPeriod=contractInfo.cutOffDate
                if(me.$route.query.roomChangeEndTime&&me.$route.query.conscientiousBreak===false){
                    me.roomChangeDisable=true
                }
          }else if(me.flagType == 3 && resultOwner.getTime()<new Date(me.$route.query.roomChangeEndTime).getTime()){
            console.log('进')
            me.roomChangeDisable=true
            me.editTenancyTerm=true
            contractInfo.endPeriod=contractInfo.cutOffDate
          }else{
            me.editTenancyTerm=false
            var result=util.dateAddMonth(new Date(contractInfo.startPeriod),12)
            contractInfo.endPeriod=util.dateFormat(util.dateAddDay(result,-1))
                if(me.$route.query.roomChangeEndTime&&me.$route.query.conscientiousBreak===false){
                    me.roomChangeDisable=true
                    contractInfo.endPeriod=me.$route.query.roomChangeEndTime
                }
                if(me.$route.query.roomChangeEndTime&&me.$route.query.conscientiousBreak===true&& me.flagType == 3){  //有责转租
                    contractInfo.endPeriod=me.$route.query.roomChangeEndTime
                }
          }
          me.computeTenancyTerm()
          me.countNewRent()
          me.TimeProcessing=true
          me.preferentialContractChange()
          me.leaveTime= contractInfo.endPeriod
          contractInfo.deposit =
          contractInfo.depositMoneyNum * contractInfo.rent
          if(contractInfo.paymentMethod.dictionaryValue=='9'){
            contractInfo.goodsDeposit=contractInfo.deposit
          }
          // 判断房源是合租
          if (
            response.data.data.houseType &&
            response.data.data.houseType == '1'
          ) {
            // 合租默认有配套服务
            contractInfo.supportingService = true
          } else if (
            response.data.data.houseType &&
            response.data.data.houseType == '2'
          ) {
            // 合租默认有配套服务
            contractInfo.supportingService = false
          } else {
            // 合租默认有配套服务
            contractInfo.supportingService = false
          }
          //间房源特色
          if (response.data.data.roomFeatureList.length != 0) {
            me.roomFeatureList = response.data.data.roomFeatureList
          } else {
            me.roomFeatureList = []
          }

          //间智能设备
          if (response.data.data.roomEqumentMap) {
            var a = JSON.stringify(response.data.data.roomEqumentMap[0])
            if (a.length != 2) {
              me.roomEqumentMap = response.data.data.roomEqumentMap
            } else {
              me.roomEqumentMap = []
            }
          }
        })
      })
      //查询房源其他费用，取第一条

      let queryDataSecond = {}
      queryDataSecond.roomId = id
      querygOwnerContractEndDate(queryData).then(function(response) {
        responseUtil.dealResponse(me, response, () => {
          let data = response.data.data.data
          let year=''
          let month=''
          if (data && !me.contractInfo.endPeriod) {
            // contractInfo.cutOffDate = data.contractEndTime != undefined && data.contractEndTime != '' ? util.dateFormat(util.dateAddDay(data.contractEndTime,response.data.data.defaultAddContractDays)) : '';
            //let endTime = new Date(me.commonDateFormate(data.contractEndTime))
            let endTime = new Date(me.commonDateFormate(me.contractInfo.cutOffDate))
            let nowTime = new Date()
            if (endTime.getTime() - nowTime.getTime() > 0) {
               year = endTime.getFullYear() - nowTime.getFullYear()
               month = endTime.getMonth() - nowTime.getMonth()
              if (year > 0) {
                month += 12 * year
              }
              me.contractInfo.leaseTerm = month
            }
            if (Number(me.contractInfo.leaseTerm) > 12) {
              me.contractInfo.leaseTerm = 12+''
            }
            let tempEndPeriod=''

            if(year+month>12){
              tempEndPeriod = util.dateAddMonth(
              new Date(me.contractInfo.startPeriod),
              me.contractInfo.leaseTerm
            )
            }else{
              tempEndPeriod =me.dateAddDays(me.contractInfo.cutOffDate,1)// me.contractInfo.cutOffDate
            }
            me.contractInfo.endPeriod = util.dateFormat(
              util.dateAddDay(tempEndPeriod, -1)
            )
          }
        })
      })

      me.getCouponList(id)
    },
    dateAddDays(dataStr,dayCount) {
    var strdate=dataStr; //日期字符串
    var isdate = new Date(strdate.replace(/-/g,"/"));  //把日期字符串转换成日期格式
    isdate = new Date((isdate/1000+(86400*dayCount))*1000);  //日期加1天
    var pdate = isdate.getFullYear()+"-"+(isdate.getMonth()+1)+"-"+(isdate.getDate());   //把日期格式转换成字符串

    return pdate;
    },
      //房间配置
      getOtherExpenses(id){
          if(!id){
             return
          }
        var me = this

        let initData = {}
        initData.roomhouse_id = id;
        queryHouseResourcesOtherExpenses(initData).then(function (response) {
          responseUtil.dealResponse(me, response, () => {
              var data = response.data.data.data;
              me.contractInfo.otherExpensesList=[]
              for(var i in data){
                  var json={}
                  json.city_id=data[i].city_id
                  json.billCollectionPaymentType=data[i].billCollectionPaymentType==0?'收':'支'
                  json.costtype=data[i].billFeeType_id
                  json.payTypeId=data[i].payType_id+''
                  json.payUnitType=data[i].timeUnit
                  json.totalamount=data[i].amount
                  json.isDownpaymentVal=data[i].isDownpaymentVal
                  json.count=0
                  json.identity='tenant'
                  json.describe=''
                  json.typeFlag='1'
                  json.costtypeName=data[i].billFeeTypeIdVal
                  json.payTypeVal=data[i].payTypeIdVal
                  json.payUnitTypeVal=data[i].timeUnitVal
                  me.contractInfo.otherExpensesList.push(json)

              }
          })
        })
      },
    //根据房源类型，查询默认的其他费用，并赋值第一个，添加初始化用
    queryRenterFeePolicyDecision(id) {
      let me = this
      let formValidate = me.contractInfo
      let queryDataThird = {}
      queryDataThird.staff_id = getStaffId()
      queryDataThird.roomId = id
      queryRenterFeePolicyDecision(queryDataThird).then(function(response) {
        responseUtil.dealResponse(me, response, () => {
          var data = response.data.data.policyDetail
          if (data) {
            me.otherFeesDefaultMap = {
              fund_id: data.fund_id ? parseInt(data.fund_id) : '',
              fundType_id: data.fundType_id,
              fund: data.fund,
              moneyType_id: data.moneyType_id,
            }
            // 没有其他费用
            // if (!formValidate.otherExpensesList || formValidate.otherExpensesList.length == 0) {
            //     //默认插入一条其他费用
            //     me.tempFirstOtherFees(true,
            //             '收',
            //             data.fund_id,
            //             data.fundType_id ? data.fundType_id : data.fundType_id.toString(),
            //             data.moneyType_id,
            //             data.fund ? data.fund : data.fund.toString())
            // }
            me.contractInfo.otherExpensesList = []
            for (var i = 0; i < data.length; i++) {
              me.tempFirstOtherFees(
                true,
                '收',
                parseInt(data[i].fund_id),
                data[i].fundType_id.toString(),
                data[i].moneyType_id,
                data[i].fund //总计 共
              )
            }
            me.buttonInsertOne = false
          } else {
            //整租查不到数据
            // me.otherFeesDefaultMap = {
            //     fund_id:me.getBillFeeTypeByTitle('其他费用').id,//其他费用
            //     fundType_id:config.PAY_TYPE_MONTH.toString(),
            //     fund:'',
            //     moneyType_id:config.PAY_UNIT_MONTH
            // }
            me.otherFeesDefaultMap = {
              fund_id: '',
              fundType_id: '',
              fund: '',
              moneyType_id: '',
            }
            // if (!formValidate.otherFees || formValidate.otherFees.length == 0) {
            //     me.firstOtherFees(true,
            //         '收',
            //         me.getBillFeeTypeByTitle('其他费用').id,//其他费用
            //         config.PAY_TYPE_MONTH.toString(),
            //         config.PAY_UNIT_MONTH,
            //         '')
            // }
            if (
              !formValidate.otherExpensesList ||
              formValidate.otherExpensesList.length == 0
            ) {
              me.buttonInsertOne = true
            } else {
              me.buttonInsertOne = false
            }
          }
        })
      })
    },

    //查询业主合同截止日期
    querygOwnerContractEndDate(roomId) {
      let that = this
      var contractInfo = that.contractInfo
      let queryData = {}
      queryData.roomId = roomId
      findRentByRoomId(queryData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.offerPrice=response.data.data.offerPrice
          that.priceIncreaseSchemeList=response.data.data.priceIncreaseSchemeList
          that.newRenewalPlanList=response.data.data.priceAdjustmentPlanList

          that.firstHousePrice=response.data.data.firstHousePrice
          that.giveHouseTime=response.data.data.giveHouseTime
          that.getCouponList()
          contractInfo.houseType = response.data.data.houseType

          contractInfo.cutOffDate =
            response.data.data.houseEndTime != undefined &&
            response.data.data.defaultAddContractDays != undefined
              ? util.dateFormat(
                  util.dateAddDay(
                    response.data.data.houseEndTime,
                    response.data.data.defaultAddContractDays
                  )
                )
              : ''
          //合同是否有再提前15天
          that.city_id = response.data.data.city_id
          if(that.payMethodId=='6' && that.advancedPaymentDays=='15'){
          if(that.alertType=='修改'){
            if(that.editIsInAdvance15){   //修改时决策打开
              that.isInAdvance15Show = true   //能看
              if(JSON.stringify(that.detailInfo)!='{}' && that.detailInfo.furtherAdvancedDays){
                that.furtherAdvancedDays = that.detailInfo.furtherAdvancedDays
              }else {
                that.furtherAdvancedDays ='0'
              }
            }else {                     //修改时决策关闭
              that.furtherAdvancedDays ='0'
              that.isInAdvance15Show = false  //不能看且+15为0
            }
          }else{   //其他新签情况
            if(that.isInAdvance15=='false'){   //政策不开
              that.furtherAdvancedDays = '0'
              that.isInAdvance15Show = false  //不能看
            }else{
              //政策开了，能看，沈阳的默认是，大连的默认否
              that.isInAdvance15Show = true
              if(response.data.data.city_id=='15'){  //大连
                that.furtherAdvancedDays = '0'
              }
              if(response.data.data.city_id=='16'){  //沈阳
                that.furtherAdvancedDays = '15'
              }
            }
          }
          }else{
            that.furtherAdvancedDays = '0'
          }

        })
      })
    },

    //查询房间对应的优惠卷
    getCouponList(roomId) {
      let that = this
      let quaryData = {}
      quaryData.roomId = roomId
      getCouponList(quaryData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let value = response.data.data.data
          that.ownerInfo.defaultOtherDays = response.data.data.defaultOtherDays
          if (value && value.length > 0) {
            that.discountsList = value
              // that.quarterCard()
              that.reduceFirstMonth()
          }
          for(let i in that.discountsList){
              if('违约优惠券'==that.discountsList[i].label){
                    that.discountsList.splice(i,1)
                    break
              }
          }
        })
      })
    },

    //房间选择的方法 End
    initStaffData() {
      var that = this
      queryStaffList({}).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.signatoryArr = response.data.data.data
          that.partnerArr = util.deepClone(that.signatoryArr)
          that.partnerArr.unshift({
            department_id: '-1',
            id: '',
            staffName: '无',
            title: '无',
            value: -1,
          })
          if (that.flagType != 1&&!that.contractInfo.signatory.id) {
            //4.默认签约人为当前用户
            that.contractInfo.signatory = that.getArrValuesByIndex(
              that.signatoryArr,
              getStaffId()
            )
            //用户不能再进行点击选择签约人了
            //that.clickSignState = true
          }
        })
      })
    },
    //初始化下拉选择的字典项
    initData() {
      let that = this
      let ratio = config.DICTIONARY.PARTNER_RATIO
      let initData = {
        dbName: [
          'billFeeType',
          'billPayType',
          'payType',
          ratio,
          'contractLabel',
        ],
        fdName: [
          'CYCLETYPEMAPOTHER',
          'CERTIFICATETYPEMAP',
          'RENTERCYCLETYPEMAP',
          ,
          'CONTRACTBILLMAP',
        ],
      }
      queryBaseData(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let data = response.data.data
          for (let key in data) {
            let values = data[key]
            for (let i = 0, item; (item = values[i++]); ) {
              item.value = item.id || item.dictionaryValue
              item.label = item.dictionaryTitle
            }
          }
          //费用类型
          that.costtypeList = data.billFeeType
          that.costtypeList.map(item => {
            if (!item.id) {
              item.id = item.dictionaryValue
            }
            item.id = Number(item.id.toString())
          })

          //证件类型
          that.cardArr = data.CERTIFICATETYPEMAP
          //付款方式
          //that.paymentMethodArr = data.RENTERCYCLETYPEMAP
          //其他费用里的付款项目分类
          that.otherExpensesTypeList = data.billFeeType.concat(
            data.CONTRACTBILLMAP
          )
          //其他费用里的付款方式
          that.otherExpensesPayTypeList = data.CYCLETYPEMAPOTHER
          //合同类型(纸质，英文)
          that.contractLabel =
            data.contractLabel != undefined ? data.contractLabel : []
          //合作占比
          that.partnershipProportionArr = data.contractRaito
        })
      })

      //此初始化方法分为三总情况
      //flagType: 0,//0代表新增，1代表修改，2,3,4代表租约变更（2续签，3转租，4换房，6新签
      //fromType: 0,//1代表房源详情，2代表预定合同，3代表潜客
      if (that.flagType == 0) {
        //新增合同
        if (that.fromType == 1) {
          //房源详情跳转的
          that.contractInfo.type = '新签'
          that.createContractCode()
          //设置房间不可点击
          that.ownerInfo.roomState = true
        } else if (that.fromType == 2) {
          //预定合同跳转的
          that.createContractCode()
          that.findUserInfoByMobile(true, that.mobile)
          that.contractInfo.type = '新签'
          //设置房间不可点击
          that.ownerInfo.roomState = false
        } else if (that.fromType == 3) {
          //潜客详情跳转的
          that.contractInfo.type = '新签'
          that.findUserInfoByMobile(true, that.mobile)
          that.createContractCode()
          that.show()
          //判断状态 如果租客为预定状态 需要查到预定的房间 房间列表不可选择
          if (that.resourceStatus == 4) {
            //获取房间id
            that.queryPotentialUserRequireRoomHouseId()
            //设置房间不可点击
            that.ownerInfo.roomState = false
          }
        } else {
          that.createContractCode()
          that.contractInfo.type = '新签'
        }
      } else if (that.flagType == 1) {
        that.title = '修改租客合同'
        that.contractInfo.type = '新签'
        //修改合同
        //查询租客合同信息
        //前台页面传合同id
        let type = 'amend'
        that.findRenterContractInfo(type)
      } else {
        //租约变更
        that.title = '录入合同'
        if (that.flagType == 2) {
          //续签
          that.contractInfo.type = '续签'
          let changeInfoParam = that.changeInfoParam
          changeInfoParam.changeType = 2
          let terminateInfoParam = []
          terminateInfoParam.push(changeInfoParam)
          let renewalData = {}
          renewalData.terminateInfoParam = terminateInfoParam
          that.reNew(renewalData)
        } else if (that.flagType == 3) {
          //转租
          that.contractInfo.type = '转租'
          let changeInfoParam = that.changeInfoParam
          changeInfoParam.changeType = 3
          let terminateInfoParam = []
          terminateInfoParam.push(changeInfoParam)
          let param = {}
          param.roomId = that.roomId
          param.newRenterMobile = that.newRenterMobile
          param.renterMobile = that.newRenterMobile
          param.subletDate_DateType = that.newStartDate
          param.houseAddress = that.houseAddress
          param.terminateInfoParam = terminateInfoParam
          that.show(param)
        } else if (that.flagType == 4) {
          //换房
          that.contractInfo.type = '换房'
          let changeInfoParam = that.changeInfoParam
          changeInfoParam.changeType = 4
          let terminateInfoParam = []
          terminateInfoParam.push(changeInfoParam)
          let param = {}
          param.roomId = that.newRoomId
          param.renterMobile = that.mobile
          param.subletDate_DateType = that.newStartDate
          param.houseAddress = that.houseAddress
          param.terminateInfoParam = terminateInfoParam
          that.show(param)
          let type = 'changeRoom'
          that.findRenterContractInfo(type)
        }else if(that.flagType == 6){
          that.contractInfo.type = '新签'
          let changeInfoParam = that.changeInfoParam
          changeInfoParam.changeType = 6
          let terminateInfoParam = []
          terminateInfoParam.push(changeInfoParam)
          let param = {}
          param.roomId = that.roomId
          param.newRenterMobile = that.newRenterMobile
          param.renterMobile = that.newRenterMobile
          param.subletDate_DateType = that.newStartDate
          param.houseAddress = that.houseAddress
          param.terminateInfoParam = terminateInfoParam
          that.show(param)
          /*let renewalData = {}
          renewalData.terminateInfoParam = terminateInfoParam
          that.reNew(renewalData)*/
        }
      }
      that.loadingFlag=false
    },
    //创建合同编号
    createContractCode() {
      let that = this
      // 创建合同编号
      createContractCode({
        type: '1', //0代表收房对应业主合同，1代表出房对应租客合同
      }).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          //先解决新增合同的情况，后续修改或者租约变更的情况在考虑
          that.contractInfo.number = response.data.data.contractCode
        })
      })
    },
    //选择标签
    choosePaperContent(item) {
      if(this.rejectEdit){
        return
      }
      !item.isChoose
        ? this.$set(item, 'isChoose', true)
        : this.$set(item, 'isChoose', false)
      this.preferentialContractChange()
    },
    //输入框的公共方法，过滤掉空格
    formatter(value) {
      // 过滤空格
      return value.replace(/\s*/g, '')
    },
    //监听租客手机号
    animatePhone() {
      var re = config.MOBILE_REG
      let str = this.ownerInfo.phone
      if (str != '') {
        if (!re.test(str)) {
          responseUtil.alertMsg(this, '租客手机号格式不正确')
          //this.ownerInfo.phone = "";
        }
      }
    },
    // 检验租客名字
    checkOwnerName() {
      //var re = config.NAME_REG;
      let str = this.ownerInfo.name
      if (str != '') {
        if (!str) {
          responseUtil.alertMsg(this, '租客姓名不能为空')
        }
      }
    },
    // 检验紧急联系人名字
    // checkEmergencyName() {
    //     //var re = config.NAME_REG;
    //     let str = this.ownerInfo.emergencyContactName;
    //     if (str == '') {
    //         responseUtil.alertMsg(this,'紧急联系人姓名不能为空')
    //     }
    // },
    // 检验同住人名字
    // checkCoName() {
    //     //var re = config.NAME_REG;
    //     let str = this.cohabitingPersonInfo.name;
    //     if (str == '') {
    //         responseUtil.alertMsg(this,'同住人姓名不能为空')
    //     }
    // },
    //监听邮箱格式
    animateEmail() {
      var re = config.EMAIL_REG
      let str = this.ownerInfo.emergencyContactEmail
      if (str == '') return
      if (!re.test(str)) {
        responseUtil.alertMsg(this, '邮箱格式不正确')
        // this.ownerInfo.email = "";
      }
    },
    //监听租客身份证信息
    animateOwnerIdCard() {
      var re = config.CERTIFICATE_REG
      let str = this.ownerInfo.cardNumber
      if (this.ownerInfo.card.value != '' && this.ownerInfo.card.value == '0') {
        if (str != '' && str != undefined) {
          if (!re.test(str)) {
            responseUtil.alertMsg(this, '租客身份证格式不正确')
            //this.ownerInfo.cardNumber = "";
          } else {
            this.ownerInfo.sex = (str.substr(-2, 1) % 2) + ''
          }
        }
      }

      this.countReduceRent()
    },
    //监听紧急联系人手机号
    animateEmPhone() {
      var re = config.MOBILE_REG
      let str = this.ownerInfo.emergencyContactPhone
      if (str != '') {
        if (!re.test(str)) {
          responseUtil.alertMsg(this, '紧急联系人手机号格式不正确')
          //this.ownerInfo.emergencyContactPhone = "";
        }
      }
    },
    //监听同住人手机号
    animateCoPhone() {
      var re = config.MOBILE_REG
      let str = this.cohabitingPersonInfo.phone
      if (str != '') {
        if (!re.test(str)) {
          responseUtil.alertMsg(this, '同住人手机号格式不正确')
          //this.cohabitingPersonInfo.phone = "";
        }
      }
    },
    //监听同住人身份证信息
    animateCoIdCard() {
      var re = config.CERTIFICATE_REG
      let str = this.cohabitingPersonInfo.cardNumber
      if (this.cohabitingPersonInfo.card.value == '0') {
        if (str != '' && !re.test(str)) {
          responseUtil.alertMsg(this, '同住人身份证格式不正确')
          //this.cohabitingPersonInfo.cardNumber = "";
        }
      }
    },
    //查询用户实名认证,租客需要实名认证
    findUserInfoByMobile: function(isOwner, mobile) {

      let that = this
      let initData = {}
      initData.userMobile = mobile
      findUserInfoByMobile(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let result = response.data.data.data
          if (response.data.data.data == undefined) return
          let phont = response.data.data.data.photo
          let nameAuthentication = 0
          if (result && result.nameAuthentication) {
            nameAuthentication = result.nameAuthentication
          }
          //业主实名认证结果处理
          if (isOwner) {
            that.ownerInfo.ownerNameAuthentication = nameAuthentication
            if (result && result.userName) {
              that.ownerInfo.name = result.userName
              if (result.gender == '男') {
                that.ownerInfo.sex = '1'
              } else if (result.gender == '女') {
                that.ownerInfo.sex = '0'
              }
              that.ownerInfo.sex = result.gender
              if (result.certificateType) {
                that.ownerInfo.card = that.setSelectObj(
                  that.cardArr,
                  result.certificateType
                )
              }
              if (
                that.changeInfoParam.changeType != 2 ||
                that.ownerInfo.cardNumber == undefined ||
                that.ownerInfo.cardNumber == ''
              ) {
                that.ownerInfo.cardNumber = result.certificateCode
              }
              that.ownerInfo.phone = mobile

              // that.animateOwnerIdCard();
            }
            that.countReduceRent()
            if (result.fpth) {
              that.ownerInfo.CertificateFront = result.fpth
            }
            if (result.bpth) {
              that.ownerInfo.CertificateBack = result.bpth
            }
            if (result.hpth) {
              that.ownerInfo.CertificateHandHeld = result.hpth
            }

            if (phont && phont.length > 0) {
              for (let i = 0; i < phont.length; i++) {
                let photodetail = phont[i]
                if (photodetail.contractPhotoType == 0) {
                  that.ownerInfo.CertificateFront = photodetail.path
                } else if (photodetail.contractPhotoType == 1) {
                  that.ownerInfo.CertificateBack = photodetail.path
                } else if (photodetail.contractPhotoType == 2) {
                  that.ownerInfo.CertificateHandHeld = photodetail.path
                }
              }
            }
          }
          that.setOwnerEditState()
        })
      })
    },
    /**
     * 设置租客信息编辑状态
     * 上上签实名认证的用户不能编辑,没有实名认证才可以编辑
     */
    setOwnerEditState: function() {
      let that = this
      if (that.ownerInfo.ownerNameAuthentication == '1') {
        that.setOwnerState(true)
      } else {
        that.setOwnerState(false)
        that.changeCertificateType(that.ownerInfo.card)
      }
    },
    //设置租客信息编辑状态
    setOwnerState: function(owner) {
      let that = this
      if (that.entranceType == 'user') {
        that.ownerInfo.mobileState = true
      } else if (that.entranceType == 'reserve') {
        that.ownerInfo.mobileState = true
        that.ownerInfo.sexState = true
        that.ownerInfo.certificateState = true
        that.ownerInfo.nameState = true
      } else if (that.entranceType == 'house') {
        that.ownerInfo.mobileState = false
        that.ownerInfo.sexState = true
        that.ownerInfo.certificateState = true
        that.ownerInfo.nameState = true
      } else {
        if (!that.perUserFlag) {
          that.ownerInfo.mobileState = owner
          that.ownerInfo.sexState = owner
          that.ownerInfo.certificateState = owner
          that.ownerInfo.nameState = owner
        }
      }
      if(that.flagType==2||that.flagType==4||that.flagType==6){
         that.ownerInfo.mobileState=true
      }
    },
    //选择证件类型
    changeCertificateType: function(val) {
      let that = this
      //选择身份证时不能选择性别,通过身份证号判断性别
      if (val.label == 0) {
        that.ownerInfo.sexState = true
      } else {
        that.ownerInfo.sexState = false
      }
    },
    setSelectObj(arr, value, keyName) {
      let obj = {
        dictionaryName: 'idcard',
        dictionaryTitle: '身份证',
        dictionaryValue: '0',
        value: '0',
        label: '身份证',
      }
      keyName = keyName || 'value'
      arr.forEach(item => {
        if (item[keyName] == value) {
          obj = item
        }
      })
      return obj
    },
    //查询推荐人
    clearRecommend() {
      this.contractInfo.recommend = {}
      this.preferentialContractChange()
    },
    quaryRecommend() {
      let that = this
      let data = {}
      if (!((this.checkNumber(this.referrerText) && this.referrerText.length > 10) || (this.checkChinese(this.referrerText)&&this.referrerText.length>=2))) {
        return
      }
      data.searchName = that.referrerText
      //queryRentTenant  seeWithUserList
      queryRentTenant(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.recommendArr = response.data.data.data
          if (that.recommendArr) {
            that.recommendArr.forEach(item => {
              item.label = item.userName + ' ' + that.getTel(item.mobile)
            })
          }
        })
      })
    },
        //验证字符串是否是数字
        checkNumber(theObj) {
            var reg = /^[0-9]+.?[0-9]*$/;
            if (reg.test(theObj)) {
                return true;
            }
            return false;
        },
        //验证汉字
        checkChinese(theObj){
            var reg = /^[\u4e00-\u9fa5]+$/g;
            if (reg.test(theObj)) {
                return true;
            }
            return false;
        },
    // 手机号隐藏四位
    getTel(tel) {
      var reg = /^(\d{3})\d{4}(\d{4})$/
      return tel.replace(reg, '$1****$2')
    },
    //创建账单
    createBill: function() {
      var me = this
      var contractInfo = me.contractInfo
      // 押一付一6
      // 押二付一9 提前1天
     // 季付1  半年付2  年付3   一次性付清4  提前30天
        if(me.payMethodId=='8'||me.payMethodId=='9'){
            me.advancedPaymentDays=1+''
        }else if(me.payMethodId=='1'||me.payMethodId=='2'||me.payMethodId=='3'||me.payMethodId=='4'){
          me.advancedPaymentDays='30'
        }else if(me.payMethodId!='6'){
          me.advancedPaymentDays=me.advancedPaymentDaysList[0].dictionaryValue
        }
            // if(me.payMethodId!='8'&&me.payMethodId!='6'&&me.payMethodId!='9'&&me.payMethodId!='1'){
            //     me.advancedPaymentDays=me.advancedPaymentDaysList[0].dictionaryValue
            // }
            let flagType=''
            if((me.detailInfo.flagType=='1'&&me.detailInfo.contractHistoryHistoryType=='1')||me.detailInfo.flagType=='2'){
                flagType='2'
            }
      //otherExpensesList
      let otherBillList = []
      let withrentBillList = [] //随房租付
      var withrentBills = []
      let hasAddtionServiceMoney = []
      //去掉额外天数
      me.contractInfo.endPeriod =
        me.contractInfo.otherDays > 0 ? util.dateAddDay(me.contractInfo.endPeriod, -me.contractInfo.otherDays) : me.contractInfo.endPeriod
      me.contractInfo.endPeriod = util.dateFormat(me.contractInfo.endPeriod)
      // 其他费用账单
      for (let i = 0; i < contractInfo.otherExpensesList.length; i++) {
        // that.alertType=='修改'&&param.contractHistoryHistoryType==1
        if(me.leaseType!='续签' && !(me.alertType=='修改' && contractInfo.type=='续签')){
          if('22'==contractInfo.otherExpensesList[i].costtype&&me.cleanMoney>0){
            contractInfo.otherExpensesList[i].billCollectionPaymentType='3'
          }
        }

        if (
          contractInfo.otherExpensesList[i].payTypeId != '' &&
          contractInfo.otherExpensesList[i].totalamount &&
          contractInfo.otherExpensesList[i].payTypeId != '5'
        ) {
          contractInfo.otherExpensesList[i]['renterType'] = me.payMethodId
          contractInfo.otherExpensesList[i]['count'] = me.contractInfo.leaseTerm
          contractInfo.otherExpensesList[i]['identity'] = 'tenant'
          contractInfo.otherExpensesList[i]['typeFlag'] = '1'
          // contractInfo.otherExpensesList[i]["isDownpaymentVal"] = '是'
          contractInfo.otherExpensesList[i]['flagType']=flagType
          otherBillList.push(contractInfo.otherExpensesList[i])
        }
        //随房租付
        let otherPayUnitType = contractInfo.otherExpensesList[i].payUnitType
        // let otherPayUnitType = formValidate.otherFees[i].payUnitType;
        if (
          contractInfo.otherExpensesList[i].payTypeId != '' &&
          contractInfo.otherExpensesList[i].totalamount &&
          contractInfo.otherExpensesList[i].payTypeId == '5'
        ) {
          contractInfo.otherExpensesList[i]['renterType'] = me.payMethodId
          contractInfo.otherExpensesList[i]['count'] = me.contractInfo.leaseTerm
          contractInfo.otherExpensesList[i]['payUnitType2'] = me.payMethodId + ''
          contractInfo.otherExpensesList[i]['identity'] = 'tenant'
          contractInfo.otherExpensesList[i]['typeFlag'] = '1'
          contractInfo.otherExpensesList[i]['otherPayUnitType'] =
            otherPayUnitType + ''
          // contractInfo.otherExpensesList[i]["isDownpaymentVal"] = '是'
          contractInfo.otherExpensesList[i]['flagType']=flagType
          withrentBillList.push(contractInfo.otherExpensesList[i])
        }
      }
      var otherBills = []
      let begin = new Date(me.commonDateFormateOther(contractInfo.startPeriod))
      let end = new Date(me.commonDateFormateOther(contractInfo.endPeriod))
      if (otherBillList.length > 0) {
        otherBills = util.creatBills(
          new Date(),
          begin,
          end,
          otherBillList,
          config.ADVANCE_DAYS,
          me.advancedPaymentDays,
          '',
          '',
          me.payMethodId
        )
      }
      //随房租付
      if (withrentBillList.length > 0) {
        withrentBills = util.creatBills(
          new Date(),
          begin,
          end,
          withrentBillList,
          config.ADVANCE_DAYS,
          me.advancedPaymentDays,
          '',
          me.billTitle,
          me.payMethodId
        )
      }

      var renterMoney = []
      var renterDeposit = []
      var renterDepositEnd = []
      var goodsDeposit=[]
      var goodsDepositEnd=[]
      if(contractInfo.paymentMethod.dictionaryValue!='9'){
          contractInfo.goodsDeposit=0
      }
      // 押金账单
      var cashPledge=0
      if(me.leaseType!='续签' && !(me.alertType=='修改' && contractInfo.type=='续签')){
        cashPledge=Number(me.restDepositMoney)+Number(contractInfo.deposit)+(me.goodsPriceMoney)+Number(contractInfo.goodsDeposit)
      }
      renterDeposit = util.creatBills(
        new Date(),
        begin,
        end,
        [
          {
            billCollectionPaymentType: '收', //收支状态
            costtype: config.COST_TYPE_OF_DEPOSIT,
            costtypeVal: '押金',
            payTypeId: config.PAY_TYPE_ONCE,
            payUnitType: config.PAY_UNIT_MONTH,
            totalamount: contractInfo.deposit,
            count: contractInfo.leaseTerm,
            identity: 'tenant', //owner 业主,tenant 租客
            typeFlag: '3',
            depositType: '1',
            describe: '押金',
            flagType:flagType
          },
        ],
        config.ADVANCE_DAYS,
        me.advancedPaymentDays,
        cashPledge,
        me.alertType
      )
            //物品押金账单
      if(contractInfo.paymentMethod.dictionaryValue=='9'||contractInfo.goodsDeposit){
        goodsDeposit = util.creatBills(
          new Date(),
          begin,
          end,
          [
            {
              billCollectionPaymentType: '收',//收支状态
              costtype: '28',
              costtypeVal: '物品押金',
              payTypeId: config.PAY_TYPE_ONCE,
              payUnitType: config.PAY_UNIT_MONTH,
              totalamount: contractInfo.deposit,
              count: contractInfo.leaseTerm,
              identity: 'tenant',//owner 业主,tenant 租客
              typeFlag: '3',
              depositType: '1',
              describe: '物品押金',
              flagType:flagType
            }
          ],
        config.ADVANCE_DAYS,
        me.advancedPaymentDays,
        cashPledge,
        me.alertType
        );
      }
      // 租金账单
      //此处和后台逻辑不同，付款方式选择一次性付清的话，在这里直接计算总金额 start
      let tempTotalRent = 0
      if (me.payMethodId != '4') {
        tempTotalRent = Number(contractInfo.rent)
      } else if (me.payMethodId == '4') {
        /*if (Number(contractInfo.leaseTerm) > 0) {
          //如果月份大于0
          tempTotalRent =
            Number(contractInfo.rent) * Number(contractInfo.leaseTerm)
        } else {*/
          //如果月份小于零,则传入月租金
          tempTotalRent = Number(contractInfo.rent)
        //}
      }

      let chooseState=false
      for(let i in me.discountsList){
          if('17'==me.discountsList[i]){
              me.chooseBreakCard=true
              chooseState=true
              break
          }
      }

      if(!chooseState){
          me.chooseBreakCard=false
      }
      console.log('进租金')
      //此处和后台逻辑不同，付款方式选择一次性付清的话，在这里直接计算总金额 end
      renterMoney = util.creatBills(
        new Date(),
        begin,
        end,
        [
          {
            billCollectionPaymentType: '收', //收支状态
            costtype: config.COST_TYPE_OF_RENTERRENT, //租金
            costtypeVal: '租金',
            payTypeId: me.payMethodId,
            payUnitType: config.PAY_UNIT_MONTH,
            totalamount: tempTotalRent,
            count: Number(contractInfo.leaseTerm),
            identity: 'tenant', //owner 业主,tenant 租客
            typeFlag: '2',
            depositType: '1',
            describe: '租金',
            isDownpaymentVal:'是',
            flagType:flagType,
            reduceLastRent:me.reduceLastRent,
            chooseBreakCard:me.chooseBreakCard,
            goodsPriceMoney:me.goodsPriceMoney
          },

        ],
        config.ADVANCE_DAYS,
        me.advancedPaymentDays,
        cashPledge,
        me.alertType,
        me.payMethodId,
        contractInfo.couponType,
        contractInfo.quarterlyDiscount
      )
      console.log(renterMoney)
            // 押金账单
            renterDepositEnd = util.creatBills(
        end,
        begin,
        end,
                [
                    {
                        billCollectionPaymentType: '支',//收支状态
                        costtype: config.COST_TYPE_OF_DEPOSIT,
                        costtypeVal: '押金',
                        payTypeId: config.PAY_TYPE_ONCE,
                        payUnitType: config.PAY_UNIT_MONTH,
                        totalamount: contractInfo.deposit,
                        count: contractInfo.leaseTerm,
                        identity: 'tenant',//owner 业主,tenant 租客
                        typeFlag: '3',
                        depositType: '1',
                        describe: '押金'
                    }
                ],
                config.ADVANCE_DAYS,
                me.advancedPaymentDays,
          '',
          '',
          me.payMethodId

            );
          if(contractInfo.paymentMethod.dictionaryValue=='9'||contractInfo.goodsDeposit){
            //物品押金账单
            goodsDepositEnd = util.creatBills(
        end,
        begin,
        end,
                [
                    {
                        billCollectionPaymentType: '支',//收支状态
                        costtype: '28',
                        costtypeVal: '押金',
                        payTypeId: config.PAY_TYPE_ONCE,
                        payUnitType: config.PAY_UNIT_MONTH,
                        totalamount: contractInfo.deposit,
                        count: contractInfo.leaseTerm,
                        identity: 'tenant',//owner 业主,tenant 租客
                        typeFlag: '3',
                        depositType: '1',
                        describe: '物品押金'
                    }
                ],
                config.ADVANCE_DAYS,
                me.advancedDays,
                '',
                '',
                me.payMethodId

            );
          }
      /*//配套服务账单
                if(contractInfo.supportingService){
                    var checkYearLong = contractInfo.leaseTerm%12
                    var leaseTerm = 0;
                    if(checkYearLong > 0){
                        leaseTerm = parseInt(contractInfo.leaseTerm/12) + 1
                    }else {
                        leaseTerm = parseInt(contractInfo.leaseTerm/12)
                    }
                    hasAddtionServiceMoney = [{
                        billCollectionPaymentType: config.WILL_INCOME_CODE,
                        costtype: config.COST_TYPE_OF_SERVICEFEE,
                        costtypeVal: '服务费',
                        payTypeId: config.PAY_TYPE_ONCE,
                        totalamount: Number(365 * leaseTerm),
                        billdescription: '合租服务费',
                        count: contractInfo.leaseTerm,
                        nowCount: '',
                        identity: 'tenant',//owner 业主,tenant 租客
                        collectionDate: util.dateFormat(new Date()),
                        costBeginDate: util.dateFormat(contractInfo.startPeriod),
                        costEndDate: util.dateFormat(contractInfo.endPeriod)
                    }]
                }*/
      let list = renterDeposit.concat(goodsDeposit).concat(otherBills).concat(renterMoney).concat(withrentBills).concat(renterDepositEnd).concat(hasAddtionServiceMoney).concat(goodsDepositEnd)
      for (let i = 0; i < list.length; i++) {
        list[i].billCollectionPaymentTypeVal = util.getPayDirectionLabel(
          list[i].billCollectionPaymentType
        )

        //改回加天数的时间
        if (
          contractInfo.otherDays > 0 &&
          list[i].costEndDate == contractInfo.endPeriod
        ) {
          list[i].costEndDate = util.dateFormat(
            util.dateAddDay(contractInfo.endPeriod, contractInfo.otherDays)
          )
        }
        if (
          contractInfo.otherDays > 0 &&
          list[i].collectionDate == contractInfo.endPeriod
        ) {
          list[i].collectionDate = util.dateFormat(
            util.dateAddDay(contractInfo.endPeriod, contractInfo.otherDays)
          )
        }
        if (list[i].costBeginDate && list[i].costEndDate) {
          list[i].range = list[i].costBeginDate + '至' + list[i].costEndDate
        } else {
          list[i].range = ''
        }

        for (let j = 0; j < this.costtypeList.length; j++) {
          if (this.costtypeList[j].value == list[i].costtype) {
            list[i].costtypeVal = this.costtypeList[j].label
            break
          }
        }
      }

      //加回额外天数
      contractInfo.endPeriod =
        contractInfo.otherDays > 0
          ? util.dateAddDay(contractInfo.endPeriod, contractInfo.otherDays)
          : contractInfo.endPeriod
      //排序
      list.sort(function(a,b){
                //return a.collectionDate > b.collectionDate?1:-1
                // if(a.collectionDate == b.collectionDate){
                //     return me.getSort(a.billdescription)>me.getSort(b.billdescription)?1:-1
                // }else {
              let dateCompare = new Date(a.collectionDate) - new Date(b.collectionDate);
              if (dateCompare !== 0) {
                return dateCompare;
              }
              // 如果日期相同，那么按照索引从小到大排序
        return  new Date(a.costBeginDate).getTime() - new Date(b.costBeginDate).getTime()

        // }

            })

      return list
    },

    //创建修改付款日期的账单
    creatEditPayTimeBills: function() {
      var me = this
      var contractInfo = me.contractInfo
      let flagType=''
      if((me.detailInfo.flagType=='1'&&me.detailInfo.contractHistoryHistoryType=='1')||me.detailInfo.flagType=='2'){
        flagType='2'
      }
      //otherExpensesList
      let otherBillList = []
      let withrentBillList = [] //随房租付
      var withrentBills = []
      //去掉额外天数
      me.contractInfo.endPeriod =
          me.contractInfo.otherDays > 0 ? util.dateAddDay(me.contractInfo.endPeriod, -me.contractInfo.otherDays) : me.contractInfo.endPeriod
      me.contractInfo.endPeriod = util.dateFormat(me.contractInfo.endPeriod)
      // 其他费用账单
      for (let i = 0; i < contractInfo.otherExpensesList.length; i++) {
        if(me.leaseType!='续签' && !(me.alertType=='修改' && contractInfo.type=='续签')) {
          if ('22' == contractInfo.otherExpensesList[i].costtype && me.cleanMoney > 0) {
            contractInfo.otherExpensesList[i].billCollectionPaymentType = '3'
          }
        }
        if (
            contractInfo.otherExpensesList[i].payTypeId != '' &&
            contractInfo.otherExpensesList[i].totalamount &&
            contractInfo.otherExpensesList[i].payTypeId != '5'
        ) {
          contractInfo.otherExpensesList[i]['renterType'] = me.payMethodId
          contractInfo.otherExpensesList[i]['count'] = me.contractInfo.leaseTerm
          contractInfo.otherExpensesList[i]['identity'] = 'tenant'
          contractInfo.otherExpensesList[i]['typeFlag'] = '1'
          // contractInfo.otherExpensesList[i]["isDownpaymentVal"] = '是'
          contractInfo.otherExpensesList[i]['flagType']=flagType
          otherBillList.push(contractInfo.otherExpensesList[i])
        }
        //随房租付
        let otherPayUnitType = contractInfo.otherExpensesList[i].payUnitType
        // let otherPayUnitType = formValidate.otherFees[i].payUnitType;
        if (
            contractInfo.otherExpensesList[i].payTypeId != '' &&
            contractInfo.otherExpensesList[i].totalamount &&
            contractInfo.otherExpensesList[i].payTypeId == '5'
        ) {
          contractInfo.otherExpensesList[i]['renterType'] = me.payMethodId   //支付方式
          contractInfo.otherExpensesList[i]['count'] = me.contractInfo.leaseTerm   //租期
          contractInfo.otherExpensesList[i]['payUnitType2'] = me.payMethodId + ''  //
          contractInfo.otherExpensesList[i]['identity'] = 'tenant'
          contractInfo.otherExpensesList[i]['typeFlag'] = '1'
          contractInfo.otherExpensesList[i]['otherPayUnitType'] = otherPayUnitType + ''
          // contractInfo.otherExpensesList[i]["isDownpaymentVal"] = '是'
          contractInfo.otherExpensesList[i]['flagType']=flagType
          withrentBillList.push(contractInfo.otherExpensesList[i])
        }
      }
      var otherBills = []
      let begin = new Date(me.commonDateFormateOther(contractInfo.startPeriod))
      let end = new Date(me.commonDateFormateOther(contractInfo.endPeriod))
      if (otherBillList.length > 0) {
        otherBills = doubleFifteenUtil.creatEditCycleBills(
            new Date(),
            begin,
            end,
            otherBillList,
            config.ADVANCE_DAYS,
            me.advancedPaymentDays,
            '',
            '',
            me.payMethodId,
            contractInfo.couponType,
            contractInfo.quarterlyDiscount,
            3,
            15,
        )
      }

      //随房租付
      if (withrentBillList.length > 0) {
        withrentBills = doubleFifteenUtil.creatEditCycleBills(
            new Date(),
            begin,
            end,
            withrentBillList,
            config.ADVANCE_DAYS,
            me.advancedPaymentDays,
            '',
            me.billTitle,
            me.payMethodId,
            contractInfo.couponType,
            contractInfo.quarterlyDiscount,
            3,
            15,
        )
      }
      var renterMoney = []
      var renterDeposit = []
      var renterDepositEnd = []
      var goodsDeposit=[]
      var goodsDepositEnd=[]
      if(contractInfo.paymentMethod.dictionaryValue!='9'){
        contractInfo.goodsDeposit=0
      }
      // 押金账单
      var  cashPledge=0
      if(me.leaseType!='续签' && !(me.alertType=='修改' && contractInfo.type=='续签')) {
        cashPledge = Number(me.restDepositMoney) + Number(contractInfo.deposit) + (me.goodsPriceMoney) + Number(contractInfo.goodsDeposit)
      }
      renterDeposit = util.creatBills(
          new Date(),
          begin,
          end,
          [
            {
              billCollectionPaymentType: '收', //收支状态
              costtype: config.COST_TYPE_OF_DEPOSIT,
              costtypeVal: '押金',
              payTypeId: config.PAY_TYPE_ONCE,
              payUnitType: config.PAY_UNIT_MONTH,
              totalamount: contractInfo.deposit,
              count: contractInfo.leaseTerm,
              identity: 'tenant', //owner 业主,tenant 租客
              typeFlag: '3',
              depositType: '1',
              describe: '押金',
              flagType:flagType
            },
          ],
          config.ADVANCE_DAYS,
          me.advancedPaymentDays,
          cashPledge,
          me.alertType
      )
      //物品押金账单
      if(contractInfo.paymentMethod.dictionaryValue=='9'||contractInfo.goodsDeposit){
        goodsDeposit = util.creatBills(
            new Date(),
            begin,
            end,
            [
              {
                billCollectionPaymentType: '收',//收支状态
                costtype: '28',
                costtypeVal: '物品押金',
                payTypeId: config.PAY_TYPE_ONCE,
                payUnitType: config.PAY_UNIT_MONTH,
                totalamount: contractInfo.deposit,
                count: contractInfo.leaseTerm,
                identity: 'tenant',//owner 业主,tenant 租客
                typeFlag: '3',
                depositType: '1',
                describe: '物品押金',
                flagType:flagType
              }
            ],
            config.ADVANCE_DAYS,
            me.advancedPaymentDays,
            cashPledge,
            me.alertType
        );
      }
      // 租金账单
      //此处和后台逻辑不同，付款方式选择一次性付清的话，在这里直接计算总金额 start
      let tempTotalRent = 0
      if (me.payMethodId != '4') {
        tempTotalRent = Number(contractInfo.rent)
      } else if (me.payMethodId == '4') {
        /*if (Number(contractInfo.leaseTerm) > 0) {
          //如果月份大于0
          tempTotalRent =
            Number(contractInfo.rent) * Number(contractInfo.leaseTerm)
        } else {*/
        //如果月份小于零,则传入月租金
        tempTotalRent = Number(contractInfo.rent)
        //}
      }

      let chooseState=false
      for(let i in me.discountsList){
        if('17'==me.discountsList[i]){
          me.chooseBreakCard=true
          chooseState=true
          break
        }
      }

      if(!chooseState){
        me.chooseBreakCard=false
      }
      //此处和后台逻辑不同，付款方式选择一次性付清的话，在这里直接计算总金额 end
      renterMoney = doubleFifteenUtil.creatEditCycleBills(
          new Date(),
          begin,
          end,
          [
            {
              billCollectionPaymentType: '收', //收支状态
              costtype: config.COST_TYPE_OF_RENTERRENT, //租金
              costtypeVal: '租金',
              payTypeId: me.payMethodId,
              payUnitType: config.PAY_UNIT_MONTH,
              totalamount: tempTotalRent,
              count: Number(contractInfo.leaseTerm),
              identity: 'tenant', //owner 业主,tenant 租客
              typeFlag: '2',
              depositType: '1',
              describe: '租金',
              isDownpaymentVal:'是',
              flagType:flagType,
              reduceLastRent:me.reduceLastRent,
              chooseBreakCard:me.chooseBreakCard,
              goodsPriceMoney:me.goodsPriceMoney,

            },

          ],
          config.ADVANCE_DAYS,
          me.advancedPaymentDays,
          cashPledge,
          me.alertType,
          me.payMethodId,
          contractInfo.couponType,
          contractInfo.quarterlyDiscount,
          3,
          15,
      )

      // 押金账单
      renterDepositEnd = util.creatBills(
          end,
          begin,
          end,
          [
            {
              billCollectionPaymentType: '支',//收支状态
              costtype: config.COST_TYPE_OF_DEPOSIT,
              costtypeVal: '押金',
              payTypeId: config.PAY_TYPE_ONCE,
              payUnitType: config.PAY_UNIT_MONTH,
              totalamount: contractInfo.deposit,
              count: contractInfo.leaseTerm,
              identity: 'tenant',//owner 业主,tenant 租客
              typeFlag: '3',
              depositType: '1',
              describe: '押金'
            }
          ],
          config.ADVANCE_DAYS,
          me.advancedPaymentDays,
          '',
          '',
          me.payMethodId

      );
      if(contractInfo.paymentMethod.dictionaryValue=='9'||contractInfo.goodsDeposit){
        //物品押金账单
        goodsDepositEnd = util.creatBills(
            end,
            begin,
            end,
            [
              {
                billCollectionPaymentType: '支',//收支状态
                costtype: '28',
                costtypeVal: '押金',
                payTypeId: config.PAY_TYPE_ONCE,
                payUnitType: config.PAY_UNIT_MONTH,
                totalamount: contractInfo.deposit,
                count: contractInfo.leaseTerm,
                identity: 'tenant',//owner 业主,tenant 租客
                typeFlag: '3',
                depositType: '1',
                describe: '物品押金'
              }
            ],
            config.ADVANCE_DAYS,
            me.advancedDays,
            '',
            '',
            me.payMethodId

        );
      }


      let list = renterDeposit.concat(goodsDeposit).concat(otherBills).concat(renterMoney).concat(withrentBills).concat(goodsDepositEnd).concat(renterDepositEnd)

      for (let i = 0; i < list.length; i++) {
        list[i].billCollectionPaymentTypeVal = util.getPayDirectionLabel(
            list[i].billCollectionPaymentType
        )

        //改回加天数的时间
        if (
            contractInfo.otherDays > 0 &&
            list[i].costEndDate == contractInfo.endPeriod
        ) {
          list[i].costEndDate = util.dateFormat(
              util.dateAddDay(contractInfo.endPeriod, contractInfo.otherDays)
          )
        }
        if (
            contractInfo.otherDays > 0 &&
            list[i].collectionDate == contractInfo.endPeriod
        ) {
          list[i].collectionDate = util.dateFormat(
              util.dateAddDay(contractInfo.endPeriod, contractInfo.otherDays)
          )
        }
        if (list[i].costBeginDate && list[i].costEndDate) {
          list[i].range = list[i].costBeginDate + '至' + list[i].costEndDate
        } else {
          list[i].range = ''
        }

        for (let j = 0; j < this.costtypeList.length; j++) {
          if (this.costtypeList[j].value == list[i].costtype) {
            list[i].costtypeVal = this.costtypeList[j].label
            break
          }
        }
      }

      //加回额外天数
      contractInfo.endPeriod =
          contractInfo.otherDays > 0
              ? util.dateAddDay(contractInfo.endPeriod, contractInfo.otherDays)
              : contractInfo.endPeriod

      list.sort(function(a,b){
        //return a.collectionDate > b.collectionDate?1:-1
        // if(a.collectionDate == b.collectionDate){
        //     return me.getSort(a.billdescription)>me.getSort(b.billdescription)?1:-1
        // }else {
        let dateCompare = new Date(a.collectionDate) - new Date(b.collectionDate);
        if (dateCompare !== 0) {
          return dateCompare;
        }
        // 如果日期相同，那么按照索引从小到大排序
        return  new Date(a.costBeginDate).getTime() - new Date(b.costBeginDate).getTime()

        // }

      })

      return list
    },
    getSort(param){
          return param.replace(/[^0-9]/ig,"")
    },
    //接口结束 end
    isTenantInfo() {
      this.whichInfo = '0'
    },
    isContractInfo() {
      this.whichInfo = '1'
    },
    mansex() {
      if (!this.ownerInfo.sexState && this.ownerInfo.card.value != 0) {
        this.ownerInfo.sex = '1'
      }
    },
    womansex() {
      if (!this.ownerInfo.sexState && this.ownerInfo.card.value != 0) {
        this.ownerInfo.sex = '0'
      }
    },
    //判断性别的方法 sexType  0代表验证女性 1代表验证男性
    getWhatSex(sexType) {
      let that = this
      if (sexType == 0) {
        //用户已实名认证
        if (that.ownerInfo.ownerNameAuthentication == '1') {
          if (that.ownerInfo.sex == '0') {
            return true
          }
        } else {
          if (
            that.ownerInfo.card.value == 0 &&
            that.ownerInfo.cardNumber != undefined
          ) {
            //判断身份证倒数第二位
            let str = that.ownerInfo.cardNumber.substr(16, 1)
            if (that.ownerInfo.cardNumber.length == 18 && str % 2 == 0) {
              that.ownerInfo.sex = '0'
              return true
            }
            return false
          } else {
            if (that.ownerInfo.sex == '0') {
              return true
            }
          }
        }
      } else {
        //用户通过了实名认证
        if (that.ownerInfo.ownerNameAuthentication == '1') {
          if (that.ownerInfo.sex == '1') {
            return true
          }
        } else {
          if (
            that.ownerInfo.card.value == 0 &&
            that.ownerInfo.cardNumber != undefined
          ) {
            //判断身份证倒数第二位
            let str = that.ownerInfo.cardNumber.substr(16, 1)
            if (that.ownerInfo.cardNumber.length == 18 && str % 2 == 1) {
              that.ownerInfo.sex = '1'
              return true
            }
            return false
          } else {
            if (that.ownerInfo.sex == '1') {
              return true
            }
          }
        }
      }
      return false
    },
    cohabitingPersonmansex() {
      if (this.cohabitingPersonInfo.card.value == 0) {
        return
      }
      this.cohabitingPersonInfo.sex = '1'
    },
    getCoSex(sexType) {
      if (sexType == 0) {
        if (
          this.cohabitingPersonInfo.card.value == 0 &&
          this.ownerInfo.cardNumber != undefined
        ) {
          let str = this.cohabitingPersonInfo.cardNumber.substr(16, 1)
          if (
            this.cohabitingPersonInfo.cardNumber.length == 18 &&
            str % 2 == 0
          ) {
            this.cohabitingPersonInfo.sex = '0'
            return true
          }
        } else {
          if (this.cohabitingPersonInfo.sex == '0') {
            return true
          }
        }
      } else {
        if (
          this.cohabitingPersonInfo.card.value == 0 &&
          this.ownerInfo.cardNumber != undefined
        ) {
          let str = this.cohabitingPersonInfo.cardNumber.substr(16, 1)
          if (
            this.cohabitingPersonInfo.cardNumber.length == 18 &&
            str % 2 == 1
          ) {
            this.cohabitingPersonInfo.sex = '1'
            return true
          }
        } else {
          if (this.cohabitingPersonInfo.sex == '1') {
            return true
          }
        }
      }
      return false
    },
    cohabitingPersonwomansex() {
      if (this.cohabitingPersonInfo.card.value == 0) {
        return
      }
      this.cohabitingPersonInfo.sex = '0'
    },
    isSupportingService() {
      if(this.rejectEdit){   //租期中驳回修改不能改这个
        return
      }
      this.contractInfo.supportingService = true
    },
    noSupportingService() {
      if(this.rejectEdit){   //租期中驳回修改不能改这个
        return
      }
      this.contractInfo.supportingService = false
    },
    isFurtherAdvancedDays(){
      if(!this.editIsInAdvance15){
        return
      }
      this.furtherAdvancedDays='15'
      this.reduceFirstMonth()
    },
    noFurtherAdvancedDays(){
      if(!this.editIsInAdvance15){  //决策关了false不能修改
        return
      }
      this.furtherAdvancedDays='0'
      this.reduceFirstMonth()
    },

    changUpload(name) {
      this.$refs[name].chooseFile()
    },
    afterRead(file, base64Str, type) {
      let that = this
      let initData = {
        base64: base64Str,
      }
      that.loadingFlag = true
      base64Upload(initData).then(function(response) {
        that.loadingFlag = false
        responseUtil.dealResponse(that, response, () => {
          //1 租客身份证正面  2 反面   3 手持   4  同住人 身份证正面 5 背面  6 反面
          if (type == '1') {
            that.ownerInfo.CertificateFront = response.data.data.path
          } else if (type == '2') {
            that.ownerInfo.CertificateBack = response.data.data.path
          } else if (type == '3') {
            that.ownerInfo.CertificateHandHeld = response.data.data.path
          } else if (type == '4') {
            that.cohabitingPersonInfo.CertificateFront = response.data.data.path
          } else if (type == '5') {
            that.cohabitingPersonInfo.CertificateBack = response.data.data.path
          } else if (type == '6') {
            that.cohabitingPersonInfo.CertificateHandHeld =
              response.data.data.path
          }
        })
      })
    },
    //上传证件
    afterReadCertificateFront(file) {
      let that = this
      //压缩图片
      dealImageSingle(file, file.content, that.afterRead, '1')
    },
    afterReadCertificateBack(file) {
      let that = this
      //压缩图片
      dealImageSingle(file, file.content, that.afterRead, '2')
    },
    afterReadCertificateHandHeld(file) {
      let that = this
      //压缩图片
      dealImageSingle(file, file.content, that.afterRead, '3')
    },
    afterReadTest(file) {
      let fileLength = parseInt(file.length) - parseInt(1)
      if (file.length > 1) {
        for (let i = 0; i < file.length; i++) {
          let fileDetail = file[i]
          dealImage(
            fileDetail,
            fileDetail.content,
            400,
            this.afterReadLast,
            i,
            fileLength
          )
        }
      } else {
        // this.afterRead(file);
        dealImage(file, file.content, 400, this.afterReadLast)
      }
    },
    afterReadLast(file, base64Str, i, fileLength) {
      let that = this
      let initData = {
        base64: base64Str,
      }
      ;(that.loadingFlag = true),
        base64Upload(initData).then(function(response) {
          responseUtil.dealResponse(that, response, () => {
            file.path = response.data.data.path
            // that.loadingFlag = false
            if (i == fileLength) {
              that.loadingFlag = false
            }
          })
        })
    },
    cohabitingPersonCertificateFront(file) {
      let that = this
      //压缩图片
      dealImageSingle(file, file.content, that.afterRead, '4')
    },
    cohabitingPersonCertificateBack(file) {
      let that = this
      //压缩图片
      dealImageSingle(file, file.content, that.afterRead, '5')
    },
    cohabitingPersonCertificateHandHeld(file) {
      let that = this
      //压缩图片
      dealImageSingle(file, file.content, that.afterRead, '6')
    },
    clickRecommendShow() {
      if(this.rejectEdit){
        return
      }
      if(this.editSignPerson){
              return
      }
      this.isRecommendShow = true
    },
    selectRecommendOption(item) {
      if (item != undefined) {
        this.contractInfo.recommend = item
      }
      if (item == undefined) {
        this.contractInfo.recommend = ''
      }
      this.isRecommendShow = false
    },
    isliveTogether() {
      this.ownerInfo.liveTogether = true
      if (
        !this.cohabitingPersonInfo.card.value ||
        this.cohabitingPersonInfo.card.value == undefined
      ) {
        this.cohabitingPersonInfo.card.value = 0
      }
    },
    noliveTogether() {
      this.ownerInfo.liveTogether = false
    },
    //新增其他费用
    newOtherExpenses() {
      var otherExpenses = {
        billCollectionPaymentType: '收',
        costtype: '', //代表费用类型
        payTypeId: '', //代表支付类型
        totalamount: '', //支付金额
        payUnitType: config.PAY_UNIT_MONTH, //支付单位  默认元/月
        count: 0,
        identity: 'tenant', //owner 业主,tenant 租客
        describe: '', //支付描述
        typeFlag: '1', //其他费用
      }
      this.contractInfo.otherExpensesList.push(otherExpenses)
    },

    tempFirstOtherFees(
      index,
      payType,
      costtype,
      payTypeId,
      payUnitType,
      totalamount
    ) {
      let me = this
      if (index === false) {
        me.buttonInsertOne = false
      }
      let obj = {
        billCollectionPaymentType: payType, //收支状态
        costtype: costtype, //其他费用
        payTypeId: payTypeId,
        payUnitType: payUnitType,
        totalamount: totalamount, //总计 共
        count: 0,
        identity: 'tenant', //owner 业主,tenant 租客
        describe: '', //描述
        typeFlag: '1', //其他费用
      }

      me.contractInfo.otherExpensesList.push(obj)
      me.buttonInsertOne = false
    },

    //其他费用里的公共方法
    //公共方法根据传入的id 获取数组对应的名字
    getItemTitle(arr, id) {
      for (let i = 0; i < arr.length; i++) {
        if (id == arr[i].value) {
          return arr[i].label
        }
      }
    },
    //根据传入的名字获取id
    getItemId(arr, title) {
      for (let i = 0; i < arr.length; i++) {
        if (title == arr[i].label) {
          return arr[i].value
        }
      }
    },
    //根据推荐人名字找到对应的信息
    getRecommendInfo(val) {
      for (let i in this.recommendArr) {
        if (val == this.recommendArr[i].userName) {
          return this.recommendArr[i]
        }
      }
      return ''
    },
    //根据id或者value 获取对应数组的值
    getArrValuesByIndex(arr, index) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].value == index) {
          return arr[i]
        }
      }
      return ''
    },
    getArrDictionaryValueByIndex(arr, index) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].dictionaryValue == index) {
          return arr[i]
        }
      }
      return ''
    },
    //删除其他费用
    deleteOtherExpenses(index) {
      this.contractInfo.otherExpensesList.splice(index, 1)
    },

    formateMoney(other, index) {
      other = other.match(/\d+(\.\d{0,2})?/)
        ? other.match(/\d+(\.\d{0,2})?/)[0]
        : ''
      this.$set(
        this.contractInfo.otherExpensesList[index],
        'totalamount',
        other
      )
    },
    //改变收支

    changeIncome(other) {
      if(this.decision=='true'){
         return
      }
      if (other.billCollectionPaymentType == '收') {
        other.billCollectionPaymentType = '支'
        return
      }
      if (other.billCollectionPaymentType == '支') {
        other.billCollectionPaymentType = '收'
        return
      }
    },

    //点击下拉框区域外 下拉框隐藏
    clickCardShow() {
      if (this.ownerInfo.certificateState) {
        return
      }
      this.isCardShow = !this.isCardShow
    },
    //点击下拉框选项 进行数据绑定
    selectCardOption(item) {
      this.ownerInfo.card = item //到时候取类型的时候就直接.value就行了
      if (!this.ownerInfo.sexState && this.ownerInfo.card.value == 0) {
        this.ownerInfo.sex = ''
      }
      this.isCardShow = false
      this.isCardSelected = true
    },
    //点击下拉框区域外 下拉框隐藏
    clickCohabitingPersonCardShow() {
      this.isCohabitingPersonCardShow = !this.isCohabitingPersonCardShow
    },
    //点击下拉框选项 进行数据绑定
    selectCohabitingPersonCardOption(item) {
      this.cohabitingPersonInfo.card = item
      if (
        !this.cohabitingPersonInfo.sexState &&
        this.cohabitingPersonInfo.card.value == 0
      ) {
        this.cohabitingPersonInfo.sex = ''
      }
      this.isCohabitingPersonCardShow = false
      this.isCohabitingPersonCardSelected = true
    },
    clickPaymentMethodShow() {
      if(this.rejectEdit){   //租期中驳回修改不能改这个
          return
      }
      if (this.houseAddress == '' || this.houseAddress == undefined || this.houseAddress == null ){
        Notify({ type: 'danger', message: '请先选择房源地址再选择付款方式' });
      }else{
        this.isPaymentMethodShow = !this.isPaymentMethodShow
      }

    },
    clickRenterMethodShow() {
      if(this.roomChangeDisable){   //换房不可改租期
        return
      }
      if(this.flagType==1){   //修改不能改租期
        return
      }
      this.isRenterMethodShow = !this.isRenterMethodShow
    },
    clickAdvancedPaymentDays(){
      if(this.rejectEdit){   //租期中驳回修改不能改这个
        return
      }
      this.isAdvancedPaymentDays=!this.isAdvancedPaymentDays
    },
    selectPaymentMethodOption(item) {
      if(item.dictionaryValue==6){
        this.showAdvancedPaymentDaysSelect=true
          this.advancedPaymentDays=this.advancedPaymentDaysList[0].dictionaryValue
      }else{
        this.showAdvancedPaymentDaysSelect=false
      }
      this.contractInfo.paymentMethod = item
      this.payMethodId = item.dictionaryValue
      if(item.dictionaryValue!=6){
        this.furtherAdvancedDays=0
      }
      this.isPaymentMethodShow = false
      this.isPaymentMethodSelected = true
      this.preferentialContractChange()
    },
    selectRenterMethodOption(item) {
       this.contractInfo.leaseTerm = item.dictionaryValue
       this.isRenterMethodShow = false
       this.computeEndTime(this.contractInfo.leaseTerm)
    },
    selectAdvancedPaymentDays(item){
      this.isAdvancedPaymentDays=false
      this.advancedPaymentDays=item.dictionaryValue
      if(this.advancedPaymentDays=='30'){
        this.furtherAdvancedDays=0
      }
    },
    clickStartPeriodShow() {
      //租期是否可编辑，true不可编辑， flagType: 0,//0代表新增，1代表修改，2,3,4代表租约变更（2续签，3转租，4换房，6新签                                                   //修改且不是新签，不可点击，新签的修改可以点击
      if(this.roomChangeDisable||this.flagType=='2'||this.flagType=='3'||this.flagType=='1'||(this.flagType=='4'&&this.changeLeaseInfo[0].hasResponsibility=='0')||this.flagType=='6'||(this.flagType=='1' && this.$route.query.contractHistoryHistoryTypeVal!='新签')){
        return

      }
      if(this.flagType=='4'){  //如果是换房，最小日期是解除日期前一个月
        // this.minDate = new Date(this.contractInfo.startPeriod)
        this.minDate = new Date(new Date(this.detailInfo.subletDate_DateType).getTime()-24*3600*1000*30)
        if(new Date(this.contractInfo.startPeriod).getTime()<new Date(this.detailInfo.subletDate_DateType).getTime()-24*3600*1000*30){
          responseUtil.alertMsg(this,'换房新合同开始日期不能在解除日期30天之前')
          return;
        }
      }
      this.isStartPeriodShow = !this.isStartPeriodShow
      this.currentDate=new Date(this.contractInfo.startPeriod)
    },
    //关闭时间弹窗
    cancel() {
      this.isStartPeriodShow = false
      this.isEndPeriodShow = false
      this.isCheckInDateShow = false
    },
    //计算租期
    computeTenancyTerm(){
        let that = this
        if (this.contractInfo.endPeriod) {
          // var endDate = this.contractInfo.endPeriod
          var endDate = this.isChangeLeaseTerm=='false'&&this.alertType=='修改'&&this.isOwnerExpiration==1?this.contractInfo.cutOffDate:this.contractInfo.endPeriod   //关上且修改且业主到期
          if (typeof endDate == 'object') {
            endDate = util.dateFormat(endDate)
          }
          var startDate=this.contractInfo.startPeriod
          let date1 = startDate.split('-')
          let date2 = endDate.split('-')
          //获取年,月数
          var year1 = parseInt(date1[0]),
            month1 = parseInt(date1[1]),
            day1 = parseInt(date1[2]),
            year2 = parseInt(date2[0]),
            month2 = parseInt(date2[1]),
            day2 = parseInt(date2[2]),
            day = day2 + 1 - day1 >= 0 ? 0 : 1
          if (day1 == 1 && (day2 >=28)) {
            // let a = this.datemonth(startDate,endDate)
            day = -1
          }
          //通过年,月差计算月份差
          let months = (year2 - year1) * 12 + (month2 - month1) - day
          months = months >= 0 ? months : 0
          if(months>12){
            that.maxMonth= months
          }else{
            that.maxMonth=0
          }
          that.contractInfo.leaseTerm = months+''
          console.log(12312313,that.contractInfo.leaseTerm)
        }
    },
    //期限开始日期选择组件 点击确认键后执行方法
    startContractTimeConfirm(date) {
      //如果有责换房选择的合同开始日期>解除时间第二天，做提示，赋值为离房时间
      if(this.flagType=='4'&&this.changeLeaseInfo[0].hasResponsibility=='1'&&date.getTime()>util.dateAddDay(this.detailInfo.subletDate_DateType, 0).getTime()){
        responseUtil.alertMsg(this,'换房新合同开始日期必须小于等于旧合同解除日期')
        return
      }
      this.isStartPeriodShow = false
      this.contractInfo.startPeriod = this.formatDays(date)
      //添加判断
      var resultToday=util.dateAddMonth(date,12)
      resultToday=util.dateAddDay(resultToday,-1)
      var resultOwner=util.dateAddDay(new Date(this.contractInfo.cutOffDate),0)
      if(resultOwner.getTime()<=resultToday.getTime()){
        this.editTenancyTerm=true
        this.contractInfo.endPeriod=this.formatDays(this.contractInfo.cutOffDate)
      }else{
        this.editTenancyTerm=false
        var result=util.dateAddMonth(date,12)
        this.contractInfo.endPeriod=util.dateFormat(util.dateAddDay(result,-1))
      }
      console.log(77777,this.contractInfo.endPeriod);
      this.computeTenancyTerm()
      this.TimeProcessing=true
      this.preferentialContractChange()
      /*if (this.contractInfo.leaseTerm) {
        //改变开始时间 同时也要改变结束时间
        let contractEndTime = new Date(
          this.commonDateFormate(this.contractInfo.startPeriod)
        )
        contractEndTime.setMonth(
          contractEndTime.getMonth() + parseInt(this.contractInfo.leaseTerm)
        )
        contractEndTime.setDate(contractEndTime.getDate()-1)
        //转换 格式化成 YYYY-MM-DD 用于页面显示
        this.contractInfo.endPeriod = this.formatDays(contractEndTime)
      } else {
        //如果没有租期期限，那么合同结束日期给他清空
        this.contractInfo.endPeriod = ''
      }*/
      //入住日期改为默认开始时间
      this.contractInfo.checkInDate = this.contractInfo.startPeriod
    },

    //期限开始日期选择组件 点击确认键后执行方法
    endContractTimeConfirm(date) {
      this.contractInfo.endPeriod = this.formatDays(date)
      this.isEndPeriodShow = false
      //添加判断
      // if(this.contractInfo.leaseTerm){
      //     //改变开始时间 同时也要改变结束时间
      //     let contractEndTime = new Date(this.commonDateFormate(this.contractInfo.startPeriod))
      //     contractEndTime.setMonth(contractEndTime.getMonth() + parseInt(this.contractInfo.leaseTerm))
      //     //转换 格式化成 YYYY-MM-DD 用于页面显示
      //     this.contractInfo.endPeriod = this.formatDays(contractEndTime)
      // } else {
      //     //如果没有租期期限，那么合同结束日期给他清空
      //     this.contractInfo.endPeriod = ''
      // }
      // //入住日期改为默认开始时间
      // this.contractInfo.checkInDate = this.contractInfo.startPeriod
    },

    //日期选择组件 点击取消键后执行方法
    startPeriodCancel() {
      this.isStartPeriodShow = false
    },
    clickEndPeriodShow() {
      this.isEndPeriodShow = !this.isEndPeriodShow
      this.currentDate=new Date(this.contractInfo.endPeriod)
    },
    //日期选择组件 点击确认键后执行方法
    endPeriodConfirm(value) {
      var date = new Date(value)
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      if (month < 10) month = '0' + month
      var days = date.getDate()
      if (days < 10) days = '0' + days
      this.contractInfo.endPeriod = year + '-' + month + '-' + days
      this.isEndPeriodShow = false
    },
    //日期选择组件 点击取消键后执行方法
    endPeriodCancel() {
      this.isEndPeriodShow = false
    },
    clickCheckInDateShow() {
      this.isCheckInDateShow = !this.isCheckInDateShow
      this.currentDate=new Date(this.contractInfo.checkInDate)
    },
    //日期选择组件 点击确认键后执行方法
    checkInDateConfirm(date) {
      this.contractInfo.checkInDate = this.formatDays(date)
      this.isCheckInDateShow = false
      this.preferentialContractChange()
    },
    //日期选择组件 点击取消键后执行方法
    checkInDateCancel() {
      this.isCheckInDateShow = false
    },
    chooseLabel(index) {
      this.labelList[index].isChoose = !this.labelList[index].isChoose
      if (this.labelList[index].isChoose) {
        this.contractInfo.labelList.push(this.labelList[index])
      }
      if (!this.labelList[index].isChoose) {
        for (var i = 0; i < this.contractInfo.labelList.length; i++) {
          if (
            this.labelList[index].name == this.contractInfo.labelList[i].name
          ) {
            this.contractInfo.labelList.splice(i, 1)
            break
          }
        }
      }
    },
    chooseDiscounts(item) {
      /*if(item.value=='1'&&this.contractInfo.oldLeadYoung){
           return
      }
      if(item.value=='2'&&this.contractInfo.onSale){
           return
      }*/

      if(this.rejectEdit){
        return
      }
      let discountsId = item.value
      let discounts = JSON.parse(JSON.stringify(this.contractInfo.discounts))
      let discountsIdIndex = discounts.indexOf(discountsId) //只存放id
      if (discountsIdIndex == '-1') {
        this.contractInfo.discounts.push(discountsId)
      } else {
        this.contractInfo.discounts.splice(discountsIdIndex, 1)
      }
      this.preferentialContractChange()
    },
    //点击下拉框区域外 下拉框隐藏
    clickSignatoryShow() {
      // if(this.clickSignState){
      //     return
      // }
      if(this.rejectEdit){
        return
      }
      if(this.editSignPerson){
              return
      }
      this.$router.push({
        name: 'referrer',
        query: {
          type: 1,
        },
      })
    },
    //点击下拉框选项 进行数据绑定
    selectSignatoryOption(item) {
      this.contractInfo.signatory = item
      this.isSignatoryShow = false
      this.isSignatorySelected = true
    },
    //点击下拉框区域外 下拉框隐藏
    clickPartnerShow() {
      if(this.rejectEdit){
        return
      }
      if(this.editSignPerson){
              return
      }
      this.$router.push({
        name: 'referrer',
        query: {
          type: 2,
        },
      })
    },
    //点击下拉框选项 进行数据绑定
    selectPartnerOption(item) {
      this.contractInfo.partner = item
      this.isPartnerShow = false
      this.isPartnerSelected = true
    },
    //点击下拉框区域外 下拉框隐藏
    clickPartnershipProportionShow() {
      if(this.rejectEdit){
        return
      }
      this.isPartnershipProportionShow = !this.isPartnershipProportionShow
    },
    //点击下拉框选项 进行数据绑定
    selectPartnershipProportionOption(item) {
      this.contractInfo.partnershipProportion = item
      this.isPartnershipProportionShow = false
      this.isPartnershipProportionSelected = true
    },
    clickTypeShow(other, index) {
      if(this.decision=='true'){
         return
      }
      this.isTypeShow = true
      this.chooseIndex = index
    },
    selectTypeOption(item) {
      this.contractInfo.otherExpensesList[this.chooseIndex].costtype =
        item.value
      this.isTypeShow = false
    },
    clickOtherPaymentMethodShow(other, index) {
      if(this.decision=='true'){
         return
      }
      this.isPaymentOtherMethodShow = true
      this.chooseIndex = index
    },
    selectOtherPaymentMethodOption(item) {
      if (item.value == '4' || item.value == '5') {
        this.otherFeeType = false
      } else {
        this.otherFeeType = true
      }
      this.contractInfo.otherExpensesList[this.chooseIndex].payTypeId =
        item.value
      this.isPaymentOtherMethodShow = false
    },
    clickPaymentIntervalShow(other, index) {
      if(this.decision=='true'){
        return
      }
      this.isPaymentIntervalShow = true
      this.chooseIndex = index
    },
    clickOtherPaymentIntervalShow(other, index) {
      if(this.decision=='true'){
         return
      }
      this.isOtherPaymentIntervalShow = true
      this.chooseIndex = index
    },
    selectPaymentIntervalOption(item) {
      this.contractInfo.otherExpensesList[this.chooseIndex].payUnitType =
        item.value
      this.isPaymentIntervalShow = false
    },
    selectOtherPaymentIntervalOption(item) {
      this.contractInfo.otherExpensesList[this.chooseIndex].payUnitType =
        item.value
      this.isOtherPaymentIntervalShow = false
    },
    getLables() {
      if (this.contractLabel == undefined) {
        this.contractLabel = []
      }
      let result = []
      let fifterArr = this.contractLabel.filter(item => {
        return item.isChoose
      })
      if (fifterArr != []) {
        fifterArr.forEach(item => {
          result.push(item.id)
        })
      }
      return result
    },
    setLables(lables) {
      let target = this.contractLabel
      lables = lables || ''
      lables = lables.split(',')
      for (let i = 0; i < target.length; i++) {
        for (let j = 0; j < lables.length; j++) {
          if (target[i].id == lables[j]) {
            this.$set(target[i], 'isChoose', true)
          }
        }
      }
      return target
    },
    getNewDataDays(dateTemp, days) {
      var arr = dateTemp.split('-')
      var nDate = new Date(arr[1] + '/' + arr[2] + '/' + arr[0]) //转换为MM-DD-YYYY格式
      var millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000
      var rDate = new Date(millSeconds)
      var year = rDate.getFullYear()
      var month = rDate.getMonth() + 1
      if (month < 10) month = '0' + month
      var date = rDate.getDate()
      if (date < 10) date = '0' + date
      return year + '-' + month + '-' + date
    },

    //公共转换日期转换格式(YYYY-MM-DD转换为YYYY/MM/DD适应ios系统)
    commonDateFormate(time) {
      var arr = time.split('-')
      var nDate = new Date(arr[1] + '/' + arr[2] + '/' + arr[0])

      var year = nDate.getFullYear()
      var month = nDate.getMonth() + 1
      if (month < 10) month = '0' + month
      var date = nDate.getDate()//-1
      if (date < 10) date = '0' + date
      return year + '-' + month + '-' + date
    },

    //公共转换日期转换格式(YYYY-MM-DD转换为YYYY/MM/DD适应ios系统)
    commonDateFormateOther(time) {
      var arr = time.split('-')
      var nDate = new Date(arr[1] + '/' + arr[2] + '/' + arr[0])
      var year = nDate.getFullYear()
      var month = nDate.getMonth() + 1
      if (month < 10) month = '0' + month
      var date = nDate.getDate()
      if (date < 10) date = '0' + date
      return year + '/' + month + '/' + date
    },

    //页面参数
    requstParams() {
      let that = this
      // 续租---新签+2  或 续签+1
      // console.log(this.detailInfo.chhtype)
      // console.log(this.flagType)
      if(this.detailInfo.chhtype=='新签' && this.flagType=='2' || this.detailInfo.chhtype=='续签' && this.flagType=='1'){
        this.billTitle='续租'
      }else{
        this.billTitle=''
      }

      //that.terminateType&&
      if(that.terminateInfoParam.length>0){
          that.terminateInfoParam[0].terminateType=that.terminateType
          that.terminateInfoParam[0].normalType=that.changeLeaseInfo[0].normalType
          that.terminateInfoParam[0].hasResponsibility=that.changeLeaseInfo[0].hasResponsibility
          that.terminateInfoParam[0].payRepair=that.changeLeaseInfo[0].payRepair
          that.terminateInfoParam[0].reservedWater=that.changeLeaseInfo[0].reservedWater
          that.terminateInfoParam[0].wrongSide=that.changeLeaseInfo[0].wrongSide
          that.terminateInfoParam[0].terminateReason=that.changeLeaseInfo[0].terminateReason
          that.terminateInfoParam[0].remarks=that.changeLeaseInfo[0].remarks
          that.terminateInfoParam[0].leaveTime=that.changeLeaseInfo[0].leaveTime//that.leaveTime
          that.terminateInfoParam[0].actualDepartureTime=that.changeInfoParam.actualDepartureTime
          that.terminateInfoParam[0].remainWaterMoney=that.detailInfo.remainWaterMoney?that.detailInfo.remainWaterMoney:'0'

        //that.terminateInfoParam[0].terminateType=that.changeLeaseInfo[0].terminateType
      }
      let requestParams = {
        contractStatus:that.$route.query.contractStatus?that.$route.query.contractStatus:'',
        contractCodeOld: that.contractCodeOld,
        contractHistory_Id: that.contractHistory_Id,
        terminateInfoParam: that.terminateInfoParam,
        //只有修改合同可以传合同id
        id: that.flagType == 1 ? that.contractId : '', //合同id
        roomId: that.roomId,
        houseAddress: that.houseAddress,
        renterMobile: that.ownerInfo.phone,
        fixedMobile: that.ownerInfo.phone,
        AddStaffId: getStaffId(),
        renterName: that.ownerInfo.name,
        renterGender: that.ownerInfo.sex,
        renterCertificateType: that.ownerInfo.card.value, //承租人证件类型
        renterCertificateCode: that.ownerInfo.cardNumber, //承租人证件号码
        emergencyContactPerson: that.ownerInfo.emergencyContactName, //紧急联系人姓名
        emergencyContactPhone: that.ownerInfo.emergencyContactPhone, //紧急联系人号码
        emergencyContactAddress: that.ownerInfo.emergencyContactAddress, //紧急联系人地址
        emergencyContactEmail: that.ownerInfo.emergencyContactEmail, //紧急联系邮箱
        potentialResource_id: that.id, //潜在资源id
        hasCohabit: that.ownerInfo.liveTogether, //是否有同住人 false true
        cohabitName: that.cohabitingPersonInfo.name, //同住人姓名
        cohabitMovle: that.cohabitingPersonInfo.phone, //同住人手机号
        cohabitCertificateType: that.cohabitingPersonInfo.card.value, //同住人证件类型
        cohabitCertificateCode: that.cohabitingPersonInfo.cardNumber, //同住人证件号码
        cohabitGender: that.cohabitingPersonInfo.sex, //同住人性别
        contractCode: that.contractInfo.number, //合同编号
        payTypeId: that.payMethodId, //付款方式id
        rentMoney: that.contractInfo.rent, //租金
        depositMoney: that.contractInfo.deposit, //押金
        rentTimeLong: that.contractInfo.leaseTerm, //租期
        startDate: that.contractInfo.startPeriod, //合同开始时间
        endDate: that.contractInfo.endPeriod, //合同结束时间
        entryTime: that.contractInfo.checkInDate, //入住时间
        lables: that.getLables(), //标签列表
        reserveId: that.perContractId, //预定合同id
        hasAddtionService: that.contractInfo.supportingService, //是否有配套服务  0代表无 1代表有
        // preferentialContractVal: util.getValues(that.contractInfo.preferentialContract, me.preferentialContractList,'label','value').join(','),
        preferentialContractId: that.contractInfo.discounts, //优惠券活动id
        footnote: that.contractInfo.explanationDescribe, //补充说明
        signStaffId: that.contractInfo.signatory.id, //签约人id
        partnerStaffId: that.contractInfo.partner.id || '', //合作人id
        partnerRatioId: that.contractInfo.partnershipProportion.value || '', //合f作占比id
        signDepartment_id: that.contractInfo.signDepartment.id, //签约人部门id
        partnerDepartment_id: that.contractInfo.partnerDepartment.id || '', //合作人部门id
        recommendUser_id: that.contractInfo.recommend.id || 0, //推荐人id
        frontOfCard:
          that.ownerInfo.CertificateFront ==
          require('../../../assets/images/default_img_4.png')
            ? ''
            : that.ownerInfo.CertificateFront, //租客身份证正面
        reverseOfCard:
          that.ownerInfo.CertificateBack ==
          require('../../../assets/images/default_img_5.png')
            ? ''
            : that.ownerInfo.CertificateBack, //租客身份证反面
        holdingCard:
          that.ownerInfo.CertificateHandHeld ==
          require('../../../assets/images/default_img_3.png')
            ? ''
            : that.ownerInfo.CertificateHandHeld, //租客手持身份证
        cohabitOfCard:
          that.cohabitingPersonInfo.CertificateFront ==
          require('../../../assets/images/default_img_4.png')
            ? ''
            : that.cohabitingPersonInfo.CertificateFront, //同住人身份证正面
        cohabitseOfCard:
          that.cohabitingPersonInfo.CertificateBack ==
          require('../../../assets/images/default_img_5.png')
            ? ''
            : that.cohabitingPersonInfo.CertificateBack, //同住人身份证反面
        //线下合同图片什么鬼，是不是合同附件
        outLinecontratPicList: that.contractInfo.attachmentList, //合同附件
        //线下合同图片修改标识什么鬼
        outLineImgChangeType: true, //好像是一个布尔类型的值
        otherFeeList: that.contractInfo.otherExpensesList, //其他费用
        otherDays: that.contractInfo.otherDays, //额外时间
        // me.advancedDays=='15'&& me.furtherAdvancedDays=='1'
        bills: that.rejectEdit?[]:(that.advancedPaymentDays=='15'&& that.furtherAdvancedDays=='15'&& that.contractInfo.leaseTerm==12)?that.creatEditPayTimeBills():that.createBill(), //账单
        surplusgoodsPrice:that.contractInfo.goodsDeposit?that.contractInfo.goodsDeposit:'',
        // 'percentage':12==that.contractInfo.leaseTerm?(that.flagType=='3'?that.percentage:100):that.percentage?that.percentage:that.detailInfo.percentage,
        'percentage':that.percentage?that.percentage:that.detailInfo.percentage,
        'furtherAdvancedDays':that.furtherAdvancedDays,   //再提前天数
        'city_id':that.city_id,   //房源city_id
        halfPriceRental:that.halfPriceRental,
      }
      requestParams.refund=that.$route.query.refund
      if(!that.rejectEdit){
        // 定金抵扣
        // 如果有定金，则走定金抵扣(待签字修改或预定进入
        if(that.earnestMoney && that.earnestMoney!='' && ((that.detailInfo.earnestMoney !=0 && that.detailInfo.payType=='1' && that.detailInfo.reserveId && that.detailInfo.contractStatus=='5')||(that.$route.query.type == '2'))){
          requestParams.bills = that.deductionRent(requestParams.bills)
        }
        //如果续签，新的抵扣方式
        if(that.leaseType=='续签' || (that.alertType=='修改' && that.contractInfo.type=='续签') ){
          requestParams.bills = that.depositDeduction(requestParams.bills)
        }else {  //其他变更类型用原来的抵扣方式
          //换房抵扣
          // if (that.returnSum != '' && that.returnSum != undefined) {
          //   requestParams.bills = that.houseChangeRent(requestParams.bills)
          // }
          //如果是续租，押金抵扣
          if(that.restDepositMoney<0){
            if (that.restDepositMoney != '' && that.restDepositMoney != undefined) {
              requestParams.bills = that.restDepositRent(requestParams.bills)
            }
          }
          //如果是续租，物品押金抵扣
          /*if(that.restDepositMoney+that.contractInfo.deposit<0){
            if(that.restDepositMoney && that.restDepositMoney != ''){
              requestParams.bills = that.goodsDepositRent(requestParams.bills)
            }
          }*/
          if(that.goodsPriceMoney<0||Number(that.restDepositMoney)+Number(that.contractInfo.deposit)+Number(that.goodsPriceMoney)<0){
            requestParams.bills = that.goodsDepositRent(requestParams.bills)
          }
        }
      }



      //如果  1.是续租，2.房间费用配置里有水电费押金，2.剩余水电押金存在， 则 水电费抵扣

      // if((that.flagType==1 || that.flagType==2) && this.detailInfo.remainWaterMoney!=undefined){
      //   if(that.contractInfo.otherExpensesList && that.contractInfo.otherExpensesList.length>0){
      //     that.contractInfo.otherExpensesList.map((item)=>{
      //       //其他费用里有水电费，且为一次性付清，且有租约变更信息，且有剩余水电费
      //       if(item.costtype===4 && item.payTypeId==='4'){
      //         that.standardWaterElectricDeposit = item  //标准水电费押金
      //       }
      //     })
      //   }
      //   requestParams.bills = that.waterElectricDepositRent(requestParams.bills)
      // }
      // 押一付一6
      // 押二付一9 提前1天
      // 季付1  半年付2  年付3   一次性付清4  提前30天
      if(that.payMethodId==6){
        requestParams.advancePaymentDays=that.advancedPaymentDays
      }else if(that.payMethodId==8||that.payMethodId==9){
        requestParams.advancePaymentDays=1
      }else if(that.payMethodId==1||that.payMethodId==2||that.payMethodId==3||that.payMethodId==4){
        requestParams.advancePaymentDays=30
      }else{
        requestParams.advancePaymentDays=that.advancedPaymentDaysList[0].dictionaryValue
      }
      return requestParams
    },

    //计算抵扣的费用周期
    calculateTime(
      costBeginDate,
      costEndDate,
      residueEarnestMoney,
      totalamount
    ) {
      //日期相差天数
      var days = util.dayMinus(new Date(costBeginDate), new Date(costEndDate))
      //费用百分比
      var percentage = Number(
        Number(residueEarnestMoney) / Number(totalamount)
      ).toFixed(2)
      //天数
      var day = Number(days * percentage).toFixed(0)
      //开始时间加天数
      var midTime = util.dateAddDay(new Date(costBeginDate), day)
      return util.dateToString(midTime)
    },

    //如果定金抵扣房租方法
    deductionRent(bills) {
      let that = this
      let restDepositMoney = Number(Math.abs(this.earnestMoney))  //已交押金
      // let restDepositMoney = type=='reserve'?Number(Math.abs(this.earnestMoney)):Number(Math.abs(this.sumAll))  //已交押金
      if(restDepositMoney==0){  //剩余定金为0不需要抵扣
        return bills
      }
      for(let i = 0 ; i < bills.length; i++){
        let bill = bills[i];
        if(bill.billCollectionPaymentType == 0 && bill.costtype ==config.COST_TYPE_OF_RENTERRENT){  //找出应收的租金账单
          // 账单金额大于  剩余押金
          if(Number(bill.totalamount) > restDepositMoney){
            let tempBill = JSON.parse(JSON.stringify(bill));
            var costBeginDate = bill.costBeginDate
            var costEndDate = bill.costEndDate
            var totalamount = bill.totalamount
            if(costEndDate == that.contractInfo.endPeriod && that.contractInfo.otherDays > 0){
              costEndDate = util.dateAddDay(that.contractInfo.endPeriod,-that.contractInfo.otherDays)
            }
            //计算费用周期，restDepositMoney/totalamount*周期相差天数，costEndDate时间为costBeginDate+占用的天数
            var midTime = that.calculateTime(costBeginDate,costEndDate,restDepositMoney,totalamount);
            bill.totalamount = restDepositMoney;
            bill.billdescription = bill.billdescription + '(定金抵扣)';
            bill.temporarybillType = '1'
            bill.billCollectionPaymentType = 3 //已收
            bill.billCollectionPaymentTypeVal = '已收' //已收
            bill.costEndDate = util.dateToString(util.dateAddDay(midTime,-1))
            bill.range = bill.costBeginDate + '至' + bill.costEndDate;
            tempBill.costBeginDate = midTime
            tempBill.range = tempBill.costBeginDate + '至' + tempBill.costEndDate;
            tempBill.totalamount = util.NumberAdd(Number(tempBill.totalamount),-restDepositMoney)  //Number(tempBill.totalamount) - restDepositMoney;  //还有多少没收
            bills.splice(i + 1,0,tempBill)
            restDepositMoney = 0;
          }else{
            let tempMoney =  util.NumberAdd(restDepositMoney,Number(-bill.totalamount)) //restDepositMoney - bill.totalamount;  //抵扣完账单金额之后还剩的定金
            // bill.totalamount = 0;
            restDepositMoney = tempMoney;
            bill.billdescription = bill.billdescription + '(定金抵扣)';
            bill.billCollectionPaymentType = 3 //已收
            bill.billCollectionPaymentTypeVal = '已收' //已收
            // //将所有租金抵扣后还剩定金
            // if(i==bills.length && restDepositMoney!=0){

            // }
          }
        }
        //如果剩余定金为0 跳出循环
        if(restDepositMoney == 0){
          break;
        }
      }
      //抵扣房租之后还有剩余定金,抵扣押金
      if (restDepositMoney > 0) {
        for (let i = 0; i < bills.length; i++) {
          let bill = bills[i]
          // 找出押金账单
          if (bill.costtype == 2 && bill.billCollectionPaymentType == 0) {
            // 账单金额大于定金
            if (Number(bill.totalamount) > restDepositMoney) {
              let tempBill = JSON.parse(JSON.stringify(bill))
              bill.totalamount = restDepositMoney
              bill.billdescription = bill.billdescription + '(定金抵扣)'
              bill.billCollectionPaymentType = 3 //已收
              bill.billCollectionPaymentTypeVal = '已收' //已收
              tempBill.totalamount =
                  Number(tempBill.totalamount) - restDepositMoney //还有多少没收
              bills.splice(i + 1, 0, tempBill)
              restDepositMoney = 0
            } else {
              let tempMoney = restDepositMoney - bill.totalamount //抵扣完账单金额之后还剩的定金
              // bill.totalamount = 0;
              restDepositMoney = tempMoney
              bill.billdescription = bill.billdescription + '(定金抵扣)'
              bill.billCollectionPaymentType = 3 //已收
              bill.billCollectionPaymentTypeVal = '已收' //已收
            }
          }
          //如果剩余定金为0 跳出循环
          if (restDepositMoney == 0) {
            break
          }
        }
      }

      //账单循环完毕，将所有租金抵扣后还剩定金，则生成一个已收押金
      if(restDepositMoney!=0){
        let tempBill = {}
        tempBill.billCollectionPaymentType = 3 //已收
        tempBill.billCollectionPaymentTypeVal = '已收' //已收
        tempBill.isDownpayment = '否';  //是否为首期款
        tempBill.costtype = 2  //费用类型
        tempBill.costtypeVal = '押金'
        tempBill.temporarybillType = '1'
        tempBill.collectionDate = bills[0].collectionDate//应收日期
        tempBill.range = bills[bills.length-1].range//费用周期
        tempBill.costBeginDate = bills[bills.length-1].costBeginDate//费用周期
        tempBill.costEndDate = bills[bills.length-1].costEndDate//费用周期
        tempBill.totalamount = Number(restDepositMoney).toFixed(2);  //金额
        tempBill.billdescription = '(定金抵扣)';  //描述

        // tempBill.costEndDate = util.dateToString(util.dateAddDay(midTime,-1))
        // tempBill.range = tempBill.costBeginDate + '至' + tempBill.costEndDate;
        bills.splice(bills.length+1,0,tempBill)

        for(let i = 0 ; i < bills.length; i++) {   //把钱合在应支的押金钱里
          let bill = bills[i];
          if (bill.billCollectionPaymentType == 1 && bill.costtype ==config.COST_TYPE_OF_DEPOSIT) {  //找出应支的押金账单
            bill.totalamount = util.NumberAdd(bill.totalamount,Number(restDepositMoney))
            bill.billdescription = bill.billdescription + '(包含已交定金)'
          }
        }

      }


      return bills
    },

    //换房抵扣租金方法
    houseChangeRent(bills) {
      let that = this
      // 应退总额
      let totalReturnMoney = Number(that.returnSum)
      // 剩余总额
      let residueReturnMoney = totalReturnMoney
      if (residueReturnMoney == 0) {
        return bills
      }
      //租金
      for (let i = 0; i < bills.length; i++) {
        let bill = bills[i]
        // 找出房租账单
        if (
          bill.costtype == 1 &&
          bill.billCollectionPaymentType != 3 &&
          bill.billCollectionPaymentType != 4
        ) {
          // 账单金额大于应退总额
          if (Number(bill.totalamount) > residueReturnMoney) {
            let tempBill = JSON.parse(JSON.stringify(bill))
            var costBeginDate = bill.costBeginDate
            var costEndDate = bill.costEndDate
            var totalamount = bill.totalamount
            if (
              costEndDate == that.contractInfo.endPeriod &&
              that.contractInfo.otherDays > 0
            ) {
              costEndDate = util.dateAddDay(
                that.contractInfo.endPeriod,
                -that.contractInfo.otherDays
              )
            }
            //计算费用周期，residueEarnestMoney/totalamount*周期相差天数，costEndDate时间为costBeginDate+占用的天数
            var midTime = that.calculateTime(
              costBeginDate,
              costEndDate,
              residueReturnMoney,
              totalamount
            )
            bill.totalamount = residueReturnMoney
            bill.billdescription = bill.billdescription + '(换房抵扣)'
            bill.billCollectionPaymentType = 3 //已收
            bill.billCollectionPaymentTypeVal = '已收' //已收
            bill.costEndDate = util.dateToString(util.dateAddDay(midTime, -1))
            bill.range = bill.costBeginDate + '至' + bill.costEndDate
            tempBill.costBeginDate = midTime
            tempBill.range =
              tempBill.costBeginDate + '至' + tempBill.costEndDate
            tempBill.totalamount =
              Number(tempBill.totalamount) - residueReturnMoney //还有多少没收
            bills.splice(i + 1, 0, tempBill)
            residueReturnMoney = 0
          } else {
            let tempMoney = residueReturnMoney - bill.totalamount //抵扣完账单金额之后还剩的定金
            // bill.totalamount = 0;
            residueReturnMoney = tempMoney
            bill.billdescription = bill.billdescription + '(换房抵扣)'
            bill.billCollectionPaymentType = 3 //已收
            bill.billCollectionPaymentTypeVal = '已收' //已收
          }
        }
        //如果剩余定金为0 跳出循环
        if (residueReturnMoney == 0) {
          break
        }
      }
      //服务费
      if (residueReturnMoney > 0) {
        for (let i = 0; i < bills.length; i++) {
          let bill = bills[i]
          // 找出房租账单
          if (
            bill.costtype == 11 &&
            bill.billCollectionPaymentType != 3 &&
            bill.billCollectionPaymentType != 4
          ) {
            // 账单金额大于应退总额
            if (Number(bill.totalamount) > residueReturnMoney) {
              let tempBill = JSON.parse(JSON.stringify(bill))
              var costBeginDate = bill.costBeginDate
              var costEndDate = bill.costEndDate
              var totalamount = bill.totalamount
              if (
                costEndDate == that.contractInfo.endPeriod &&
                that.contractInfo.otherDays > 0
              ) {
                costEndDate = util.dateAddDay(
                  that.contractInfo.endPeriod,
                  -that.contractInfo.otherDays
                )
              }
              //计算费用周期，residueEarnestMoney/totalamount*周期相差天数，costEndDate时间为costBeginDate+占用的天数
              var midTime = that.calculateTime(
                costBeginDate,
                costEndDate,
                residueReturnMoney,
                totalamount
              )
              bill.totalamount = residueReturnMoney
              bill.billdescription = bill.billdescription + '(换房抵扣)'
              bill.billCollectionPaymentType = 3 //已收
              bill.billCollectionPaymentTypeVal = '已收' //已收
              bill.costEndDate = util.dateToString(util.dateAddDay(midTime, -1))
              bill.range = bill.costBeginDate + '至' + bill.costEndDate
              tempBill.costBeginDate = midTime
              tempBill.range =
                tempBill.costBeginDate + '至' + tempBill.costEndDate
              tempBill.totalamount =
                Number(tempBill.totalamount) - residueReturnMoney //还有多少没收
              bills.splice(i + 1, 0, tempBill)
              residueReturnMoney = 0
            } else {
              let tempMoney = residueReturnMoney - bill.totalamount //抵扣完账单金额之后还剩的定金
              // bill.totalamount = 0;
              residueReturnMoney = tempMoney
              bill.billdescription = bill.billdescription + '(换房抵扣)'
              bill.billCollectionPaymentType = 3 //已收
              bill.billCollectionPaymentTypeVal = '已收' //已收
            }
          }
          //如果剩余定金为0 跳出循环
          if (residueReturnMoney == 0) {
            break
          }
        }
      }
      //押金
      if (residueReturnMoney > 0) {
        for (let i = 0; i < bills.length; i++) {
          let bill = bills[i]
          // 找出应收押金账单
          if (bill.costtype == 2 && bill.billCollectionPaymentType == 0) {
            // 账单金额大于应退总额
            if (Number(bill.totalamount) > residueReturnMoney) {
              let tempBill = JSON.parse(JSON.stringify(bill))
              var costBeginDate = bill.costBeginDate
              var costEndDate = bill.costEndDate
              var totalamount = bill.totalamount
              if (
                costEndDate == that.contractInfo.endPeriod &&
                that.contractInfo.otherDays > 0
              ) {
                costEndDate = util.dateAddDay(
                  that.contractInfo.endPeriod,
                  -that.contractInfo.otherDays
                )
              }
              //计算费用周期，residueEarnestMoney/totalamount*周期相差天数，costEndDate时间为costBeginDate+占用的天数
              var midTime = that.calculateTime(
                costBeginDate,
                costEndDate,
                residueReturnMoney,
                totalamount
              )
              bill.totalamount = residueReturnMoney
              bill.billdescription = bill.billdescription + '(换房抵扣)'
              bill.billCollectionPaymentType = 3 //已收
              bill.billCollectionPaymentTypeVal = '已收' //已收
              bill.costEndDate = util.dateToString(util.dateAddDay(midTime, -1))
              bill.range = bill.costBeginDate + '至' + bill.costEndDate
              tempBill.costBeginDate = midTime
              tempBill.range =
                tempBill.costBeginDate + '至' + tempBill.costEndDate
              tempBill.totalamount =
                Number(tempBill.totalamount) - residueReturnMoney //还有多少没收
              bills.splice(i + 1, 0, tempBill)
              residueReturnMoney = 0
            } else {
              let tempMoney = residueReturnMoney - bill.totalamount //抵扣完账单金额之后还剩的定金
              // bill.totalamount = 0;
              residueReturnMoney = tempMoney
              bill.billdescription = bill.billdescription + '(换房抵扣)'
              bill.billCollectionPaymentType = 3 //已收
              bill.billCollectionPaymentTypeVal = '已收' //已收
            }
          }
          //如果剩余定金为0 跳出循环
          if (residueReturnMoney == 0) {
            break
          }
        }
      }
      return bills
    },

    //押金抵扣方法
    // restDepositRent(bills){
    //     let that = this
    //     // 总定金
    //     let totalEarnestMoney = Number(that.restDepositMoney);
    //     // 剩余押金
    //     let restDepositMoney = totalEarnestMoney;
    //     if(restDepositMoney == 0){
    //         return bills;
    //     }
    //     //应收-应支
    //     for(let i = 0 ; i < bills.length; i++){
    //         let bill = bills[i];
    //         // 找出应收的押金账单
    //         if(bill.costtype == 2 && bill.billCollectionPaymentType == 0){
    //             bill.totalamount = Number(bill.totalamount) + Number(restDepositMoney);
    //             bill.billdescription = bill.billdescription + '(押金抵扣)';
    //             if(bill.totalamount < 0){
    //                 bill.billCollectionPaymentType = 1
    //             }
    //             break;
    //         }
    //     }
    //     return bills;
    // },
    //押金抵扣方法
    restDepositRent(bills) {

      let that = this
      // 总定金
      let totalEarnestMoney = Number(that.restDepositMoney)
      // 剩余押金
      let restDepositMoney = totalEarnestMoney
      if (restDepositMoney == 0) {
        return bills
      }
      //应收-应支
      for (let i = 0; i < bills.length; i++) {
        let bill = bills[i]
        // 找出应收的押金账单
        if (bill.costtype == 2 && bill.billCollectionPaymentType == 0) {
          // 如果新合同押金大于老合同剩余押金，那个会额外生成一个应支的押金账单
          if (Number(bill.totalamount) > Math.abs(restDepositMoney)) {
            //额外的账单
            let tempBill = JSON.parse(JSON.stringify(bill))
            tempBill.totalamount = Math.abs(restDepositMoney) //金额
            tempBill.billCollectionPaymentType = 3
            tempBill.billCollectionPaymentTypeVal = util.getPayDirectionLabel(
              tempBill.billCollectionPaymentType
            ) //已收
            tempBill.billdescription = tempBill.billdescription + '(押金抵扣)'
            tempBill.billCollectionPaymentTypeVal='已收'
            //bill.deduction = '1' //  是否为抵扣，1 抵扣
            bills.splice(i, 0, tempBill)
            //原本的账单
            bill.totalamount =
              Number(bill.totalamount) - Math.abs(Number(restDepositMoney))
          } else {
            let tempMoney = Number(bill.totalamount) //+ Number(restDepositMoney)
            bill.billCollectionPaymentType = 3
            bill.billdescription = bill.billdescription + '(押金抵扣)'
            bill.billCollectionPaymentTypeVal='已收'
            //bill.deduction = '1' //  是否为抵扣，1 抵扣
            if (tempMoney < 0) {
              bill.totalamount = tempMoney
              bill.totalamount = (-1 * bill.totalamount).toFixed(2)
              bill.billCollectionPaymentType = 1
              bill.billCollectionPaymentTypeVal = util.getPayDirectionLabel(
                3
              )
            } else if (Number(tempMoney) == 0) {
              bill.billCollectionPaymentType = 3
              bill.billCollectionPaymentTypeVal = util.getPayDirectionLabel(
                3
              )
            }
          }

          break
        }
      }
      return bills
    },
    //物品押金抵扣方法
    goodsDepositRent(bills){

            let that = this
            // 总定金
            let totalEarnestMoney = that.goodsPriceMoney?that.goodsPriceMoney:that.restDepositMoney+Number(that.contractInfo.deposit)//Number(that.restDepositMoney)+Number(that.contractInfo.deposit);
            // 剩余押金
            let restDepositMoney = totalEarnestMoney;
            if(restDepositMoney == 0){
                return bills;
            }
            //应收-应支
            for(let i = 0 ; i < bills.length; i++){
                let bill = bills[i];
                // 找出应收的押金账单
                if((bill.costtype == 28||bill.costtype == 2)&& bill.billCollectionPaymentType == 0){
                    // 如果新合同押金大于老合同剩余押金，那个会额外生成一个应支的押金账单
                    if(Number(bill.totalamount) > Math.abs(restDepositMoney)){
                        //额外的账单
                        let tempBill = JSON.parse(JSON.stringify(bill));
                        tempBill.totalamount = (Math.abs(restDepositMoney)).toFixed(2); //金额
                        tempBill.billCollectionPaymentType = 3
                        tempBill.billCollectionPaymentTypeVal = util.getPayDirectionLabel(tempBill.billCollectionPaymentType); //已收
                        tempBill.billdescription = tempBill.billdescription + '(物品押金抵扣)';
                        tempBill.billCollectionPaymentTypeVal='已收'
                        bills.splice(i, 0, tempBill)
                        //原本的账单
                        bill.totalamount = (Number(bill.totalamount) - Math.abs(Number(restDepositMoney))).toFixed(2);

                    }else{
                        let tempMoney = Number(bill.totalamount) //+ Number(restDepositMoney);
                        bill.billCollectionPaymentType = 3
                        bill.billdescription = bill.billdescription + '(押金抵扣)';
                        bill.billCollectionPaymentTypeVal='已收'
                        if(tempMoney < 0){
                            bill.totalamount = tempMoney;
                            bill.totalamount = ((-1) * bill.totalamount).toFixed(2)
                            bill.billCollectionPaymentType = 1
                            //bill.billCollectionPaymentTypeVal = util.getPayDirectionLabel(bill.billCollectionPaymentType);  //原
                            bill.billCollectionPaymentTypeVal = util.getPayDirectionLabel(3);                                 //赵
                        }else if(Number(tempMoney) == 0){
                            bill.billCollectionPaymentType = 3
                            //bill.billCollectionPaymentTypeVal = util.getPayDirectionLabel(bill.billCollectionPaymentType);  //原
                            bill.billCollectionPaymentTypeVal = util.getPayDirectionLabel(3);                                 //赵
                        }
                    }

                    break;
                }
            }
            return bills;
    },
    //水电费押金抵扣
    waterElectricDepositRent(bills){
      // console.log(this.detailInfo.remainWaterMoney)  //剩余水电押金
      // console.log(this.standardWaterElectricDeposit.totalamount)  //房子的标准水电押金
      // console.log(bills)
      this.standardWaterElectricDeposit.totalamount = this.standardWaterElectricDeposit.totalamount?this.standardWaterElectricDeposit.totalamount:0
      let that = this
      // 配置押金
      let totalEarnestMoney = Number(this.standardWaterElectricDeposit.totalamount);
      // 剩余押金
      let restDepositMoney = Number(this.detailInfo.remainWaterMoney);
      if(restDepositMoney==0){  //剩余押金为0不需要抵扣
        return bills
      }
      if(totalEarnestMoney==0){  //新房子没有水电费押金
        this.waterElectricDepositRentDeduction(bills)
      }
      for(let i = 0 ; i < bills.length; i++){
        let bill = bills[i];
        // console.log(bill)
        //如果是水电费,应收
        if(bill.costtype == 4 && bill.billCollectionPaymentType == 0){
          //1.剩余水电押金<标准水电押金，补已收，修改原有应收金额
          if(restDepositMoney<totalEarnestMoney){
            //额外的账单（剩余水电金额，已收，押金抵扣，
            let tempBill = JSON.parse(JSON.stringify(bill));
            tempBill.totalamount = (Math.abs(restDepositMoney)).toFixed(2); //金额
            tempBill.billCollectionPaymentType = 3
            tempBill.billCollectionPaymentTypeVal = util.getPayDirectionLabel(tempBill.billCollectionPaymentType); //已收
            tempBill.billdescription = tempBill.billdescription + '水电费押金抵扣';
            tempBill.billCollectionPaymentTypeVal='已收'
            bills.splice(i, 0, tempBill)
            //原本的账单（应收 差额 的钱
            bill.totalamount = (Number(totalEarnestMoney) - Math.abs(Number(restDepositMoney))).toFixed(2);
            i++
          }else if(restDepositMoney==totalEarnestMoney){  //2.剩余水电押金=标准水电押金，修改原有为已收
            bill.billCollectionPaymentType = 3
            bill.billCollectionPaymentTypeVal='已收'
            bill.billdescription = bill.billdescription + '水电费押金抵扣';
          }else{                                  //3.剩余水电押金>标准水电押金，原账单变为已收，剩余金额抵扣租金
            // console.log(totalEarnestMoney)
            bill.billCollectionPaymentType = 3
            bill.billCollectionPaymentTypeVal='已收'
            bill.billdescription = bill.billdescription + '水电费押金抵扣';
            this.waterElectricDepositRentDeduction(bills)
          }
        }
      }
      return bills;
    },
    //水电费押金用租金抵扣
    waterElectricDepositRentDeduction(bills){
      let that = this
      // 剩余水电费抵扣新合同的水电费押金后的  剩余金额
      let residueEarnestMoney = util.NumberAdd(Number(this.detailInfo.remainWaterMoney),-Number(this.standardWaterElectricDeposit.totalamount));
      if(residueEarnestMoney == 0){
        return bills;
      }
      for(let i = 0 ; i < bills.length; i++){
        let bill = bills[i];
        // 找出房租账单
        if(bill.costtype == 8 && bill.billCollectionPaymentType != 3 && bill.billCollectionPaymentType != 4){
          // 账单金额大于  减去本次押金后的剩余押金
          if(Number(bill.totalamount) > residueEarnestMoney){
            let tempBill = JSON.parse(JSON.stringify(bill));
            var costBeginDate = bill.costBeginDate
            var costEndDate = bill.costEndDate
            var totalamount = bill.totalamount
            if(costEndDate == that.contractInfo.endPeriod && that.contractInfo.otherDays > 0){
              costEndDate = util.dateAddDay(that.contractInfo.endPeriod,-that.contractInfo.otherDays)
            }
            //计算费用周期，residueEarnestMoney/totalamount*周期相差天数，costEndDate时间为costBeginDate+占用的天数
            var midTime = that.calculateTime(costBeginDate,costEndDate,residueEarnestMoney,totalamount);
            bill.totalamount = residueEarnestMoney;
            bill.billdescription = bill.billdescription + '(水电费押金抵扣)';
            bill.billCollectionPaymentType = 3 //已收
            bill.billCollectionPaymentTypeVal = '已收' //已收
            bill.temporarybillType = '1'
            bill.costEndDate = util.dateToString(util.dateAddDay(midTime,-1))
            bill.range = bill.costBeginDate + '至' + bill.costEndDate;
            tempBill.costBeginDate = midTime
            tempBill.range = tempBill.costBeginDate + '至' + tempBill.costEndDate;
            tempBill.totalamount = Number(tempBill.totalamount) - residueEarnestMoney;  //还有多少没收
            bills.splice(i + 1,0,tempBill)
            residueEarnestMoney = 0;
          }else{
            let tempMoney = residueEarnestMoney - bill.totalamount;  //抵扣完账单金额之后还剩的定金
            // bill.totalamount = 0;
            residueEarnestMoney = tempMoney;
            bill.billdescription = bill.billdescription + '(定金抵扣)';
            bill.billCollectionPaymentType = 3 //已收
            bill.billCollectionPaymentTypeVal = '已收' //已收
            // //将所有租金抵扣后还剩定金
            // if(i==bills.length && residueEarnestMoney!=0){

            // }
          }
        }

        //如果剩余定金为0 跳出循环
        if(residueEarnestMoney == 0){
          break;
        }
      }
      //账单循环完毕，将所有租金抵扣后还剩定金，则生成一个已收押金
      if(residueEarnestMoney > 0){
        // console.log(residueEarnestMoney)
        let tempBill = {}
        tempBill.billCollectionPaymentType = 3 //已收
        tempBill.billCollectionPaymentTypeVal = '已收' //已收
        tempBill.isDownpayment = '否';  //是否为首期款
        tempBill.costtype = 2  //费用类型
        tempBill.costtypeVal = '押金'
        tempBill.collectionDate = bills[bills.length-1].collectionDate//应收日期
        tempBill.range = bills[bills.length-1].range//费用周期
        tempBill.costBeginDate = bills[bills.length-1].costBeginDate//费用周期
        tempBill.costEndDate = bills[bills.length-1].costEndDate//费用周期
        tempBill.totalamount = residueEarnestMoney.toFixed(2);  //金额
        tempBill.billdescription = '押金(水电费押金抵扣)';  //描述

        // tempBill.costEndDate = util.dateToString(util.dateAddDay(midTime,-1))
        // tempBill.range = tempBill.costBeginDate + '至' + tempBill.costEndDate;
        bills.splice(bills.length+1,0,tempBill)

      }
      return bills;
    },
    //新的续签抵扣方式
    depositDeduction(bills,type){
      let that = this
      // let restDepositMoney = JSON.parse(JSON.stringify(this.detailList))  //已交押金
      // let restDepositMoney = type=='reserve'?Number(Math.abs(this.earnestMoney)):Number(Math.abs(this.sumAll))  //已交押金
      let restDepositMoney = Number(Math.abs(this.sumAll))  //已交总额

      if(restDepositMoney==0){  //剩余押金为0不需要抵扣
        return bills
      }
      // 先抵扣押金,再抵扣保洁,再抵扣一次性费用,再按照时间抵扣
      let deductionList = [
        {
          deductType:'costtype',
          deductTypeVal:config.COST_TYPE_OF_CLEANING,
          remarkName:'续签',
        },
        {
          deductType:'costtype',
          deductTypeVal:config.COST_TYPE_OF_DEPOSIT,
          remarkName:'续签',
        },
        {
          deductType:'costtype',
          deductTypeVal:config.COST_TYPE_OF_WATER,
          remarkName:'续签',
        },
        {
          deductType:'payTypeId',
          deductTypeVal:config.PAY_TYPE_ONCE,
          remarkName:'续签',
        },
        {
          deductType:'',
          deductTypeVal:'',
          remarkName:'续签',
        },
      ]
      for(let i in deductionList){
        if(restDepositMoney!=0){
          let returnMap = this.mainDeduction(bills,restDepositMoney, deductionList[i].deductType, deductionList[i].deductTypeVal,deductionList[i].remarkName)
          bills = returnMap.bills
          restDepositMoney = returnMap.restMoney
        }else {
          break
        }
      }

      //账单循环完毕，将所有租金抵扣后还剩定金，则生成一个已收押金
      if(restDepositMoney!=0){
        let tempBill = {}
        tempBill.billCollectionPaymentType = 3 //已收
        tempBill.billCollectionPaymentTypeVal = '已收' //已收
        tempBill.isDownpayment = '否';  //是否为首期款
        tempBill.costtype = 2  //费用类型
        tempBill.costtypeVal = '押金'
        tempBill.temporarybillType = '1'
        tempBill.collectionDate = bills[0].collectionDate//应收日期
        tempBill.range = bills[bills.length-1].range//费用周期
        tempBill.costBeginDate = bills[bills.length-1].costBeginDate//费用周期
        tempBill.costEndDate = bills[bills.length-1].costEndDate//费用周期
        tempBill.totalamount = Number(restDepositMoney).toFixed(2);  //金额
        tempBill.billdescription = '(续签抵扣)';  //描述

        // tempBill.costEndDate = util.dateToString(util.dateAddDay(midTime,-1))
        // tempBill.range = tempBill.costBeginDate + '至' + tempBill.costEndDate;
        bills.splice(bills.length+1,0,tempBill)

        for(let i = 0 ; i < bills.length; i++) {   //把钱合在应支的押金钱里
          let bill = bills[i];
          if (bill.billCollectionPaymentType == 1 && bill.costtype ==config.COST_TYPE_OF_DEPOSIT) {  //找出应支的押金账单
            bill.totalamount = util.NumberAdd(bill.totalamount,Number(restDepositMoney))
            bill.billdescription = bill.billdescription + '(包含续签押金)'
          }
        }

      }
      return bills
    },
    //抵扣主逻辑:账单、抵扣的钱、抵扣类型、抵扣类型值
    mainDeduction(bills,restMoney, deductType, deductTypeVal, remarkName){
      let that = this
      for(let i = 0 ; i < bills.length; i++) {
        let bill = bills[i];
        if(bill.billCollectionPaymentType == 0 && (bill[deductType]!=undefined && bill[deductType] == deductTypeVal || bill[deductType]==undefined)){
          if(Number(bill.totalamount) > restMoney){
            let tempBill = JSON.parse(JSON.stringify(bill));
            var costBeginDate = bill.costBeginDate
            var costEndDate = bill.costEndDate
            var totalamount = bill.totalamount
            if(costEndDate == that.contractInfo.endPeriod && that.contractInfo.otherDays > 0){
              costEndDate = util.dateAddDay(that.contractInfo.endPeriod,-that.contractInfo.otherDays)
            }
            var midTime = that.calculateTime(costBeginDate,costEndDate,restMoney,totalamount);
            bill.totalamount = restMoney;
            bill.billdescription = bill.billdescription + `(${remarkName}抵扣)`;
            bill.temporarybillType = '1'
            bill.billCollectionPaymentType = 3 //已收
            bill.billCollectionPaymentTypeVal = '已收' //已收
            bill.costEndDate = util.dateToString(util.dateAddDay(midTime,-1))
            bill.range = bill.costBeginDate + '至' + bill.costEndDate;
            tempBill.costBeginDate = midTime
            tempBill.range = tempBill.costBeginDate + '至' + tempBill.costEndDate;
            tempBill.totalamount = util.NumberAdd(Number(tempBill.totalamount),-restMoney)   //Number(tempBill.totalamount) - restMoney;  //还有多少没收
            bills.splice(i + 1,0,tempBill)
            restMoney = 0;
          }else{
            let tempMoney =  util.NumberAdd(restMoney,Number(-bill.totalamount)) //restMoney - bill.totalamount;  //抵扣完账单金额之后还剩的定金
            // bill.totalamount = 0;
            restMoney = tempMoney;
            bill.billdescription = bill.billdescription + `(${remarkName}抵扣)`;
            bill.billCollectionPaymentType = 3 //已收
            bill.billCollectionPaymentTypeVal = '已收' //已收
          }
        }
        //如果剩余定金为0 跳出循环
        if(restMoney == 0){
          break;
        }
      }
      let map = {
        bills:bills,
        restMoney:restMoney,
      }
      return map
    },



    //续租查押金
    queryRestDepositMoneyBill(rentercontract_id) {
      var me = this
      let queryData = {}
      queryData.rentercontract_id = rentercontract_id
      queryRestDepositMoneyBill(queryData).then(function(response) {
        responseUtil.dealResponse(me, response, () => {
          me.restDepositMoney = response.data.data.depositMoney   // 已交的押金
          me.goodsPriceMoney=response.data.data.goodsPriceMoney   //已交的物品押金
          me.cleanMoney=response.data.data.cleanMoney             //已交的保洁费
          me.detailList = response.data.data.detailList
        })
      })
    },

        //修改查押金
    queryRestDepositMoneyBillNew(rentercontract_id){
            var me = this;
            let queryData = {}
            queryData.rentercontract_id = rentercontract_id
            queryRestDepositMoneyBillNew(queryData).then(function (response) {
                responseUtil.dealResponse(me, response, () => {
                    me.restDepositMoney = response.data.data.depositMoney
                    me.goodsPriceMoney=response.data.data.goodsPriceMoney
                    me.cleanMoney=response.data.data.cleanMoney
                    me.sumAll = response.data.data.sumAll   //所有已交押金的总和
                    me.detailInfo.remainWaterMoney = Math.abs(response.data.data.waterMoney)
                    me.detailList = response.data.data.detailList
                })
            })
    },
    //判断租客信息按钮颜色
    canRenterActive() {
      let that = this
      if (
        that.ownerInfo.phone &&
        that.ownerInfo.name &&
        that.ownerInfo.sex &&
        that.ownerInfo.cardNumber &&
        that.ownerInfo.emergencyContactName &&
        that.ownerInfo.emergencyContactPhone &&
        (!that.ownerInfo.liveTogether ||
          (that.ownerInfo.liveTogether &&
            that.cohabitingPersonInfo.name &&
            that.cohabitingPersonInfo.phone &&
            that.cohabitingPersonInfo.sex &&
            that.cohabitingPersonInfo.cardNumber))
      ) {
        return true
      }
      return false
    },
    //判断合同信息按钮颜色
    canContractActive() {
      let that = this
      let other = that.canOtherBillsActive()
      if (
        that.contractInfo.paymentMethod &&
        that.contractInfo.rent &&
        that.contractInfo.deposit &&
        that.contractInfo.leaseTerm &&
        that.contractInfo.startPeriod &&
        that.contractInfo.endPeriod &&
        that.contractInfo.checkInDate &&
        that.contractInfo.signatory &&
        (!that.contractInfo.partner ||
          (that.contractInfo.partner &&
            that.contractInfo.partnershipProportion)) &&
        other
      ) {
        return true
      }
      return false
    },
    //判断其他费用信息是否填写完善
    canOtherBillsActive() {
      let that = this
      if (that.contractInfo.otherExpensesList.length == 0) {
        return true
      } else {
        for (let i = 0; i < that.contractInfo.otherExpensesList.length; i++) {
          if (that.contractInfo.otherExpensesList[i].costtype == '')
            return false
          if (that.contractInfo.otherExpensesList[i].payTypeId == '')
            return false
          if (!that.contractInfo.otherExpensesList[i].totalamount) return false
        }
        return true
      }
    },
    //为空判断和格式检验方法
    canRenterInfoSave() {
      let that = this
      //检测房间地址
      if (!that.houseAddress) {
        responseUtil.alertMsg(that, '请选择房间地址')
        return false
      }
      //检测租客电话号码
      if (that.ownerInfo.phone == '') {
        responseUtil.alertMsg(that, '请填写租客手机号')
        return false
      } else {
        let re = config.MOBILE_REG
        let str = that.ownerInfo.phone
        if (!re.test(str)) {
          responseUtil.alertMsg(that, '租客手机号格式不正确')
          return false
        }
      }
      //验证租客姓名
      if (!that.ownerInfo.name) {
        responseUtil.alertMsg(that, '请填写租客姓名')
        return false
      }
      // else {
      //     let re = config.NAME_REG
      //     let str = that.ownerInfo.name
      //     if (!re.test(str)) {
      //         Toast("租客姓名格式不正确")
      //         return false
      //     }
      // }
      //监听租客身份证号码
      if (!that.ownerInfo.cardNumber) {
        responseUtil.alertMsg(that, '请填写租客身份证')
        return false
      } else if (that.ownerInfo.card.value == '0') {
        let re = config.CERTIFICATE_REG
        let str = that.ownerInfo.cardNumber
        if (!re.test(str) && str != '') {
          responseUtil.alertMsg(that, '租客身份证格式不正确')
          return false
        }
      }
      //监听紧急联系人名字
      if (!that.ownerInfo.emergencyContactName) {
        responseUtil.alertMsg(that, '请填写紧急联系人姓名')
        return false
      }
      // else {
      //     let re = config.NAME_REG
      //     let str = that.ownerInfo.emergencyContactName
      //     if (!re.test(str)) {
      //         Toast("紧急联系人姓名格式不正确")
      //         return false
      //     }
      // }
      //监听紧急联系人电话号
      if (!that.ownerInfo.emergencyContactPhone) {
        responseUtil.alertMsg(that, '请填写紧急联系人电话')
        return false
      } else {
        let re = config.MOBILE_REG
        let str = that.ownerInfo.emergencyContactPhone
        if (!re.test(str)) {
          responseUtil.alertMsg(that, '紧急联系人电话格式不正确')
          return false
        }
      }
      //监听邮箱
      if (that.ownerInfo.emergencyContactEmail) {
        let re = config.EMAIL_REG
        let str = that.ownerInfo.emergencyContactEmail
        if (!re.test(str)) {
          responseUtil.alertMsg(that, '邮箱格式不正确')
          return false
        }
      }
      //监听同住人信息
      if (that.ownerInfo.liveTogether) {
        //监听姓名
        if (!that.cohabitingPersonInfo.name) {
          responseUtil.alertMsg(that, '请填写同住人姓名')
          return false
        }
        // else {
        //     let re = config.NAME_REG
        //     let str = that.cohabitingPersonInfo.name
        //     if (!re.test(str)) {
        //         Toast("同住人姓名格式不正确")
        //         return false
        //     }
        // }
        //监听手机号
        if (!that.cohabitingPersonInfo.phone) {
          responseUtil.alertMsg(that, '请填写同住人手机')
          return false
        } else {
          let re = config.MOBILE_REG
          let str = that.cohabitingPersonInfo.phone
          if (!re.test(str)) {
            responseUtil.alertMsg(that, '同住人手机格式不正确')
            return false
          }
        }
        //监听身份证
        if (!that.cohabitingPersonInfo.cardNumber) {
          responseUtil.alertMsg(that, '请填写同住人身份证')
          return false
        } else if (that.cohabitingPersonInfo.card.value == '0') {
          let re = config.CERTIFICATE_REG
          let str = that.cohabitingPersonInfo.cardNumber
          if (!re.test(str)) {
            responseUtil.alertMsg(that, '同住人身份证格式不正确')
            return false
          }
        }
      }
      //合同付款方式
      if (!that.contractInfo.paymentMethod) {
        responseUtil.alertMsg(that, '请选择合同付款方式')
        return false
      }
      //租金
      if (!that.contractInfo.rent) {
        responseUtil.alertMsg(that, '请输入租金')
        return false
      }
      //押金
      if (!that.contractInfo.deposit) {
        responseUtil.alertMsg(that, '请输入押金')
        return false
      }
      //租期
      if (!that.contractInfo.leaseTerm) {
        responseUtil.alertMsg(that, '请输入租期')
        return false
      }
      //合同开始期限
      if (!that.contractInfo.startPeriod) {
        responseUtil.alertMsg(that, '请选择合同开始期限')
        return false
      }
      //合同结束期限
      if (!that.contractInfo.endPeriod) {
        responseUtil.alertMsg(that, '请选择合同结束期限')
        return false
      }
      //入住时间
      if (!that.contractInfo.checkInDate) {
        responseUtil.alertMsg(that, '请选择入住时间')
        return false
      }
      //签约人
      if (!that.contractInfo.signatory) {
        responseUtil.alertMsg(that, '请选择签约人')
        return false
      }
      //合作人
      if (that.contractInfo.partner) {
        if (!that.contractInfo.partnershipProportion) {
          responseUtil.alertMsg(that, '请选择合作占比')
          return false
        }
      }

      //验证其他费用
      if (that.contractInfo.otherExpensesList.length > 0) {
        for (let i in that.contractInfo.otherExpensesList) {
          if (that.contractInfo.otherExpensesList[i].costtype == '') {
            responseUtil.alertMsg(that, '请选择其他费用费用类型')
            return false
          }
          if (that.contractInfo.otherExpensesList[i].payTypeId == '') {
            responseUtil.alertMsg(that, '请选择其他费用支付方式')
            return false
          }
          if (!that.contractInfo.otherExpensesList[i].totalamount) {
            responseUtil.alertMsg(that, '请输入其他费用金额')
            return false
          }
        }
      }
      return true
    },
    //验证时间的可行性
    checkTimeCanSave() {
      let that = this
      //入住时间不能小于合同开始时间
      let entryTime = new Date(
        that.commonDateFormate(that.contractInfo.checkInDate)
      ).getTime()
      let ownerEndTime = new Date(
        that.commonDateFormate(that.contractInfo.cutOffDate)
      ).getTime()
      let renterStartTime = new Date(
        that.commonDateFormate(that.contractInfo.startPeriod)
      ).getTime()
      let renterEndTime = new Date(
        that.commonDateFormate(that.contractInfo.endPeriod)
      ).getTime()
      /*if (entryTime < renterStartTime&&that.flagType!=1) {
        responseUtil.alertMsg(that, '入住时间不能小于合同开始时间')
        return false
      }*/
      // console.log(this.rejectEdit)  //驳回修改
      if (!this.rejectEdit && renterEndTime > ownerEndTime) {
        responseUtil.alertMsg(that, '合同结束时间不能大于业主合同截止时间')
        return false
      }
      return true
    },
    //续签
    reNew(param) {
      let that = this
      that.terminateInfoParam = param.terminateInfoParam
      let queryData = {}
      queryData.renterContractId = that.contractId
      queryData.globaluser_id = getStaffId()
      renterContractInfo(queryData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          var detailInfo = response.data.data.data
          let changeInfoParam = {
            rentercontract_id: that.contractId,
          }
          if (that.leaseType == '续签'){
            detailInfo.partnerRatio_id = ''
          }

          //变更类型(0 新签 1 续签 2 退房子 3 违约退房4 转租 5 换房)
          changeInfoParam.changeType = 1
          detailInfo.changeInfoParam = changeInfoParam
          detailInfo.oldContractCode = detailInfo.contractCode
          detailInfo.flagType = 2

          that.show(detailInfo)
        })
      })
    },

    //查询租客合同信息
    findRenterContractInfo(type) {
      let that = this
      let initData = {}
      initData.renterContractId = that.contractId
      initData.globaluser_id = getStaffId()
      initData.refund = '1'
      initData.isNew = '1'
      renterContractInfo(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          if (type == 'amend') {
            let detailInfo = response.data.data.data
            detailInfo.flagType = 1 //1代表合同修改
            that.contractInfo.number = detailInfo.contractCode
          if(detailInfo.payType_id==6){
             that.advancedPaymentDays=that.advancedPaymentDaysList[0].dictionaryValue
          }
            that.show(detailInfo)
          } else if (type == 'changeRoom') {
            let detailInfo = response.data.data.data
            if (detailInfo.returnSum) {
              that.returnSum = detailInfo.returnSum
            } else {
              // 剩余租金
              let remainRentMoney,
                surplusDepositMoney,
                remainWaterMoney,
                rentMoney
              remainRentMoney = detailInfo.restRentMoney
                ? detailInfo.restRentMoney
                : 0 //剩余租金
              surplusDepositMoney = detailInfo.surplusRent
                ? detailInfo.surplusRent
                : 0 //剩余押金
              remainWaterMoney = detailInfo.restWaterElectric
                ? detailInfo.restWaterElectric
                : 0 //剩余水电费
              rentMoney = detailInfo.rentMoney ? detailInfo.rentMoney : 0
              if (detailInfo.refundInfo) {
                remainRentMoney = detailInfo.refundInfo.surplusMoney
                  ? detailInfo.refundInfo.surplusMoney
                  : 0 //剩余租金
                surplusDepositMoney = detailInfo.refundInfo.depositMoney
                  ? detailInfo.refundInfo.depositMoney
                  : 0 //剩余押金
              }
              that.returnSum =
                Number(remainRentMoney) - Number(surplusDepositMoney)
            }
          }
          //that.countNewRent(response.data.data.data)
        })
      })
    },
    //字典项获取租期列表
    getRenterData(){
      let that=this
      let initData={}
      queryMonth(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.renterList=response.data.data.data
          that.renterList.push({dictionaryValue:'0',id:685})
        })
      })
    },
    getPolicyDecisionData(param){
            var me = this;
            let initData = {}
            initData.pdName ='isotherExpenses,isInAdvance15,isChangeLeaseTerm,halfPriceRental,halfPriceButPlus50,80PercentPrice,isOpenRenewal'
            queryPolicyDecision(initData).then(function (response) {
                me.decision=response.data.data.isotherExpenses
                if(me.decision=='true'){
                  me.editOtherFees=true
                }else{
                  me.editOtherFees=false
                }
                if(me.$route.query.type == '1'||me.$route.query.type == '2'){  //房源，预定合同
                  me.selectRoomChange(me.roomId)
                }

                me.isInAdvance15 = response.data.data.isInAdvance15   //是否再提前15天的决策值是否开
                if(me.isInAdvance15=='true'){
                  me.editIsInAdvance15=true
                  me.isInAdvance15Show = true
                }else{
                  me.editIsInAdvance15=false
                  me.isInAdvance15Show = false
                  //如果决策关了，修改时再提前15天为0且不显示
                  me.furtherAdvancedDays=0
                }

                me.isChangeLeaseTerm = response.data.data.isChangeLeaseTerm
                me.halfPriceRentalId = response.data.data.halfPriceRental
                me.halfPriceButPlus50Id = response.data.data.halfPriceButPlus50
                me.eightyPercentPriceId = response.data.data['80PercentPrice']
                me.isOpenRenewal = response.data.data.isOpenRenewal
                me.show2(param)
            })
    },
        //是否减免最后一个月租金
        countReduceRent(){
            if(!this.ownerInfo.phone||!this.ownerInfo.certificateState||!this.ownerInfo.card.value){
                return
            }
            let that=this
            let initData={}
            initData.mobile=this.ownerInfo.phone
            initData.certificateType=this.ownerInfo.card.value
            initData.certificateCode=this.ownerInfo.certificateState
            queryContractfalsifydiscount(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    if(response.data.data){
                        // that.reduceLastRent='是'
                        if(response.data.data.data.length==0){
                            that.reduceLastRent='否'
                        }else{
                            that.reduceLastRent='是'
                        }
                    }
                })
            })
        },
    //享受老带新优惠活动
    enjoyOldTakeNew(){
            if(Number(this.contractInfo.leaseTerm)<12||!this.ownerInfo.phone||(this.ownerInfo.cardNumber && this.ownerInfo.cardNumber.length!=18)||!this.contractInfo.recommend.id){
               for(let i in this.discountsList){
                  if('1'==this.discountsList[i]){
                    this.discountsList.splice(i,1)
                    break
                  }
               }
               return
            }
            let that=this
            let initData={}
            initData.mobile=that.ownerInfo.phone
            initData.certificateCode=that.ownerInfo.cardNumber
            rentHouseNewTenant(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    if(response.data.data.agecompare=='1'&&response.data.data.newRentHouse=='1'){
                       let state=false
                       for(let i in that.contractInfo.discounts){
                         if('1'==that.contractInfo.discounts[i]){
                            state=true
                            break;
                         }
                       }
                       if(!state){
                        that.contractInfo.discounts.push('1')
                        that.preferentialContractChange()
                       }
                    }
                })
              })
    },
    //修改或者租约变更数据处理的方法
    show(param){
      if(this.$route.query.type=='2'){
        this.loadingFlag=true
      }
      this.getPolicyDecisionData(param)
    },
    show2: function(param) {
      console.log(param)
      let that = this
      if(!param){
         return
      }
      if(param && param.isWhetherKitchen){
        that.isWhetherKitchen=1
      }
      that.contractInfo.quarterlyDiscount=0
      that.earnestMoney = ''
      if(param && param.earnestMoney){//定金
        that.earnestMoney = param.earnestMoney
      }
      that.detailInfo=param
      // that.sumAll = Number(Math.abs(that.restDepositMoney))+Number(Math.abs(that.goodsPriceMoney))+Number(Math.abs(that.cleanMoney))+Number(Math.abs(that.detailInfo.remainWaterMoney))
      let sumSive = Number(Math.abs(that.restDepositMoney))+Number(Math.abs(that.goodsPriceMoney))+Number(Math.abs(that.cleanMoney))+Number(that.detailInfo.remainWaterMoney)
      that.sumAll = that.detailInfo.remainRentMoney?Number(util.NumberAdd(sumSive,Number(that.detailInfo.remainRentMoney))):sumSive
      // that.detailList = that.detailInfo.detailList
      if(Number(that.detailInfo.remainRentMoney)>0){
        that.detailList.push({totalAmount:Number(that.detailInfo.remainRentMoney),billFeeType_id:8})
      }
      if(Number(that.detailInfo.remainWaterMoney)>0){
        that.detailList.push({totalAmount:Number(that.detailInfo.remainWaterMoney),billFeeType_id:4})
      }
      if (that.alertType=='修改'&&param.otherDays) {//额外时间
                that.contractInfo.otherDays = param.otherDays;
      }
      if(that.alertType=='修改'){
          that.fromChange=true
          that.alertPage={mobile:true,tenancy:true,referrer:true}
          that.contractInfo.type=that.detailInfo.chhtype
      }
      if(that.alertType=='修改'&&param.contractHistoryHistoryType==1){
      this.queryRestDepositMoneyBillNew(param.renterContractId)
      }
      if(param.advancePaymentDays&&that.flagType!='2'){
         that.advancedPaymentDays=param.advancePaymentDays
      }
      /*if(param.payType_id==6){
         that.showAdvancedPaymentDaysSelect=true
      }*/
      if (param.terminateInfoParam != undefined) {
        that.terminateInfoParam = param.terminateInfoParam //续租、转租、换房时，在退租页面保存的数据
      }
      if (param.userCoupon_id && param.recommendUser_id) {
        for (var i = 0; i < param.userCoupon_id.length; i++) {
          //有推荐
          if (param.userCoupon_id[i] == '1' && param.recommendUser_id) {
            this.TimeProcessing = false
          }
        }
      }
      if (param.entranceType == 'user') {
        that.entranceType = param.entranceType
      } else if (param.entranceType == 'house') {
        that.ownerInfo.roomState = true
        that.entranceType = param.entranceType
      } else if (param.entranceType == 'reserve') {
        that.ownerInfo.roomState = true
        that.entranceType = param.entranceType
        that.setOwnerState(true)
      }
      if (param && param.potentialResource_id) {
        that.id = param.potentialResource_id
      }
      if (param && param.changeInfoParam) {
        if (param.changeInfoParam.changeType == 0) {
          that.lastContractCode = ''
        } else {
          let typeMap = {
            '0': '新签',
            '1': '续签',
            '2': '退房子',
            '3': '违约退房',
            '4': '转租',
            '5': '换房',
          }
          that.contractInfo.contractHistoryHistoryType =
            typeMap[param.changeInfoParam.changeType]
          that.lastContractCode = param.oldContractCode
        }
      } else {
        that.lastContractCode = ''
      }
      //1编辑,2续签
      if (param && param.flagType) {
        that.flagType = param.flagType
      }
      if (param && param.renterContractId) {
        that.contractId = param.renterContractId
      }
      let queryData = {}
      //如果是修改合同则不创建新的合同编号
      if (that.flagType != 1) {
        queryData.type = 1
        createContractCode(queryData).then(function(response) {
          responseUtil.dealResponse(that, response, () => {
            that.contractInfo.number = response.data.data.contractCode
          })
        })
      }
      let queryDataSecond = {}
      queryDataSecond.staff_id = getStaffId()
      if (param && param.roomId) {
        that.roomId = parseInt(param.roomId)
        if (that.flagType != 1) {
          that.selectRoomChange(param.roomId)
        }
      }
      if (param && param.renterMobile) {
        that.ownerInfo.phone = param.renterMobile
        that.changeFlag=true
        that.findUserInfoByMobile(!(param.contractStatus != '5' && that.alertType=='修改'), that.ownerInfo.phone)
      }
      if (param && param.roomHouse_id) {
        that.roomId = param.roomHouse_id
        //if (that.flagType != 1) {
          that.selectRoomChange(param.roomHouse_id)
        //} else if (that.flagType == 1) {
          that.getCouponList(param.roomHouse_id)
        //}
      }
      if (param && param.hasCohabit == 'true') {
        that.ownerInfo.liveTogether = true
      } else {
        that.ownerInfo.liveTogether = false
      }
      if (param && param.emergencyContactPerson) {
        that.ownerInfo.emergencyContactName = param.emergencyContactPerson
      }
      if (param && param.emergencyContactPhone) {
        that.ownerInfo.emergencyContactPhone = param.emergencyContactPhone
      }
      if (param && param.emergencyContactAddress) {
        that.ownerInfo.emergencyContactAddress = param.emergencyContactAddress
      }
      if (param && param.emergencyContactEmail) {
        that.ownerInfo.emergencyContactEmail = param.emergencyContactEmail
      }
      if (param && param.renterCertificateType) {
        that.ownerInfo.card.value = param.renterCertificateType
        that.ownerInfo.card.label = that.getItemTitle(
          that.cardArr,
          param.renterCertificateType
        )
      }
      if (param && param.renterCertificateCode) {
        that.ownerInfo.cardNumber = param.renterCertificateCode
      }
      if (param && param.renterName) {
        that.ownerInfo.name = param.renterName
      }
      if (param && param.renterGender) {
        that.ownerInfo.sex = param.renterGender
      }
      if (param && param.renterGender) {
        that.ownerInfo.renterGender = param.renterGender
      }
      if (param && param.houseAddress) {
        that.houseAddress = param.houseAddress
      }
      if (param && param.photo) {
        let photo = param.photo
        if (photo.lesseeOrOwnerIdFront) {
          that.ownerInfo.CertificateFront = photo.lesseeOrOwnerIdFront
        }
        if (photo.lesseeOrOwnerIdBack) {
          that.ownerInfo.CertificateBack = photo.lesseeOrOwnerIdBack
        }
        if (photo.lesseeOrOwnerIdHand) {
          that.ownerInfo.CertificateHandHeld = photo.lesseeOrOwnerIdHand
        }
        if (photo.togetherOrEntrustIdFront) {
          that.cohabitingPersonInfo.CertificateFront =
            photo.togetherOrEntrustIdFront
        }
        if (photo.togetherOrEntrustIdBack) {
          that.cohabitingPersonInfo.CertificateBack =
            photo.togetherOrEntrustIdBack
        }
        if (photo.togetherOrAccessories1) {
          //合同附件  现在合同附件好像是在线下合同列表里取的，现在UI窗口没有这个位置
          that.contractInfo.appendicesContract = photo.togetherOrAccessories1
        }
      }
      if (param && param.frontAccPath) {
        that.ownerInfo.frontOfCard = param.frontAccPath
      }
      if (param && param.reverseAccPath) {
        that.ownerInfo.reverseOfCard = param.reverseAccPath
      }
      if (param && param.holdingAccPath) {
        that.ownerInfo.holdingCard = param.holdingAccPath
      }
      if (param && param.cohabitName) {
        that.cohabitingPersonInfo.name = param.cohabitName
      }
      if (param && param.cohabitSex) {
        that.cohabitingPersonInfo.sex = param.cohabitSex
      }
      if (param && param.cohabitMovle) {
        that.cohabitingPersonInfo.phone = param.cohabitMovle
      }
      if (param && param.cohabitCertificateCode) {
        that.cohabitingPersonInfo.cardNumber = param.cohabitCertificateCode
      }
      if (param && param.cohabitCertificateType) {
        that.cohabitingPersonInfo.card.value = param.cohabitCertificateType
        that.cohabitingPersonInfo.card.label = that.getItemTitle(
          that.cardArr,
          param.cohabitCertificateType
        )
      }
      if (param && param.payType_id&&that.flagType!=2) {
        that.payMethodId = param.payType_id + ''
        //改变付款下拉选的内容  getArrValuesByIndex
        that.contractInfo.paymentMethod = that.getArrDictionaryValueByIndex(
          that.paymentMethodArr,
          param.payType_id
        )
      } else {
        if (that.paymentMethodArr.length > 0) {
          that.payMethodId = that.paymentMethodArr[0].value + ''
        }
      }
      if(param && param.payType_id&& param.payType_id=='6'){
        that.showAdvancedPaymentDaysSelect=true
      }
      if (param && param.rentMoney) {
        that.contractInfo.rent = param.rentMoney
      }
      if (param && param.percentage) {
        that.oldpercentage = param.percentage
      }
      if (param && param.rentTimeLong!=undefined) {
        that.contractInfo.leaseTerm = param.rentTimeLong+''

        // if(that.isChangeLeaseTerm=='true'){   //可以修改且房子是业主截止
        // }
      // else{  //不能修改，获取最大期限
      //     that.computeTenancyTerm()   //计算租期
      //   }
        if(Number(that.contractInfo.leaseTerm)<12){
          that.editTenancyTerm=true
        }
      }
      if (param && param.depositMoney) {
        that.contractInfo.deposit = param.depositMoney
      }
      if (param && param.depositMoneyNum) {
        that.contractInfo.depositMoneyNum = param.depositMoneyNum
      }
      if (param && param.roomHouse_id) {
        that.querygOwnerContractEndDate(param.roomHouse_id)
        // that.contractInfo.cutOffDate = param.ownerContractEndTime;
      }
      if (param && param.contractBeginTime) {
        //判断是否为续签，续签的话 默认开始时间为上次合同的结束时间+1
        if (that.flagType == 2) {
          // if(detailInfo.extendedLeaseEndTime&&that.historyType=='续签'){
          //   that.relieveDate=util.dateFormat(detailInfo.extendedLeaseEndTime)
          // }
          if(param.extendedLeaseEndTime && param.extendedLeaseEndTime!=''){
            that.contractInfo.startPeriod = that.getNewDataDays(
                param.extendedLeaseEndTime,
                1
            )
          }else{
            that.contractInfo.startPeriod = that.getNewDataDays(
                param.contractEndTime,
                1
            )
          }

          //开始时间只能往后不能往前,得加判断
        } else {
          that.contractInfo.startPeriod = param.contractBeginTime
        }
      }

      if (param && param.contractEndTime) {
        if (that.flagType == 2) {
          //结束时间为开始时间加上租期
          let contractEndTime = new Date(
            that.commonDateFormate(that.contractInfo.startPeriod)
          )
          contractEndTime.setMonth(
            contractEndTime.getMonth() + parseInt(that.contractInfo.leaseTerm)
          )
          contractEndTime = util.dateAddDay(contractEndTime, -1)
          //转换 格式化成 YYYY-MM-DD 用于页面显示
          that.contractInfo.endPeriod = that.formatDays(contractEndTime)
        } else {
          that.contractInfo.endPeriod = param.contractEndTime
        }
      }

      if (param && param.entryTime) {
        if (that.flagType == 2) {
          //默认入住时间为合同开始时间
          that.contractInfo.checkInDate = that.contractInfo.startPeriod
        } else {
          that.contractInfo.checkInDate = param.entryTime
        }
      }
      if(that.flagType!=2&&that.flagType!=3&&that.flagType!=4){
      if (param && param.labelIds && param.labelIds.length > 0) {
        let labelIds = param.labelIds.split(',')
        for (let i = 0; i < labelIds.length; i++) {
          for (let j = 0; j < that.contractLabel.length; j++) {
            if (that.contractLabel[j].id == labelIds[i]) {
              that.$set(that.contractLabel[j], 'isChoose', true)
              break
            }
          }
        }
      }
      }
      if (param && param.hasAddtionService) {
        that.contractInfo.supportingService = param.hasAddtionService
      }
      if (
        param &&
        param.outLinecontratPicList &&
        param.outLinecontratPicList.length > 0
      ) {
        let list = param.outLinecontratPicList
        if(that.alertType==1||that.alertType==3||that.alertType==4||that.alertType==6){
          that.contractInfo.attachmentList=[]
        }else{
         for (let i in list) {
          that.contractInfo.attachmentList.push({
            url: list[i].path,
            path: list[i].path,
          })
         }
        }
        //that.contractInfo.attachmentList = param.outLinecontratPicList
      }
      if(that.flagType!=2&&that.flagType!=3&&that.flagType!=4){
        if (param && param.supplementaryRemark) {
          that.contractInfo.explanationDescribe = param.supplementaryRemark
        }
      }
      if (!(this.$route.query.contractId && this.flagType == '2')) {
        if (param && param.userCoupon_id) {
          //优惠活动的id
          that.contractInfo.discounts = JSON.parse(JSON.stringify(param.userCoupon_id));
          that.lastPreferentialList = JSON.parse(JSON.stringify(param.userCoupon_id))
          // for(let i in that.contractInfo.discounts){
          //   if(that.contractInfo.discounts[i] == that.halfPriceButPlus50Id){
          //     that.oldrentMoney = param.offerPrice
          //     that.oldDespositMoney = that.oldrentMoney
          //   }
          // }
          //that.quarterCard()
        }
      }
      /*if (this.$route.query.contractId && this.flagType == '2') {
        let tempDiscounts = param.userCoupon_id
        // let tempDiscounts = ['1','2'];
        for (let i = 0; i < tempDiscounts.length; i++) {
          if (tempDiscounts[i] == 1) {
            tempDiscounts.splice(i, 1)
          }
        }
        that.contractInfo.discounts = tempDiscounts
      }*/

      if (param && param.partnerRatio_id) {
        //合作占比id
        that.contractInfo.partnershipProportion = that.getArrValuesByIndex(
          that.partnershipProportionArr,
          param.partnerRatio_id
        )
      }
      if (param && param.otherFees) {
        for (let i = 0; i < param.otherFees.length; i++) {
          param.otherFees[i].payTypeId = param.otherFees[i].payTypeId.toString()
        }
        that.contractInfo.otherExpensesList = param.otherFees
      }
      if (that.terminateInfoParam.length > 0) {
        //changeType:2续租 3转租 4换房
        if (that.terminateInfoParam[0].changeType == 2) {
          that.roomId = param.roomHouse_id
        } else if (that.terminateInfoParam[0].changeType == 3) {
          that.roomId = param.roomId
          that.ownerInfo.phone = param.newRenterMobile
          that.contractInfo.startPeriod = that.changeLeaseInfo[0].changeLeaseTime//param.subletDate_DateType
          that.contractInfo.checkInDate=that.contractInfo.startPeriod
        } else if (that.terminateInfoParam[0].changeType == 4) {  //<----------------------换房
          that.roomId = param.roomId
          that.contractInfo.startPeriod = util.dateFormat(util.dateAddDay(param.subletDate_DateType, 0))//param.subletDate_DateType
          that.contractInfo.checkInDate=that.contractInfo.startPeriod
        }else if (that.terminateInfoParam[0].changeType == 6) {
          that.roomId = param.roomId
          that.contractInfo.startPeriod = that.changeLeaseInfo[0].changeLeaseTime
          that.contractInfo.checkInDate=that.contractInfo.startPeriod
        }
        that.ownerInfo.roomState = true
        that.ownerInfo.mobileState = true
      }
      //默认签约人为当前登录用户
      //设置签约人信息
      if (param) {
        that.showParam = param
        if (that.showParam.flagType && that.showParam.flagType == 1) {
          that.ownerInfo.roomState = true
          that.ownerInfo.mobileState = true
          that.ownerInfo.sexState = true
          that.ownerInfo.certificateState = true
          that.ownerInfo.nameState = true
        }
        if (that.showParam.flagType && that.showParam.flagType == 2) {
          that.contractInfo.type = '续签'
        } else if (that.showParam && that.showParam.changeInfoParam) {
          let changeInfoParam = that.showParam.changeInfoParam
          ////变更类型(0 新签 1 续签 2 退房子 3 违约退房4 转租 5 换房)
          if (changeInfoParam.flagType) {
            if (changeInfoParam.flagType == 2) {
              that.contractInfo.type = '退租'
            } else if (changeInfoParam.flagType == 3) {
              that.contractInfo.type = '退租'
            } else if (changeInfoParam.flagType == 4) {
              that.contractInfo.type = '转租'
            } else if (changeInfoParam.flagType == 5) {
              that.contractInfo.type = '换房'
            }
          } else {
            that.showParam = ''
          }
        } else {
          that.showParam = ''
        }
      } else {
        that.showParam = ''
      }
      setTimeout(() => {
        if (param && param.signStaff_id&&param.flagType!=2) {
          //签约人id
          that.contractInfo.signatory = that.getArrValuesByIndex(
            that.signatoryArr,
            param.signStaff_id
          )
          if (that.contractInfo.signatory != '') {
            that.contractInfo.signatory.id = param.signStaff_id
            let departmentList = param.signDepartmentList
            if (departmentList.length > 2) {
              for (let i = 2; i < departmentList.length; i++) {
                that.contractInfo.signDepartment.label +=
                    departmentList[i].departmentName + ' '
                that.contractInfo.signDepartment.id = departmentList[i].id
              }
            } else {
              param.signDepartmentList.forEach(item => {
                that.contractInfo.signDepartment.label +=
                    item.departmentName + ' '
                that.contractInfo.signDepartment.id = item.id
              })
            }
          } else {
            that.contractInfo.signDepartment.id = ''
            that.contractInfo.signDepartment.label = ''
          }
        }
        //驳回修改有合作人
        if(param && param.partnerStaff_id&&this.rejectEdit){
          this.contractInfo.partner={}
          that.contractInfo.partner.id = param.partnerStaff_id
          that.contractInfo.partner.title = param.parUserName
          that.contractInfo.partnerDepartment.label = ''
          let departmentList = param.partnerDepartmentList
          if (departmentList.length > 2) {
            for (let i = 2; i < departmentList.length; i++) {
              that.contractInfo.partnerDepartment.label +=
                  departmentList[i].departmentName + ' '
              that.contractInfo.partnerDepartment.id = departmentList[i].id
            }
          } else {
            param.partnerDepartmentList.forEach(item => {
              that.contractInfo.partnerDepartment.label +=
                  item.departmentName + ' '
              that.contractInfo.partnerDepartment.id = item.id
            })
          }
        }else{
          if (param && param.partnerStaff_id&&that.flagType!=2&&that.flagType!=4) {
            //合作人id
            that.contractInfo.partner = that.getArrValuesByIndex(
                that.partnerArr,
                param.partnerStaff_id
            )
          }
        }

        if (!(this.$route.query.contractId && this.flagType == '2')) {
          if (param && param.recommendUserName) {
            //推荐人名字
            that.contractInfo.recommend.label =
              param.recommendUserName +
              ' ' +
              that.getTel(param.recommendUserMobile)
            that.contractInfo.recommend.id = param.recommendUser_id
            that.contractInfo.recommend.userName = param.recommendUserName
            that.contractInfo.recommend.mobile = param.recommendUserMobile
            // that.contractInfo.recommend = that.getRecommendInfo(param.recommendUserName);
          }
        }
      }, 500)
    },

    //优惠活动改变
    preferentialContractChange: function() {
      if(this.fromChange){
        this.fromChange=false
          return
      }

      //先比对操作前后所选优惠券区别
      if(this.alertType=='修改' && this.lastPreferentialList.indexOf(this.halfPriceButPlus50Id)>-1 && this.contractInfo.discounts.indexOf(this.halfPriceButPlus50Id)==-1
          && (this.contractInfo.paymentMethod.dictionaryValue==6||this.contractInfo.paymentMethod.dictionaryValue==1)&&this.contractInfo.leaseTerm==12
          && (this.contractInfo.type=='新签'||this.contractInfo.type=='换房')){
        this.contractInfo.rent = this.offerPrice
        this.contractInfo.deposit = this.contractInfo.rent
      }
      // if(this.alertType=='修改' &&(this.contractInfo.type=='新签'||this.contractInfo.type=='换房') && this.lastPreferentialList.indexOf(this.halfPriceButPlus50Id)>-1 && this.contractInfo.discounts.indexOf(this.halfPriceButPlus50Id)>=-1){
      //   if(this.contractInfo.paymentMethod==6||this.contractInfo.paymentMethod==1){
      //     this.contractInfo.rent = this.detailInfo.rentMoney
      //     this.contractInfo.deposit = this.contractInfo.rent
      //   }else{
      //     this.contractInfo.rent = this.offerPrice
      //     this.contractInfo.deposit = this.contractInfo.rent
      //   }
      //
      // }
      this.lastPreferentialList = JSON.parse(JSON.stringify(this.contractInfo.discounts))
      if (this.contractInfo.discounts && this.contractInfo.discounts != []) {
        // this.TimeProcessing=false;
        for (var i = 0; i < this.contractInfo.discounts.length; i++) {
          //有推荐
          if (
            this.isExistSignDiscount(this.contractInfo.discounts[i]) &&
            this.contractInfo.recommend.id
          ) {
            this.contractInfo.otherDays = Number(
              this.ownerInfo.defaultOtherDays
            )
            //结束时间加天数
            // TimeProcessing 为true 代表租期没有加10天  为false代表已经加了10天
            if (this.TimeProcessing) {
              this.contractInfo.endPeriod = util.dateFormat(
                util.dateAddDay(
                  this.contractInfo.endPeriod,
                  this.contractInfo.otherDays
                )
              )
              this.TimeProcessing = false
            }
            break
          }
        }
      }
      if (
        !this.isExistSignDiscount(this.contractInfo.discounts[i]) &&
        this.contractInfo.recommend.id &&
        !this.TimeProcessing
      ) {
        this.contractInfo.otherDays = Number(this.ownerInfo.defaultOtherDays)
        this.contractInfo.endPeriod = util.dateFormat(
          util.dateAddDay(
            this.contractInfo.endPeriod,
            -this.contractInfo.otherDays
          )
        )
        this.contractInfo.otherDays = 0
        this.TimeProcessing = true
      }
      if(this.alertPage.referrer){
        this.alertPage.referrer=false
      }else{
        this.enjoyOldTakeNew()
      }
      // this.quarterCard()
      this.reduceFirstMonth()
    },
    //计算优惠
    reduceFirstMonth(){
      this.contractInfo.quarterlyDiscount = 0
      if(this.alertType!='修改' && this.alertType!='管理修改') {
        this.countNewRent()
      }
      let state=false
      this.halfPriceRental=0
      for(let i in this.discountsList){
        for(let j in this.contractInfo.discounts){   //已选优惠券
          if(this.discountsList[i].value==this.contractInfo.discounts[j]){   //如果选的和备选的id一样
            this.getCouponDetail(this.discountsList[i].value)  //查对应优惠券配置详情


            // //1.沈阳15+15优惠
            // // 首先验证选的当前id是决策里配置的沈阳优惠券的id  是第一期减免
            // if(this.contractInfo.discounts[j]==this.halfPriceRentalId) {
            //   // 条件：沈阳的房子 且是合同押一付一  且提前15天再提前15天  且租期为12个月
            //   if(this.city_id=='16'  &&this.contractInfo.paymentMethod.dictionaryValue==6
            //       &&this.advancedPaymentDays=='15'&& this.furtherAdvancedDays=='15'&&this.contractInfo.leaseTerm==12){
            //     this.contractInfo.quarterlyDiscount = this.discountsList[i].discount  //优惠比例
            //     this.contractInfo.couponType = this.discountsList[i].couponType  //付款方式
            //     if(this.alertType!='修改' && this.alertType!='管理修改') {
            //       this.countNewRent()
            //     }
            //     // this.contractInfo.rent = this.oldrentMoney!=''?this.oldrentMoney:this.contractInfo.rent
            //     // this.contractInfo.deposit = this.oldDespositMoney!=''?this.oldDespositMoney:this.contractInfo.deposit
            //     // this.oldrentMoney = ''
            //     // this.oldDespositMoney = ''
            //     this.halfPriceRental = 1
            //     state = 1
            //     break
            //   }
            // }
            //
            //
            // //2.首次出房价+50优惠
            // // 首先验证是+50优惠券的id
            // if(this.contractInfo.discounts[j]==this.halfPriceButPlus50Id){
            //   //条件：支付方式押一付一或季付，租期12个月，新签或换房
            //   if((this.contractInfo.paymentMethod.dictionaryValue==6||this.contractInfo.paymentMethod.dictionaryValue==1)&&this.contractInfo.leaseTerm==12
            //       &&(this.contractInfo.type=='新签'||this.contractInfo.type=='换房')){
            //     this.contractInfo.quarterlyDiscount = this.discountsList[i].discount  //优惠比例
            //     this.contractInfo.couponType = this.discountsList[i].couponType  //优惠方式
            //     // this.oldrentMoney = this.contractInfo.rent
            //     // this.oldDespositMoney = this.contractInfo.deposit
            //
            //     this.contractInfo.rent = this.firstHousePrice + 50
            //     this.contractInfo.deposit = this.firstHousePrice + 50
            //     this.percentage = 100
            //     // this.halfPriceRental='1'
            //     state = 2
            //     break
            //   }
            // }
            //
            // // 3.季度8折优惠
            // if(this.contractInfo.discounts[j]==this.eightyPercentPriceId){
            //   //支付方式季付，租期12个月，
            //   if(this.contractInfo.leaseTerm==12&&this.contractInfo.paymentMethod.dictionaryValue==1){
            //     this.contractInfo.quarterlyDiscount=this.discountsList[i].discount
            //     this.contractInfo.couponType=this.discountsList[i].couponType
            //     if(this.alertType!='修改' && this.alertType!='管理修改') {
            //       this.countNewRent()
            //     }
            //     // this.contractInfo.rent = this.oldrentMoney!=''?this.oldrentMoney:this.contractInfo.rent
            //     // this.contractInfo.deposit = this.oldDespositMoney!=''?this.oldDespositMoney:this.contractInfo.deposit
            //     // this.oldrentMoney = ''
            //     // this.oldDespositMoney = ''
            //     state=3
            //     break
            //   }
            // }

          }
        }
        // if(state==2){
        //   this.halfPriceButPlus50Choose = true  //选了优惠券，租期切回12个月时，不进计算
        //   break
        // }else {
        //   this.halfPriceButPlus50Choose = false  //
        // }
      }
      // if(!state){
      //   this.contractInfo.quarterlyDiscount=0
      //   // this.countNewRent()
      //   // this.contractInfo.rent = this.oldrentMoney!=''?this.oldrentMoney:this.contractInfo.rent
      //   // this.contractInfo.deposit = this.oldDespositMoney!=''?this.oldDespositMoney:this.contractInfo.deposit
      //   // this.oldrentMoney = ''
      //   // this.oldDespositMoney = ''
      //   if(this.alertType!='修改' && this.alertType!='管理修改') {
      //     this.countNewRent()
      //   }
      // }
    },
    getCouponDetail(coupon_id){
      let that = this
      let initData = {}
      initData.coupon_id = Number(coupon_id)
      getCouponNew(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          // console.log(response.data.data)
          that.discountsEnableReason = ''
          let couponDetailInfo = response.data.data
          if(couponDetailInfo.city_idList && couponDetailInfo.city_idList.findIndex(item => item == that.city_id)==-1){
            that.discountsEnableReason = '城市'
            return
          }
          if(couponDetailInfo.payTypeVal && couponDetailInfo.payTypeVal.findIndex(item => item == that.payMethodId)==-1){
            that.discountsEnableReason = '付款方式'
            return
          }
          if(couponDetailInfo.rentTimeVal && couponDetailInfo.rentTimeVal.findIndex(item => item == that.contractInfo.leaseTerm)==-1){
            that.discountsEnableReason = '租期'
            return
          }
          // console.log(that.contractInfo.houseType)
          if(couponDetailInfo.houseType && couponDetailInfo.houseType.findIndex(item => item == that.contractInfo.houseType)==-1){
            that.discountsEnableReason = '房源类型'
            return
          }
          let contractLabelList = that.getLables()
          if(couponDetailInfo.labelList){
            let count = 0
            for(let i in contractLabelList){
              if(couponDetailInfo.labelList.findIndex(item => item == contractLabelList[i])>-1){
                count++;
              }
            }
            if(count===0){
              that.discountsEnableReason = '标签'
              return
            }
          }
          if(coupon_id===that.halfPriceButPlus50Id&&(that.contractInfo.type=='新签'||that.contractInfo.type=='换房')){
            that.contractInfo.rent = that.firstHousePrice+50
            that.contractInfo.deposit = that.firstHousePrice+50
          }
          if(coupon_id===that.halfPriceButPlus50Id&&(that.contractInfo.type=='新签'||that.contractInfo.type=='换房')
              ||coupon_id!==that.halfPriceButPlus50Id){
            that.contractInfo.quarterlyDiscount=couponDetailInfo.discount
            that.contractInfo.couponType=couponDetailInfo.couponUseType
          }

          if(that.alertType!='修改' && that.alertType!='管理修改'&&coupon_id!==that.halfPriceButPlus50Id) {
            that.countNewRent()
          }

          //所有条件index>-1，满足使用，status返回true
          //有一个条件index==-1，不满足条件使用，status返回false
        })
      })
    },
    isExistSignDiscount(id) {
      let discountsList = this.discountsList
      for (let i = 0; i < discountsList.length; i++) {
        if (
          discountsList[i].value == id &&
          discountsList[i].receiveCondition == '4'
        ) {
          return true
        } else {
          return false
        }
      }
    },

    //点击租客电话选择租客
    skipChooseRenter() {
      if(this.flagType=='6'){
           return
      }
      if(this.ownerInfo.mobileState){
        return
      }
      if (this.perUserFlag) {
        return
      }
      let flagType
      if (this.paramType == 3 && this.flagType == 0) {
        //潜客详情进入
        flagType = 5
      } else if (this.paramType == '' && this.flagType == 2) {
        //租约变更续租
        flagType = 2
      } else if (this.paramType == '' && this.flagType == 3) {
        //租约变更转租
        flagType = 3
      } else if (this.paramType == '' && this.flagType == 4) {
        //租约变更换房
        flagType = 4
      } else if (this.paramType == 1 && this.flagType == 0) {
        //房源详情进入
        flagType = 6
      }
      this.$router.push({
        name: 'referrer',
        query: {
          flagType: flagType,
          type: 4,
        },
      })
    },

    //保存按钮
    save() {
      let that = this
      //验证按钮是否已激活
      //let canSave = that.canContractActive()&&that.canContractActive()
      for(let i in this.contractInfo.discounts){
          if(this.contractInfo.discounts[i]=='1'&&!this.contractInfo.recommend.label){
              responseUtil.alertMsg(this, '享受老带新活动需选择推荐人!')
              return
          }
      }
      let canSave = that.canRenterInfoSave()
      if (!canSave) return
      if(this.ownerInfo.card.value==0&&this.detailInfo.contractStatus!=6){
        let rentIdCard=this.ownerInfo.cardNumber.substring(6,10)+'-'+this.ownerInfo.cardNumber.substring(10,12)+'-'+this.ownerInfo.cardNumber.substring(12,14)
        let compareDate=util.dateAddYear(new Date(),-16)
        let compareEndDate=util.dateAddYear(new Date(),-55)
        if(new Date(rentIdCard).getTime()>compareDate.getTime()){
          responseUtil.alertMsg(this,"承租人年龄不满16周岁，不允许签约");
          return
        }else if(util.dateAddDay(rentIdCard,0).getTime()<compareEndDate.getTime()){
          responseUtil.alertMsg(this,"承租人年龄超过55周岁，不允许签约");
          return
        }
      }

      if ((new Date(util.dateToString(that.giveHouseTime)).getTime()) > new Date(util.dateToString(that.contractInfo.startPeriod)).getTime()) {
        responseUtil.alertMsg(that,'合同开始时间不能早于业主合同交房时间')
        return
      }
      if ((new Date(util.dateToString(that.giveHouseTime)).getTime()) > new Date(util.dateToString(that.contractInfo.startPeriod)).getTime()) {
        responseUtil.alertMsg(that,'合同开始时间不能早于业主合同交房时间')
        return
      }
      if(this.contractInfo.leaseTerm<3&&this.contractInfo.paymentMethod.dictionaryValue==1){
        responseUtil.alertMsg(this,'季付租期至少三个月！')
        return
      }
      if(new Date(this.contractInfo.endPeriod).getTime()<new Date(this.$route.query.roomChangeEndTime).getTime()
          && this.flagType == '3'
          && that.$route.query.conscientiousBreak
          && new Date(this.contractInfo.endPeriod).getTime()!=new Date(this.contractInfo.cutOffDate).getTime()
      ){
        responseUtil.alertMsg(this,'有责转租新合同结束日期必须大于等于旧合同结束日期')
        return
      }

      if(this.rejectEdit===false && this.contractInfo.discounts.length >0 && this.contractInfo.discounts.findIndex(item => item == this.halfPriceButPlus50Id)>-1 && that.contractInfo.quarterlyDiscount===0){  //选择了优惠券又没折扣
        responseUtil.alertMsg(this,`所选${this.discountsEnableReason}不符合首次出房+50优惠券`)
        return
      }
      if(this.rejectEdit===false && this.contractInfo.discounts.length >0 && this.contractInfo.discounts.findIndex(item => item == 16)==-1 && that.contractInfo.quarterlyDiscount===0){  //选择了优惠券又没折扣
        responseUtil.alertMsg(this,'签约条件不符合所选优惠券')
        return
      }

      //验证时间的可行性
      let canTime = that.checkTimeCanSave()
      if (!canTime) return
      // that.reduceFirstMonth()
      let initData = that.requstParams()
      // 新签会改，管理修改、驳回修改、待签字修改不要改这个，转换续相当于新合同；新签中，集寓也不能改，所以能改的只有新签且非集寓的
      // 几个能获取火房信息的地方，setPageData根据详情的空置天设置详情是否火房，新签走findRentByRoomId控制天数，其他修改会走selectRoomChange2的详情是否火房
      // 和后端返回情况不一样的只有新签的集寓，如果新签的集寓是火房，设置成不是火房
      // console.log(that.isWhetherKitchen)
      // console.log(this.contractInfo.houseType)
      // console.log(this.flagType)
      if(that.isWhetherKitchen==1&&this.contractInfo.houseType=='2' && this.flagType===0){
        initData.isWhetherKitchen =0
      }else{
        initData.isWhetherKitchen = this.isWhetherKitchen
      }

      // 新签会改  管理修改、驳回修改、待签字修改不要改这个
      // 合同终止日期==房源截止日期
      if(this.flagType!=1){
        if(new Date(that.contractInfo.endPeriod).getTime()==new Date(that.contractInfo.cutOffDate).getTime()){   //合同是业主到期
          initData.isOwnerExpiration=1
        }
      }else{
        initData.isOwnerExpiration=this.detailInfo.isOwnerExpiration?1:undefined
      }

      if (!that.saveFlag) return
      that.saveFlag = false

      // if(new Date(that.contractInfo.endPeriod).getTime()==new Date(that.contractInfo.cutOffDate).getTime()){   //合同是业主到期
      //   initData.isOwnerExpiration=1
      // }
      // if(that.isWhetherKitchen==1&&this.contractInfo.houseType!='2'){
      //   initData.isWhetherKitchen =1
      // }

      checkRenterContractStatus(initData).then(function(response) {
        if(response.data.code==0){
          saveOrUpdateRenterContract(initData).then(function(response) {
            if (response.data.code != 0) {
              that.saveFlag = true
            }
            responseUtil.dealResponse(that, response, () => {
              responseUtil.alertOK(that, '操作成功')
              // if(that.flagType==0 || that.flagType==1){
              //     that.$router.go(-1)
              // } else {
              //     that.$router.go(-2)
              // }
              //同一回列表页面
              localStorage.removeItem('currentLabel') //保存成功后会跳转到合同列表页面，所以要清除在潜客页面存储在
              localStorage.removeItem('type') //缓存中的currentLabel，否则到合同列表页面的话会优先取缓存中的数据
              that.$router.push({
                name: 'contractList',
                query: {
                  currentLabel: '1',
                  type: '1',
                },
              })
            })
          })

        }else{
          responseUtil.alertMsg(that, '合同数据变化，请返回合同列表刷新页面！')
          that.saveFlag = true
        }
      })
    },
  },
}
</script>

<style scoped>
.roomHouse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nav-bar {
  background-color: white;
  border: 0;
}

.headbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  width: 100%;
  font-size: 15px;
  font-weight: 900;
  padding-top: 10px;
}

.headbar-fixed {
  background-color: white;
  position: fixed;
  top: 50px;
  z-index: 99;
}

.headbar-redline {
  height: 4px;
  width: 20px;
  border-radius: 3px;
  background-color: #ff5d3b;
  margin: 5px 0 0 20px;
}

.headbar-graytick {
  display: inline-block;
  background: url('../../../assets/images/grayTick.png');
  width: 18px;
  height: 18px;
  background-size: 18px;
  margin-bottom: -3px;
}

.headbar-greentick {
  display: inline-block;
  background: url('../../../assets/images/GrassgreenTick.png');
  width: 18px;
  height: 18px;
  background-size: 18px;
  margin-bottom: -3px;
}

.contentpart {
  margin-top: 80px;
}

.part {
  margin: 15px;
}

.other-part {
  margin: 0 15px 50px 15px;
}

.other-part-add {
  top: 42px;
}

.part-subtitle {
  color: #ff5d3b;
  font-size: 12px;
  font-weight: 900;
  position: relative;
  top: 12px;
}
.part-subtitle-1 {
  font-size: 12px;
  font-weight: 900;
  position: relative;
  top: 12px;
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
}

.part-inputpart-houseAddress {
  margin: 0px 15px 8px;
}

.part-horizontalLine {
  width: 100%;
  height: 2px;
  background-color: #ededed;
  margin-top: 50px;
}

.part-button {
  height: 45px;
  border-radius: 10px;
  background-color: #ededed;
  color: #ffffff;
  text-align: center;
  line-height: 45px;
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 50px;
}

.part-button-enabled {
  background-image: linear-gradient(to right, #ffc274, #ff5d3b);
}

.part-hyperlinks {
  float: right;
  color: #999999;
  font-size: 12px;
  margin-top: 10px;
}

.part-hyperfirst {
  color: #999999;
  font-size: 12px;
  margin-top: 20px;
}

.part-inputpart div:last-child {
  border: none;
}

.part-inputpart-row {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  height: 45px;
  background-color: #ffffff;
  margin-top: 15px;
}

.part-inputpart-row-line {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  height: 65px;
  background-color: #ffffff;
  margin-top: 15px;
  flex-wrap: wrap;
}

.part-inputpart-row-short {
  width: 48%;
  float: left;
}

.part-inputpart-row-short-right {
  float: right;
}

.part-inputpart-row-short-graytext {
  color: #d8d8d8;
}

.part-inputpart-row-header {
  font-weight: 900;
}

.part-inputpart-row-sex {
  margin-top: 26px;
}

.part-inputpart-row-startPeriod {
  width: 60px;
  line-height: 21px;
  float: left;
}

.part-inputpart-row-endPeriod {
  width: 100px;
  height: 20px;
  line-height: 21px;
  margin-left: 8px;
  float: left;
}

.part-inputpart-man {
  margin-left: 54px;
  margin-right: 18px;
}

.part-inputpart-woman {
  margin-left: 18px;
}

.part-inputpart-yes {
  margin-left: 120px;
  margin-right: 18px;
}

.part-inputpart-isSupport {
  margin-left: 80px;
  margin-right: 18px;
}

.part-inputpart-cardpic {
  margin-top: 15px;
  border-radius: 8px;
  background-color: white;
}

.part-inputpart-cardpic-row {
  margin: 0;
}

.part-inputpart-cardpic-module {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.part-cardpic-module {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.part-inputpart-cardpic-pic {
  width: 85.6px;
  height: 54px;
  border-radius: 5px;
  margin: 0 15px;
  background-color: #e1eaec;
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

/*part-inputpart-cardpic-pic-img {*/
/*    width: auto;*/
/*    height: auto;*/
/*    max-width: 100%;*/
/*    max-height: 100%;*/
/*}*/

.nopadding {
  padding: 0;
  flex: auto;
}
.part-inputpart-cardpic-button {
  display: inline-block;
  background: url('../../../assets/images/grayAdd.png') center center no-repeat;
  width: 85.6px;
  height: 54px;
  border-radius: 5px;
  background-size: 30px;
  background-color: #fafafa;
  margin: 0 15px;
}

.part-inputpart-cardpic-text {
  font-size: 12px;
  color: #d8d8d8;
  margin: 7px 0 13px;
}

.part-inputpart-textarea {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  background-color: white;
  padding-top: 6px;
  border-radius: 8px;
}

.part-inputpart-textarea textarea {
  height: 100px;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  border: none;
  resize: none;
}

.part-inputpart-textarea textarea::-webkit-input-placeholder {
  color: #d8d8d8;
}

.part-inputpart-row input {
  border: 0;
  width: 5.6rem;
}

.part-inputpart-row input::-webkit-input-placeholder {
  color: #d8d8d8;
}

.otherExpenses-inputpart-row input::-webkit-input-placeholder {
  color: #d8d8d8;
}

.part-uploadpic-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.part-uploadpic-module {
  margin: 10px 25px 10px 10px;
}

.part-uploadpic-text {
  font-size: 15px;
  margin-left: 24px;
}

.part-uploadpic-module-pic {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

.part-uploadpic-van-uploader {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.part-inputpart-dropDownList {
  height: 23%;
  /*垂直方向滚动*/
  /*overflow-y: scroll;*/
  overflow: auto;
  width: 92.5%;
  position: absolute;
  z-index: 99;
}

.part-inputpart-dropDownList-option {
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: #999999;
}

.part-other-dropDownList-option {
  width: 92%;
}

.part-inputpart-dropDownList-option-selected {
  color: #ff5d3b;
}

.part-inputpart-row-enablenon {
  margin-left: 26px;
}

.part-inputpart-column-enablenon {
  margin-left: 10px;
}

.otherExpenses-row-enablenon {
  margin-left: 20px;
}

.part-inputpart-row-graytext {
  width: 100px;
  color: #d8d8d8;
  flex: auto;
}

.part-inputpart-row-normaltext {
  color: black;
  flex: auto;
}

.part-inputpart-row-redtext {
  flex: auto;
  color: #ff5d3b;
}

.part-inputpart-row-bluetext {
  color: #3590f6;
}

.part-inputpart-other-redtext {
  color: #ff5d3b;
}

.part-inputpart-row-housetype input {
  width: 18px;
  margin-left: 10px;
}

.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}

.part-inputpart-row-left {
  float: left;
  width: 20px;
}
.part-inputpart-row-header-other {
  float: left;
  margin-left: -15px;
}

.part-inputpart-row-right-rightArrow {
  width: 8px;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}
.part-inputpart-row-right-timepick {
  width: 20px;
}

.content-have {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #ff5d3b;
}

.content-none {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #c7c7c7;
}

.content-divide {
  font-size: 12px;
  color: #efefef;
  margin: 0 10px;
}

.content-divide-position {
  position: relative;
  bottom: 2px;
}

.content-bigdivide {
  width: 2px;
  height: 20px;
  background-color: #efefef;
  margin: 0px 40px 10px;
}

.content-margedivide {
  width: 3px;
  height: 30px;
  background-color: #efefef;
  margin: 0 10px;
}

.bluemiddleLabel {
  width: 46px;
  height: 23px;
  border-radius: 3px;
  color: #6d9ddd;
  background-color: #deebfd;
  text-align: center;
  line-height: 23px;
  margin: 8px;
}

.graymiddleLabel {
  width: 46px;
  height: 23px;
  border-radius: 3px;
  background-color: #f5f8fd;
  color: #d1e0f3;
  text-align: center;
  line-height: 23px;
  margin: 8px;
}

.redsmallLabel {
  width: 32px;
  height: 14px;
  border-radius: 3px;
  background-color: #fcded7;
  color: #ff5d3b;
  text-align: center;
  font-size: 11px;
  line-height: 14px;
  margin: 8px;
}

.selectTag {
  display: inline-block;
  background: url('../../../assets/images/selectedTag.png');
  width: 23px;
  height: 21px;
  background-size: 20px;
  margin: 10px;
}

.noSelectTag {
  display: inline-block;
  background: url('../../../assets/images/noSelectedTag.png');
  width: 25px;
  height: 21px;
  background-size: 20px;
  margin: 10px;
}

.deleteButton {
  float: left;
  display: inline-block;
  background: url('../../../assets/images/deleteButton.png');
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin: 15px 8px 0px 0;
}

.selectCircle {
  border: 1px #ff5d3b solid;
  border-radius: 50%;
  padding: 6px;
}

.selectCirclePosition {
  position: absolute;
  right: 19px;
}

.otherExpenses-inputpart {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  margin-top: 10px;
}

.otherExpenses-inputpart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  background-color: #ffffff;
  margin-top: 2px;
}

.otherExpenses-inputpart-column {
  display: flex;
  flex-direction: column;
  height: 45px;
  padding: 20px 10px;
  justify-content: space-between;
  background-color: #ffffff;
  margin-top: 2px;
}

.otherExpenses-inputpart-row input {
  border: 0;
  width: 100%;
}

.otherExpenses-price {
  font-weight: 900;
  font-size: 25px;
  margin-right: 20px;
}

.otherExpenses-hyperlinks {
  float: right;
  color: #ff5d3b;
  font-size: 14px;
  text-decoration: underline;
  margin-top: 20px;
  position: relative;
  bottom: 10px;
  margin-right: 7px;
  font-weight: 900;
}

.part-smalltext {
  font-size: 12px;
  width: 100%;
  font-weight: 900;
}

.text-heating {
  margin-left: 12px;
  font-weight: 600;
  font-size: 15px;
}

.divid-heating {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  /*margin-right: 5px;*/
  margin-left:12px;
}

.text-icon {
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border: 1px solid lightblue;
}

.text-iconActive {
  background-color: blue;
}
.text-contractActive {
  min-width: 1.5rem;
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.1rem;
  background-color: #ddebfb;
  color: #6aa7ee;
  font-size: 10px;
  text-align: center;
  font-family: PingFangSC-Regular;
  padding-left: 0.2rem;
  padding-right: 0.2rem;

}
#endPeriodPanel {
  width: 2.8rem;
}
.text-contract {
  min-width: 1.3rem;
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.1rem;
  background-color: #f5f9fe;
  color: #d8e8fa;
  font-size: 0.26667rem;
  text-align: center;
  font-family: PingFangSC-Regular;
  padding-left: 0.2rem;
  padding-right: 0.2rem;

}
.imageTo {
  width: 9px;
  height: 16px;
  margin-right: 0px;
}
.RecommendUser-clear {
  margin-top: 3px;
}

/*间房源特色*/
.infos-info-2 {
  float: left;
  width: auto;
  height: 26px;
  /* margin-top: 15px; */
  margin-right: 3px;
  color: rgba(86, 155, 236, 1);
  font-family: PingFangSC-Regular;
  -webkit-transform: scale(0.83);
  background-color: rgba(86, 155, 236, 0.2);
  border-radius: 0.1rem;
  padding: 0 5px;
}
.infosInfo3 {
  float: left;
  width: auto;
  height: 26px;
  /* margin-top: 15px; */
  margin-right: 3px;
  color: #ff5d3b;
  font-weight: 900;
  font-family: PingFangSC-Regular;
  -webkit-transform: scale(0.83);
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  line-height: 26px;
}
.smallText-10 {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 26px;
  font-size: 18px;
  -webkit-transform: scale(0.83);
}
.infos {
  margin-left: 15px;
  width: 100%;
}
.infos2 {
  float: left;
  width: auto;
  height: 56px;
  /* margin-top: 15px; */
  margin-right: 3px;
  color: rgba(86, 155, 236, 1);
  font-family: PingFangSC-Regular;
  -webkit-transform: scale(0.83);
  background-color: rgba(86, 155, 236, 0.2);
  border-radius: 5px;
}
.isWhetherKitchen{
  margin-left: 0.4rem;
  float: left;
  width: auto;
  height: 26px;
  /* margin-top: 15px; */
  margin-right: 3px;
  color: rgb(236, 86, 86);
  font-family: PingFangSC-Regular;
  -webkit-transform: scale(0.83);
  background-color: rgba(236, 86, 86, 0.2);
  border-radius: 0.1rem;
  padding: 0 5px;
}
</style>
